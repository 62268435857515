import { Component, OnInit } from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { PaginatorModule } from 'primeng/paginator';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownItemDto } from '../../../ model/dropdown-item.model';
import { ToolLocationService } from '../../../service/tool-location.service';
import { CantaaMessageService } from '../../../service/cantaa-message.service';
import { ToolDocumentService } from '../../../service/tool-document.service';
import { MatButton } from '@angular/material/button';
import { SystemSettingsService } from '../../../service/system-settings.service';
import { SystemSettings } from '../../../ model/system-settings.model';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';

@Component({
  selector: 'wim-system-settings',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    PaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButton
  ],
  templateUrl: './system-settings.component.html',
  styleUrl: './system-settings.component.scss'
})
export class SystemSettingsComponent implements OnInit {

  locationForm = this.fb.group({
    defaultLocation: this.fb.control<DropdownItemDto | null>(null)
  });

  locations: DropdownItemDto[] = [];
  systemSettings: SystemSettings | null = null;

  constructor(private fb: FormBuilder,
              private systemSettingsService: SystemSettingsService,
              private errorHandler: CantaaErrorHandlerService,
              private toolLocationService: ToolLocationService,
              private messageService: CantaaMessageService,
              protected toolDocumentService: ToolDocumentService) {
  }

  async ngOnInit() {
    this.systemSettings = await this.fetchSystemSettings();
    this.locations = await this.toolLocationService.getToolLocationsForDropdown();
    this.preselectDefaultLocation(this.systemSettings?.defaultLocationId);
  }

  private preselectDefaultLocation(defaultLocationId: number | null | undefined) {
    if (!defaultLocationId) {
      return;
    }
    let defaultLocation = this.locations.find(l => l.id === defaultLocationId);
    if (defaultLocation) {
      this.locationForm.controls.defaultLocation.setValue(defaultLocation);
    }
  }

  async fetchSystemSettings() {
    try {
      return await this.systemSettingsService.getSystemSettings();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async onSave() {
    const systemSettings = {
      defaultLocationId: this.locationForm.value.defaultLocation?.id
    } as SystemSettings;

    try {
      await this.systemSettingsService.saveSystemSettings(systemSettings);
      this.messageService.success('SAVE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, 'UPDATE.FAILED');
      throw e;
    }
  }

  onCancel() {
    this.preselectDefaultLocation(this.systemSettings?.defaultLocationId);
  }


}
