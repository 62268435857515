import {Component, inject} from '@angular/core';
import {BaseNodeGridComponent} from '../../base-components/base-node-grid.component';
import {TreeHelperService} from '../../../service/helper/tree-helper.service';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {NodeType} from '../../../constants/node-type';
import {TableLazyLoadEvent, TableModule} from 'primeng/table';
import {ToolLocationListItem} from '../../../ model/tool-location-list-item.model';
import {ToolLocationService} from '../../../service/tool-location.service';
import {SharedModule, TreeNode} from 'primeng/api';
import {WimIcons} from '../../../constants/wim-icons';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {RouteType} from '../../../constants/route-type';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {ToolLocationDocumentService} from "../../../service/tool-location-document.service";
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {UuidHelperService} from '../../../service/helper/uuid-helper.service';
import {TranslateModule} from '@ngx-translate/core';
import {GridRequest} from '../../../ model/grid-request.model';
import {SearchInputComponent} from '../../inputs/search-input/search-input.component';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-location-grid',
  standalone: true,
  imports: [
    SharedModule,
    TableModule,
    MatButton,
    MatIcon,
    HasPermissionDirective,
    TranslateModule,
    SearchInputComponent,
    ShowIfDebugMode
  ],
  templateUrl: './tool-location-grid.component.html',
  styleUrl: './tool-location-grid.component.scss'
})
export class ToolLocationGrid extends BaseNodeGridComponent<ToolLocationListItem> {
  protected readonly Permission = Permission;
  protected override nodeType = NodeType.TOOL_LOCATION_RELATION;

  public toolLocationDocumentService = inject(ToolLocationDocumentService);
  private messageService = inject(CantaaMessageService);
  private treeHelperService = inject(TreeHelperService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private toolLocationService = inject(ToolLocationService);


  override async fetchData(selectedNode: WimTreeNode, lazyLoadMetadata: TableLazyLoadEvent) {
    await super.fetchData(selectedNode, lazyLoadMetadata);

    this.gridItems.forEach(toolLocation => {
      this.toolLocationDocumentService.getDefaultPhotoScaled35x35AndRender(toolLocation.id);
    });
  }

  override async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode) {
    try {
      return await this.toolLocationService.getToolLocations(gridRequest, selectedNode);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  override mapTreeItems(selectedNode: WimTreeNode, items: ToolLocationListItem[]): WimTreeNode[] {
    return items
      .map<WimTreeNode>(listItem => {

        let treeNode = {
          id: listItem.id,
          key: UuidHelperService.generateUuid(),
          label: listItem.locationPretty,
          data: `${listItem.id};${listItem.locationTypeCode}`,
          icon: WimIcons.TOOL_LOCATION_ICON,
          nodeType: NodeType.TOOL_LOCATION,
          route: RouteType.LOCATION,
          children: [] as TreeNode[],
          leaf: false,
          parent: selectedNode
        };
        listItem.treeNode = treeNode;

        treeNode.children = [this.treeHelperService.createTransactionRelationsTreeItem(treeNode),
          this.treeHelperService.createToolRelationsTreeItem(treeNode)];

        return treeNode;
      });
  }

  protected readonly location = location;

  async delete() {
    try {
      await this.toolLocationService.deleteLocation(this.selectedItem!);
      this.messageService.success('DELETE.SUCCESS');
      await this.reFetchData();
    } catch (e) {
      throw this.errorHandler.handleError(e, 'DELETE.FAILED');
    }
  }

}
