import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BulkBookingHandoutComponent } from './bulk-booking-handout/bulk-booking-handout.component';
import { BulkBookingReturnComponent } from './bulk-booking-return/bulk-booking-return.component';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'wim-bulk-booking',
  standalone: true,
  templateUrl: './bulk-booking.component.html',
  styleUrl: './bulk-booking.component.scss',
  imports: [
    TranslateModule,
    MatTabGroup,
    MatTab,
    BulkBookingHandoutComponent,
    BulkBookingReturnComponent,
    MatTabContent

  ],
})
export class BulkBookingComponent {

}
