import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {AssignmentDto} from '../../../ model/qualification-assignment.model';
import {AppStateService} from '../../../service/app-state.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {NodeType} from '../../../constants/node-type';
import {AssignmentComponent} from '../../assignment/assignment.component';
import {MatCheckbox} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {InspectionAssignmentService} from '../../../service/inspection-assignment.service';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'wim-inspection-assignment-category',
  standalone: true,
  templateUrl: './inspection-assignment-category.component.html',
  styleUrl: './inspection-assignment-category.component.scss',
  imports: [
    MatCheckbox,
    FormsModule,
    TableModule,
    AssignmentComponent,
    TranslateModule
  ],
})
export class InspectionAssignmentCategoryComponent implements OnInit {
  protected readonly destroyRef = inject(DestroyRef);
  qualificationAssignments: AssignmentDto[] = [];

  constructor(private appStateService: AppStateService,
              private errorHandler: CantaaErrorHandlerService,
              private inspectionAssignmentService: InspectionAssignmentService,
              private messageService: CantaaMessageService) {
  }

  async ngOnInit() {
    const onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL_CATEGORY) {
          return;
        }
        await this.fetchAssignments(selectedNode.id);
      });

    this.destroyRef.onDestroy(() => {
      onSelectNodeSubscription.unsubscribe();
    });
  }

  private async fetchAssignments(id: number) {
    try {
      this.qualificationAssignments = await this.inspectionAssignmentService.getInspectionAssignmentsByCategory(id);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async updateAssignment(assignment: AssignmentDto) {
    const id = this.appStateService.selectedNodeValue.id!;

    try {
      await this.inspectionAssignmentService.updateInspectionToCategoryAssignment(id, assignment.assignmentId, assignment.assigned);
      this.messageService.success('UPDATE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, 'UPDATE.FAILED');
    }
    await this.fetchAssignments(id);
  }

}
