<div class="container " style="min-height: 80vh">

  <h2>An error occurred</h2>

  @if (errorMessage()) {
    <p>{{ errorMessage() }}</p>
  }

  <button mat-button routerLink="/logout" matTooltip="Log out">
    <mat-icon>logout</mat-icon>
    Log out
  </button>

</div>

