import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CantaaLocalStorageService} from './cantaa-local-storage.service';
import {MAT_DATE_FORMATS} from '@angular/material/core';


@Injectable({
  providedIn: 'root'
})
export class CantaaLocalizationService {

  private readonly _defaultLanguage = 'en';
  private readonly _defaultMarket = 'DE';

  private readonly _languages = ['de', 'en'];
  private readonly _markets = ['DE', 'US'];
  private _locales: string[] = [];

  private _currentLanguage = '';
  private _currentMarket = '';

  constructor(private translateService: TranslateService,
              private cantaaLocalStorageService: CantaaLocalStorageService,
              @Inject(MAT_DATE_FORMATS) private matDateFormats: any) {
    this._languages.map(l => {
      this._markets.map(m => {
        this._locales.push(l + '-' + m);
      })
    });

    let languageFromStorage = this.cantaaLocalStorageService.getLanguage();
    this._currentLanguage = languageFromStorage ?? this._defaultLanguage;

    let marketFromStorage = this.cantaaLocalStorageService.getMarket();
    this._currentMarket = marketFromStorage ?? this._defaultMarket;

    this.initLocalizations();
  }

  private initLocalizations() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(this._defaultLanguage);
    this.translateService.addLangs(this._locales);

    this.useLocales(this.currentLocale);
  }

  private useLocales(locales: string) {
    this.translateService.use(locales).subscribe(r => {
      this.initFormats();
    })
  }

  // https://stackoverflow.com/questions/61364618/angular-date-picker-update-mat-date-formats-dynamically
  // export const MY_DATE_FORMATS = {
  //   parse: {
  //     dateInput: 'DD.MM.YYYY',
  //   },
  //   display: {
  //     dateInput: 'DD.MM.YYYY',
  //     monthYearLabel: 'MMMM YYYY',
  //     dateA11yLabel: 'LL',
  //     monthYearA11yLabel: 'MMMM YYYY'
  //   },
  // };

  private initFormats() {
    this.translateService.get('DATE.FORMAT').subscribe((res: string) => {
      const dateFormat = res.toUpperCase();
      this.matDateFormats.parse.dateInput = dateFormat;
      this.matDateFormats.display.dateInput = dateFormat;
    });
  }

  get languages(): string[] {
    return this._languages;
  }

  get markets(): string[] {
    return this._markets;
  }

  get currentLocale(): string {
    return this._currentLanguage + '-' + this._currentMarket;
  }

  get currentLanguage(): string {
    return this._currentLanguage;
  }

  set currentLanguage(language: string) {
    this.cantaaLocalStorageService.setLanguage(language);
    this._currentLanguage = language;
    this.useLocales(this.currentLocale);
  }

  get currentMarket(): string {
    return this._currentMarket;
  }

  set currentMarket(market: string) {
    this.cantaaLocalStorageService.setMarket(market);
    this._currentMarket = market;
    this.useLocales(this.currentLocale);
  }

  public instant(key: string) {
    return this.translateService.instant(key);
  }

}


