import { Component, OnInit, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SuggestionInputComponent } from '../../inputs/suggestion-input/suggestion-input.component';
import { ToolService } from '../../../service/tool.service';
import { ToolListItem } from '../../../ model/tool-list-item.model';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayHelper } from '../../../service/helper/DisplayHelper';

@Component({
  selector: 'wim-test-search',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    SuggestionInputComponent,
    MatButton,
    TranslateModule,
  ],
  templateUrl: './test-search.component.html',
  styleUrl: './test-search.component.scss'
})
export class TestSearchComponent implements OnInit {
  protected readonly DisplayHelper = DisplayHelper;
  toolSuggestionInput = viewChild.required<SuggestionInputComponent<ToolListItem>>('toolSuggestionInput');

  constructor(private toolService: ToolService) {
  }

  ngOnInit() {
  }

  fetchSuggestions = async (query: string): Promise<ToolListItem[]> => {
    return await this.toolService.searchTools(query);
  };

  displayFn = (option: ToolListItem): string => {
    return option ? `${option.id} ${option.category} ${option.rfid}` : '';
  };

  optionsSelected(toolListItem: ToolListItem) {
    console.log('optionsSelected', toolListItem);
  }

  onDisable() {
    this.toolSuggestionInput().disable();
  }

  clearChildAutocomplete(): void {
    this.toolSuggestionInput().clear();
  }

}
