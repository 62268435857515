import { FormControl } from '@angular/forms';

export class FormErrorHelper {

  public static getErrorMessage(control: FormControl): string {
    if (control) {
      // if (control.hasError('required')) return 'This field is required and cannot be empty';
      // if (control.hasError('other')) return 'Some other error';
      if (control.hasError('serverError')) return control.errors?.['serverError'];
    }
    return '';
  }

}
