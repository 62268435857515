<div class="container highlight-cursor">
  <h2>{{ 'RESERVATION.RESERVATION' | translate }}</h2>

  <form [formGroup]="reservationForm" autocomplete="off" (ngSubmit)="onReservationFormSubmit()" class="">

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION.PICK_UP' | translate }}</mat-label>
          <mat-select class="w-100"
                      formControlName="pickUpLocation"
                      (selectionChange)="onPickUpLocationChange()">
            @for (toolLocation of toolLocations; track toolLocation.id) {
              <mat-option [value]="toolLocation">{{ toolLocation.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-1">
        <img class="img-defaultphoto-icon iconw70xh70" id="reservation_pickuplocation_icon" src="">
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'EMPLOYEE.EMPLOYEE' | translate }}</mat-label>
          <mat-select class="w-100"
                      formControlName="employee"
                      (selectionChange)="onEmployeeChange()">
            <mat-option></mat-option>
            @for (employee of employees; track employee.id) {
              <mat-option [value]="employee">{{ employee.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-1">
        <img class="img-defaultphoto-icon iconw70xh70" id="reservation_employee_icon" src="">
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'DATE.FROM' | translate }}</mat-label>
          <input matInput [matDatepicker]="fromDatePicker" formControlName="fromDate"
                 (dateChange)="onFromDateChanged()"
                 (keydown.enter)="onFromDateChanged()">
          <mat-hint>{{ 'DATE.FORMAT' | translate }}</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'DATE.TO' | translate }}</mat-label>
          <input matInput [matDatepicker]="toDatePicker" formControlName="toDate"
                 (dateChange)="onToDateChanged()"
                 (keydown.enter)="onToDateChanged()">
          <mat-hint>{{ 'DATE.FORMAT' | translate }}</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'CATEGORY.CATEGORY' | translate }}</mat-label>
          <mat-select class="w-100"
                      formControlName="category"
                      (selectionChange)="onCategoryChange()">
            @for (category of categories; track category.id) {
              <mat-option [value]="category">{{ category.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'AMOUNT.AMOUNT' | translate }}</mat-label>
          <input id="amount" type="number" matInput formControlName="amount" (keydown.enter)="onAmountEnter($event)">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <p-table
        #dt
        [value]="gridItems"
        [scrollable]="true" scrollHeight="40vh"
        [resizableColumns]="true" columnResizeMode="expand"
        dataKey="id"
        styleClass="p-datatable-sm strikethrough-able-grid grid-shadow p-datatable-gridlines">

        <ng-template id="simple-header" pTemplate="header">
          <tr>
            <!--            <th id="defaultpicture" style="width:1px" ></th>-->
            <th id="header_id">{{ 'CATEGORY.CATEGORY' | translate }}</th>
            <th id="header_name">{{ 'AMOUNT.AMOUNT' | translate }}</th>
            <th id="header_message">{{ 'MESSAGE' | translate }}</th>
            <th id="header_action"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <!--            <td style="display: flex; justify-content: center;"><img id="{{ toolDocumentService.generateImgElementId4Tool(item?.tool.id) }}" class="img-defaultphoto-icon iconw70xh70" src=""></td>-->
            <td [class]="getTdClass(item)">{{ item.category.name }}</td>
            <td [class]="getTdClass(item)">{{ item.amount }}</td>
            <td [class]="getTdClass(item)">{{ item.message }}</td>
            <td>

              <button type="button" mat-icon-button (click)="onUpdateActiveState(item)">
                @if (item.active) {
                  <mat-icon>delete</mat-icon>
                } @else {
                  <mat-icon fontIcon="restart_alt"></mat-icon>
                }
              </button>

            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <!--                [disabled]="reservationForm.pristine || !reservationForm.valid" -->
    <div class="row">
      <div class="col-4" *hasPermission="Permission.BOOKING_MANAGER">
        <button mat-raised-button color="primary"
                [disabled]="getActiveItems().length == 0"
                class="m-1">
          {{ 'SAVE.SAVE' | translate }}
        </button>
        <button type="button" mat-raised-button (click)="onCancel()">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>

  </form>

</div>

