<div class="container highlight-cursor">

  <div class="row">
    <div class="col-6">

      <wim-suggestion-input #employeeSuggestionInput
                            [label]="'EMPLOYEE.EMPLOYEE' | translate"
                            [fetchSuggestions]="employeeFetchSuggestions"
                            [displayFn]="DisplayHelper.locationDisplayFn"
                            (onOptionSelected)="onEmployeeSelected($event)"/>
    </div>

    <div class="col-1">
      <img class="img-defaultphoto-icon iconw70xh70" id="mobilelocationbooking_employee_icon" src="">
    </div>
  </div>

  <div class="row">
    <div class="col-6">

      <wim-suggestion-input #mobileLocationSuggestionInput
                            [label]="'MOBILE_LOCATION.MOBILE_LOCATION' | translate"
                            [fetchSuggestions]="mobileLocationFetchSuggestions"
                            [displayFn]="DisplayHelper.locationDisplayFn"
                            (onOptionSelected)="onMobileLocationSelected($event)"/>

    </div>
    <div class="col-1">
      <img class="img-defaultphoto-icon iconw70xh70" id="mobilelocationbooking_mobilelocation_icon" src="">
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <mat-form-field id="statusBox" class="w-100">
        <textarea matInput [(ngModel)]="statusText" disabled rows="7"></textarea>
      </mat-form-field>
    </div>
  </div>

</div>
