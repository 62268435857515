import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from "./console-logger.service";
import { SystemJob } from '../ model/system-job.model';
import { CantaaErrorHandlerService } from './cantaa-error-handler.service';
import { SystemJobUpdate } from '../ model/system-job-update.model';
import { CantaaLocalizationService } from './cantaa-localization.service';

@Injectable({
  providedIn: 'root'
})
export class SystemJobService {

  constructor(private http: HttpClient, private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService,
              private localization: CantaaLocalizationService) {
  }

  async getSystemJobs() {
    const url = environment.apiUrl + `/system-jobs`;
    let params = this.httpClientHelper.createHttpParams();

    const observable = this.http.get<CollectionDto<SystemJob>>(url, { params: params })
      .pipe(tap(r => this.log.debug('System jobs: ', r)))
      .pipe(map(c => c.items));

    try {
      return await firstValueFrom(observable);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async getSystemJob(jobId: string) {
    const url = environment.apiUrl + `/system-jobs/${jobId}`;
    let params = this.httpClientHelper.createHttpParams();

    const observable = this.http.get<SystemJob>(url, { params: params })
      .pipe(tap(r => this.log.debug('System job: ', r)))

    try {
      return await firstValueFrom(observable);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async updateSystemJob(systemJobUpdate: SystemJobUpdate) {
    const url = environment.apiUrl + `/system-jobs`;
    let params = this.httpClientHelper.createHttpParams();

    const observable = this.http.put<void>(url, systemJobUpdate, { params: params });

    try {
      return await firstValueFrom(observable);
    } catch (e) {
      this.errorHandler.handleError(e, 'UPDATE.FAILED');
      throw e;
    }
  }

  async runSystemJob(jobId: string) {
    const url = environment.apiUrl + `/system-jobs/${jobId}/forceRun`;
    let params = this.httpClientHelper.createHttpParams();

    const observable = this.http.get<void>(url, { params: params })

    try {
      return await firstValueFrom(observable);
    } catch (e) {

      this.errorHandler.handleError(e, this.localization.instant('SYSTEM.JOB.FAILED_TO_START') + ' ' + jobId);
      throw e;
    }
  }

}
