import { ActivatedRoute, Router } from '@angular/router';
import { WimTreeNode } from '../../ model/wim-tree.node';


export class RouteHelper {

  public static isInNewState(route: ActivatedRoute): boolean {
    return route.snapshot.url.toString().endsWith('new');
  }

  public static async navigateToGrid(route: ActivatedRoute, router: Router) {
    await router.navigate(['../'], { relativeTo: route });
  }

  public static async navigateViaTreeNode(router: Router, activatedRoute: ActivatedRoute, treeNode: WimTreeNode) {
    await router.navigate(['../', treeNode.route, treeNode.id], { relativeTo: activatedRoute });
  }

  public static async navigateToGridDocument(route: ActivatedRoute, router: Router) {
    await router.navigate(['../../../document'], { relativeTo: route }) .then(() => {
      window.location.reload();
    });
  }

}
