{
  "name": "wim-angular",
  "version": "0.0.17-SNAPSHOT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration local",
    "build": "ng build",
    "develop": "ng build --configuration development",
    "main": "ng build --configuration main",
    "watch": "ng build --watch --configuration local",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.12",
    "@angular/cdk": "^18.2.13",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/material": "^18.2.13",
    "@angular/material-moment-adapter": "^18.2.13",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "angular-oauth2-oidc": "^17.0.2",
    "bootstrap": "^5.3.3",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.12",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@types/jasmine": "~5.1.4",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "^5.5.4"
  }
}
