<form class="example-form">
  <mat-form-field class="w-100">
    <mat-label>{{ label() }}</mat-label>
    <input
      #suggestionInput
      type="text"
      class="suggestionInput"
      placeholder=""
      matInput
      onclick="this.select()"
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      (keyup.enter)="onEnter()"
    >

    <mat-autocomplete #auto="matAutocomplete"
                      autoActiveFirstOption
                      [displayWith]="displayFn"
                      (optionSelected)="onOptionSelectedInternal($event)">
      @for (option of filteredOptions | async; track option.id) {
        <mat-option [value]="option">{{ displayFn(option) }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

</form>

<!--<div>-->
<!--  <p>-->
<!--    {{ searchControl.value | json }}-->
<!--  </p>-->

<!--</div>-->
