import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GridRequest} from '../ model/grid-request.model';
import {CollectionDto} from '../ model/collection.model';
import {environment} from '../../environments/environment';
import {WimTreeNode} from '../ model/wim-tree.node';
import {NodeType} from '../constants/node-type';
import {HttpClientHelper} from './http-client-helper.service';
import {firstValueFrom, tap} from 'rxjs';
import {ConsoleLoggerService} from './console-logger.service';
import {InspectableToolListItem} from '../ model/inspectable-tool-list-item.model';


@Injectable({
  providedIn: 'root'
})
export class InspectableToolService {

  http = inject(HttpClient);
  httpClientHelper = inject(HttpClientHelper);
  log = inject(ConsoleLoggerService);

  getInspectableTools(request: GridRequest, selectedNode: WimTreeNode) {
    const params = this.httpClientHelper.createHttpParams();
    let url = `${environment.apiUrl}${this.getUrl(selectedNode)}`;
    const observable = this.http.post<CollectionDto<InspectableToolListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('Inspectable tools: ', r)))
    return firstValueFrom(observable);
  }

  private getUrl(selectedNode: WimTreeNode): string {
    const parent = (selectedNode.parent as WimTreeNode);
    const parentNodeType = parent?.nodeType;

    switch (parentNodeType) {
      case undefined:
        return `/inspectable-tools`;
      case NodeType.TRANSACTION:
        return `/inspectable-tools/transaction/${parent.id}`;
      case NodeType.TOOL_LOCATION:
        return `/inspectable-tools/location/${parent.id}`;
      case NodeType.TOOL_CATEGORY:
        return `/inspectable-tools/category/${parent.id}`;
      case NodeType.DOCUMENT:
        return `/inspectable-tools/document/${parent.id}`;
      default:
        throw new Error("not Implemented");
    }

  }

}
