import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { GridRequest } from '../ model/grid-request.model';
import { DocumentTypeListItem } from '../ model/document-type-list-item.model';


@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getDocumentTypes() {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/documentTypes`;
    const observable = this.http.get<CollectionDto<DocumentTypeListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('documentTypes: ', r)))
      .pipe(map(c => c.items))

    return firstValueFrom(observable)
  }

  getPagedDocumentTypes(request: GridRequest) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/documentTypes/paged`;
    const observable = this.http.post<CollectionDto<DocumentTypeListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('documentTypes: ', r)));
    return firstValueFrom(observable);
  }

  saveDocumentType(documentType: DocumentTypeListItem) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/documentTypes`;
    const observable = this.http.post<DocumentTypeListItem>(url, documentType, { params: params })
      .pipe(tap(r => this.log.debug('documentType save response: ', r)));

    return firstValueFrom(observable);
  }

  updateDocumentTypeStatus(id: number, active: boolean) {
    let params = this.httpClientHelper.createHttpParams();
    console.log('params1', params);
    params = params.append('active', active);
    console.log('params', params);

    let url = environment.apiUrl + `/documentTypes/${id}/status`;
    const observable = this.http.put<boolean>(url, null, { params: params })
      .pipe(tap(r => this.log.debug('Document type updated: ', r)))

    return firstValueFrom(observable)
  }

}
