import { Component } from '@angular/core';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { ToolDetail } from '../../tool/tool-detail/tool-detail.component';
import { ToolDetailDocumentsComponent } from '../../tool/tool-detail-documents/tool-detail-documents.component';
import { ToolDetailInspectionsComponent } from '../../tool/tool-detail-inspections/tool-detail-inspections.component';
import { ToolDetailInventoryComponent } from '../../tool/tool-detail-inventory/tool-detail-inventory.component';
import { TransactionDetail } from '../transaction-detail/transaction-detail.component';
import { TransactionDetailToolsComponent } from '../transaction-detail-tools/transaction-detail-tools.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'wim-transaction-tab-detail',
  standalone: true,
  templateUrl: './transaction-tab-detail.component.html',
  styleUrl: './transaction-tab-detail.component.scss',
  imports: [
    MatTab,
    MatTabContent,
    MatTabGroup,
    ToolDetail,
    ToolDetailDocumentsComponent,
    ToolDetailInspectionsComponent,
    ToolDetailInventoryComponent,
    TransactionDetail,
    TransactionDetailToolsComponent,
    TranslateModule
  ],
})
export class TransactionTabDetailComponent {

}
