import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {WimTreeNode} from '../ model/wim-tree.node';
import {CantaaGridMeta} from "../ model/GridMeta";

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private selectedTabIndexMap = new Map<string, number>();

  private rootNode = new BehaviorSubject<WimTreeNode>({});
  private _selectedNode = new BehaviorSubject<WimTreeNode>({});
  private _currentTreeItems = new BehaviorSubject<WimTreeNode[]>([]);

  private gridsMeta = new Map<string, CantaaGridMeta>();

  getRootNode$(): Observable<WimTreeNode> {
    return this.rootNode.asObservable();
  }

  setRootNode(value: WimTreeNode) {
    this.rootNode.next(value);
  }

  get selectedNodeValue(): WimTreeNode {
    return this._selectedNode.value;
  }

  getSelectedNode$(): Observable<WimTreeNode> {
    return this._selectedNode.asObservable();
  }

  setSelectedNode(value: WimTreeNode) {
    this._selectedNode.next(value);
  }

  resendSelectedNode() {
    this._selectedNode.next(this._selectedNode.value);
  }

  getCurrentTreeItems$(): Observable<WimTreeNode[]> {
    // TODO: better way
    return this._currentTreeItems.asObservable();
  }

  setCurrentTreeItems(treeItems: WimTreeNode[]) {
    this._currentTreeItems.next(treeItems);
  }

  // sendExplorerEvent(eventType: string) {
  //   this.explorerEvent.next({ eventType: eventType } as ExplorerEvent);
  // }

  getGridMeta(gridName: string) {
    const meta = this.gridsMeta.get(gridName);
    if (!meta) {
      return {
        searchQuery: undefined,
        lazyLoadMeta: undefined
      }
    }
    return meta;
  }

  setGridMeta(gridName: string, gridMeta: CantaaGridMeta) {
    this.gridsMeta.set(gridName, gridMeta);
  }

  getSelectedTabIndex(componentName: string) {
    return this.selectedTabIndexMap.get(componentName) ?? 0;
  }

  setSelectedTabIndex(componentName: string, index: number) {
    this.selectedTabIndexMap.set(componentName, index);
  }

}
