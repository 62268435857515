<mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="selectedIndexChange($event)">
  <mat-tab label="{{ 'TOOLS' | translate }}">
    <ng-template matTabContent>
      <wim-tool-grid></wim-tool-grid>
    </ng-template>
  </mat-tab>

    <mat-tab label="{{ 'INSPECTIONS.INSPECTIONS' | translate }}">
    <ng-template matTabContent>
      <wim-inspectable-tool-grid></wim-inspectable-tool-grid>
    </ng-template>
  </mat-tab>

</mat-tab-group>
