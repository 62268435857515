import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { GridRequest } from '../ model/grid-request.model';
import { QualificationListItem } from '../ model/qualification-list-item.model';


@Injectable({
  providedIn: 'root'
})
export class QualificationService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getQualifications() {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/qualifications`;
    const observable = this.http.get<CollectionDto<QualificationListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('qualifications: ', r)))
      .pipe(map(c => c.items))

    return firstValueFrom(observable)
  }

  getPagedQualifications(request: GridRequest) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/qualifications/paged`;
    const observable = this.http.post<CollectionDto<QualificationListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('qualifications: ', r)));
    return firstValueFrom(observable);
  }

  saveQualification(documentType: QualificationListItem) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/qualifications`;
    const observable = this.http.post<QualificationListItem>(url, documentType, { params: params })
      .pipe(tap(r => this.log.debug('qualification save response: ', r)));

    return firstValueFrom(observable);
  }

  updateQualificationStatus(id: number, active: boolean) {
    let params = this.httpClientHelper.createHttpParams();
    params = params.append('active', active);

    let url = environment.apiUrl + `/qualifications/${id}/status`;
    const observable = this.http.put<boolean>(url, null, { params: params })
      .pipe(tap(r => this.log.debug('Document type updated: ', r)))

    return firstValueFrom(observable)
  }

}
