import { ToolListItem } from '../../ model/tool-list-item.model';
import { ToolLocationListItem } from '../../ model/tool-location-list-item.model';


export class DisplayHelper {

  public static readonly toolDisplayFn = (tool: ToolListItem): string => {
    if (!tool) {
      return '';
    }
    return `${DisplayHelper.getValueOrEmpty(tool.category)} ${DisplayHelper.getValueOrEmpty(tool.rfid)}`;
  };

  public static readonly locationDisplayFn = (location: ToolLocationListItem): string => {
    if (!location) {
      return '';
    }
    return `${DisplayHelper.getValueOrEmpty(location.locationPretty)} ${DisplayHelper.getValueOrEmpty(location.rfid)}`;
  };


  public static readonly getValueOrEmpty = (option: any): string => {
    if (!option) {
      return '';
    }
    return option;
  };


}
