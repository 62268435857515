import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import {firstValueFrom, map, tap} from 'rxjs';
import {ToolDocument} from "../ model/tool-document.models";
import {ConsoleLoggerService} from "./console-logger.service";
import {ToolLocationDetail} from "../ model/tool-location-detail.model";
import {ToolDocumentDetail} from "../ model/tool-document-detail.models";
import {ToolCategoryDetailItem} from "../ model/tool-category-detail.model";
import {ToolDetailItem} from "../ model/tool-detail.model";
import {ToolLocationDocumentDetail} from "../ model/tool-location-document-detail.models";
import {DocumentService} from "./document.service";
import {ToolLocationDocument} from "../ model/tool-location-document.models";


@Injectable({
  providedIn: 'root'
})
export class ToolLocationDocumentService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private documentService: DocumentService,
              private log: ConsoleLoggerService) {
  }

  getImgElementId(toolLocationId: number){
    return 'default_img_toollocationid_' + toolLocationId;
  }

  async getDefaultPhotoAndRender(toolLocationId: number){
    this.getDefaultPhotoScaledAndRender(toolLocationId,-1,-1);
  }

  async getDefaultPhotoScaled35x35AndRender(toolLocationId: number){
    this.getDefaultPhotoScaledAndRender(toolLocationId,35,35);
  }

  async getDefaultPhotoScaledAndRender(toolLocationId: number, width: number, heigth: number){
    const imgEleId = this.getImgElementId(toolLocationId);

    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/toollocationdocument/defaultphoto/${toolLocationId}`;
    let toolDocument = await firstValueFrom(this.http.get<ToolDocument>(url, { params: params }));

    if (toolDocument.documentId == -1) { // NO_DEFAULT_PHOTO_FOUND
      this.documentService.disableImgElement(imgEleId);
    } else {
      let imgBlob;
      if (width == -1 && heigth == -1) {
        imgBlob = await this.documentService.getBlob4Id(toolDocument.documentId);
      } else {
        imgBlob = await this.documentService.getBlob4IdScaled(toolDocument.documentId, width, heigth);
      }
      this.documentService.processImageAndRender(imgBlob, imgEleId);
      this.documentService.enableImgElement(imgEleId);
    }
  }

  async getDefaultPhoto2ImgEleId(toolLocationId: number | undefined, imgEleId: string){
    this.getDefaultPhotoScaled2ImgEleId(toolLocationId, imgEleId, -1,-1);
  }

  async getDefaultPhotoScaled35x35_2ImgEleId(toolLocationId: number | undefined, imgEleId: string){
    this.getDefaultPhotoScaled2ImgEleId(toolLocationId, imgEleId, 35,35);
  }

  async getDefaultPhotoScaled70x70_2ImgEleId(toolLocationId: number | undefined | null, imgEleId: string){
    this.getDefaultPhotoScaled2ImgEleId(toolLocationId, imgEleId, 70,70);
  }

  async getDefaultPhotoScaled2ImgEleId(toolLocationId: number | undefined |null, imgEleId: string, width: number, heigth: number){
    if (toolLocationId == undefined) {
      this.documentService.disableImgElement(imgEleId);
      return;
    }
    if (toolLocationId == null) {
      this.documentService.disableImgElement(imgEleId);
      return;
    }

    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/toollocationdocument/defaultphoto/${toolLocationId}`;
    let toolDocument = await firstValueFrom(this.http.get<ToolDocument>(url, { params: params }));

    if (toolDocument.documentId == -1 || toolDocument.documentId == null) { // NO_DEFAULT_PHOTO_FOUND
      this.documentService.disableImgElement(imgEleId);
    } else {
      let imgBlob;
      if (width == -1 && heigth == -1) {
        imgBlob = await this.documentService.getBlob4Id(toolDocument.documentId);
      } else {
        imgBlob = await this.documentService.getBlob4IdScaled(toolDocument.documentId, width, heigth);
      }
      this.documentService.processImageAndRender(imgBlob, imgEleId);
      this.documentService.enableImgElement(imgEleId);
    }
  }

  // --- get Default Photo 4 Email ---
  async getDefaultPhoto4Email2ImgEleId(email: string | undefined, imgEleId: string){
    this.getDefaultPhotoScaled4Email2ImgEleId(email, imgEleId, -1, -1);
  }

  async getDefaultPhotoScaled35x35_4Email2ImgEleId(email: string | undefined, imgEleId: string){
    this.getDefaultPhotoScaled4Email2ImgEleId(email, imgEleId, 35, 35);
  }

  async getDefaultPhotoScaled70x70_4Email2ImgEleId(email: string | undefined, imgEleId: string){
    this.getDefaultPhotoScaled4Email2ImgEleId(email, imgEleId, 70, 70);
  }

  async getDefaultPhotoScaled4Email2ImgEleId(email: string | undefined, imgEleId: string, width: number, heigth: number){
    if (email == undefined) {
      this.documentService.disableImgElement(imgEleId);
      return;
    }

    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/toollocationdocument/defaultphoto/email/${email}`;
    let toolDocument = await firstValueFrom(this.http.get<ToolDocument>(url, { params: params }));

    if (toolDocument.documentId == -1 || toolDocument.documentId == null) { // NO_DEFAULT_PHOTO_FOUND
      this.documentService.disableImgElement(imgEleId);
    } else {
      let imgBlob;
      if (width == -1 && heigth == -1) {
        imgBlob = await this.documentService.getBlob4Id(toolDocument.documentId);
      } else {
        imgBlob = await this.documentService.getBlob4IdScaled(toolDocument.documentId, width, heigth);
      }
      this.documentService.processImageAndRender(imgBlob, imgEleId);
      this.documentService.enableImgElement(imgEleId);
    }
  }


  getToolLocationDocumentDetail(toolLocationDocumentId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/toollocationdocument/${toolLocationDocumentId}`;
    return firstValueFrom(this.http.get<ToolDocument>(url, { params: params }));
  }

  // --- get All Docs 4 LocationId ---
  getAllToolLocationDocuments4LocationId(locationId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/toollocationdocument/location/${locationId}`;
    return firstValueFrom(this.http.get<CollectionDto<ToolDocument>>(url, { params: params })
      .pipe(tap(r => this.log.debug('tool document: ', r))));
  }

  // --- DELETE ---
  deleteToolLocationDocument(toolLocationDocumentId: number){
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/toollocationdocument/${toolLocationDocumentId}`;
    const observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('toolDocument deleted: ', r)))
    return firstValueFrom(observable)
  }

  // --- NEW ---
  newToolLocationDocument(detail: ToolLocationDocumentDetail) {
    const params = this.httpClientHelper.createHttpParams();
    params.append('Content-Type', 'multipart/form-data');

    let url = environment.apiUrl + `/toollocationdocument/newtoollocationdocument`;
    let observable = this.http.post<number>(url, detail, { params: params })
      .pipe(tap(r => this.log.debug('saved: ', r)))

    return firstValueFrom(observable)
  }

  // --- UPLOAD ---
  uploadDocumentForLocation(locationId: number, file: File){
    const formData = new FormData();
    formData.append("file", file);

    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/toollocationdocument/uploadtoollocationdocument/location/${locationId}`;

    let observable = this.http.post(url, formData, { params: params })
      .pipe(tap(r => this.log.debug('saved: ', r)))

    return firstValueFrom(observable)
  }

  // --- SAVE ---
  saveToolLocationDocument(detail: ToolLocationDocument) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/toollocationdocument/savetoollocationdocument`;
    let observable = this.http.post<ToolDetailItem>(url, detail, { params: params })
      .pipe(tap(r => this.log.debug('saved tool: ', r)))

    return firstValueFrom(observable)
  }

}
