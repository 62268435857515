import {Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {
  MatTab,
  MatTabChangeEvent,
  MatTabContent,
  MatTabGroup,
  MatTabLink,
  MatTabNav,
  MatTabNavPanel
} from '@angular/material/tabs';
import {AppStateService} from '../../../service/app-state.service';
import {ToolGrid} from "../tool-grid/tool-grid.component";
import {InspectableToolGridComponent} from "../inspectable-tool-grid/inspectable-tool-grid.component";

@Component({
  selector: 'wim-tool-tab-list',
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    MatTabNav,
    MatTabNavPanel,
    MatTabLink,
    MatTab,
    MatTabGroup,
    MatTabContent,
    ToolGrid,
    InspectableToolGridComponent
  ],
  templateUrl: './tool-tab-list.component.html',
  styleUrl: './tool-tab-list.component.scss'
})
export class ToolTabList {
  selectedTabIndex = 0;


  constructor(private appStateService: AppStateService) {
    this.selectedTabIndex = appStateService.getSelectedTabIndex(ToolTabList.name);
  }

  selectedIndexChange(e: MatTabChangeEvent) {
    console.log('selectedIndexChange', e);
    this.appStateService.setSelectedTabIndex(ToolTabList.name, e.index);
  }
}
