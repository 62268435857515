import {Component, inject} from '@angular/core';
import {TableModule} from 'primeng/table';
import {SharedModule, TreeNode} from 'primeng/api';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {BaseNodeGridComponent} from '../../base-components/base-node-grid.component';
import {TreeHelperService} from '../../../service/helper/tree-helper.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {NodeType} from '../../../constants/node-type';
import {WimIcons} from '../../../constants/wim-icons';
import {InspectionTypeListItem} from '../../../ model/inspection-type-list.item';
import {InspectionTypesService} from '../../../service/inspection-types.service';
import {RouteType} from '../../../constants/route-type';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {UuidHelperService} from '../../../service/helper/uuid-helper.service';
import {TranslateModule} from '@ngx-translate/core';
import {GridRequest} from '../../../ model/grid-request.model';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-inspection-type-grid',
  templateUrl: './inspection-type-grid.component.html',
  styleUrl: './inspection-type-grid.component.scss',
  standalone: true,
  imports: [
    SharedModule,
    TableModule,
    MatButton,
    MatIcon,
    HasPermissionDirective,
    TranslateModule,
    ShowIfDebugMode
  ]
})
export class InspectionTypeGridComponent extends BaseNodeGridComponent<InspectionTypeListItem> {
  protected readonly Permission = Permission;

  private treeHelperService = inject(TreeHelperService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private messageService = inject(CantaaMessageService);
  private inspectionTypesService = inject(InspectionTypesService);

  override mapTreeItems(selectedNode: WimTreeNode, items: InspectionTypeListItem[]): WimTreeNode[] {
    return items
      .map<WimTreeNode>(listItem => {

        let treeNode = {
          id: listItem.id,
          key: UuidHelperService.generateUuid(),
          label: listItem.name,
          data: `${listItem.id}`,
          icon: WimIcons.INSPECTION_ICON,
          nodeType: NodeType.INSPECTION_TYPE,
          route: RouteType.INSPECTION_TYPE,
          children: [] as TreeNode[],
          leaf: false,
          parent: selectedNode
        };
        listItem.treeNode = treeNode;

        treeNode.children = [
          this.treeHelperService.createToolSubCategoryRelationsTreeItem(treeNode)
        ];
        return treeNode;
      });
  }

  override async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode) {
    try {
      return await this.inspectionTypesService.getInspectionTypes(gridRequest, selectedNode);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async delete() {
    try {
      await this.inspectionTypesService.deleteInspectionType(this.selectedItem);
      this.messageService.success('DELETE.SUCCESS');
      await this.reFetchData();
    } catch (e) {
      this.errorHandler.handleError(e, 'DELETE.FAILED');
      throw e;
    }
  }

}



