<div class="tooldocument-wrapper">
  <div class="tooldocument-actions">
    <div class="add-existing-docuemnt">
    <form [formGroup]="formCreateNew" (ngSubmit)="onSave()">
      <mat-form-field>
        <mat-label>{{ 'DOCUMENT.SELECT_EXISTING' | translate }}</mat-label>
        <mat-select type="number" formControlName="documentId"  #attachFile>
          @for (document of documents; track document.id) {
            <mat-option type="number" [value]="document.id">{{document.id}} | {{document.fileName}}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <button mat-fab color="primary" type="submit" matTooltip="{{ 'DOCUMENT.ATTACH_SELECTED' | translate }}">
        <mat-icon>share</mat-icon>
      </button>
    </form>
    </div>

    <span class="example-spacer"></span>
    <input type="file" accept=".jpg, .jpeg, .png, .bmp, .pdf" class="file-input" (change)="onFileSelected($event)" #fileUpload>
    <div class="file-upload" align="end">
      <button matTooltip="{{ 'DOCUMENT.ADD_NEW' | translate }}" mat-fab color="primary" (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
    </div>

  </div>
  <mat-divider></mat-divider>
  <div class="tooldocument-cards">
    <ng-template [ngIf]="toolDocuments.length == 0">
      <div class="tooldocument-nodocuments">
        <h1>{{ 'DOCUMENTS.NOT_FOUND' | translate }}!</h1>
      </div>
    </ng-template>


    <mat-card *ngFor="let toolDocument of toolDocuments" class="tooldocument">
      <div class="img-wrapper" (click)="openDialog4DocumentShow( toolDocument.id,toolDocument.documentFileType )">
        <ng-template [ngIf]="toolDocument.documentFileType == ('jpg')
                          || toolDocument.documentFileType == ('jpeg')
                          || toolDocument.documentFileType == ('bmp')
                          || toolDocument.documentFileType == ('png')"
                     [ngIfElse]="noimgdiv">
          <img id="{{ toolDocument.documentFileType }}{{ toolDocument.documentId }}"
               mat-card-image src="" alt="IMG">
        </ng-template>
        <ng-template #noimgdiv>
          <div class="noimgdiv">
            {{ toolDocument.documentFileType.toUpperCase() }}
          </div>
        </ng-template>
      </div>
      <mat-card-content>
        <mat-card-title>{{ toolDocument.documentTypeDesignation }}</mat-card-title>
        <mat-card-subtitle>{{ 'CREATED' | translate }}: {{ toolDocument.createdOn }} | {{ 'BY' | translate }}: {{ toolDocument.createdBy }}</mat-card-subtitle>
        <mat-card-subtitle>{{ toolDocument.documentText}}</mat-card-subtitle>
      </mat-card-content>
      <mat-card-actions>
        <button mat-stroked-button (click)="openDialog4DocumentShow( toolDocument.id, toolDocument.documentFileType )">
          {{ 'SHOW' | translate }}
        </button>
        <button mat-stroked-button (click)="openDialog4DocumentEdit( toolDocument.id )">
          {{ 'EDIT' | translate }}
        </button>
        <button mat-stroked-button (click)="delete(toolDocument.id)">
          {{ 'DELETE.DELETE' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

