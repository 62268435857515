import {Component, OnInit} from '@angular/core';
import {MainTree} from '../main-tree/main-tree.component';
import {ToolGrid} from '../tool/tool-grid/tool-grid.component';
import {ToolDetail} from '../tool/tool-detail/tool-detail.component';
import {AppStateService} from '../../service/app-state.service';
import {WimTreeNode} from '../../ model/wim-tree.node';
import {SplitterModule} from 'primeng/splitter';
import {MainToolbar} from '../main-toolbar/main-toolbar.component';
import {TransactionGrid} from '../transaction/transaction-grid/transaction-grid.component';
import {ToolLocationGrid} from '../tool-location/tool-location-grid/tool-location-grid.component';
import {TransactionDetail} from '../transaction/transaction-detail/transaction-detail.component';
import {ToolLocationDetailComponent} from '../tool-location/tool-location-detail/tool-location-detail.component';
import {ToolTabList} from '../tool/tool-tab-list/tool-tab-list.component';
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from '@angular/router';
import {TreeHelperService} from '../../service/helper/tree-helper.service';
import {ToolCategoryGrid} from '../tool-category/tool-category-grid/tool-category-grid.component';
import {ToolCategoryDetail} from '../tool-category/tool-category-detail/tool-category-detail.component';
import {ToolTabDetailComponent} from "../tool/tool-tab-detail/tool-tab-detail.component";
import {
  ToolLocationTabDetailComponent
} from '../tool-location/tool-location-tab-detail/tool-location-tab-detail.component';
import {ToolCategoryTabComponent} from '../tool-category/tool-category-tab/tool-category-tab.component';
import {InspectionTypeGridComponent} from '../inspection/inspection-type-grid/inspection-type-grid.component';
import {InspectionTypeDetailComponent} from '../inspection/inspection-type-detail/inspection-type-detail.component';
import {RouteType} from '../../constants/route-type';
import {filter} from "rxjs";

@Component({
  selector: 'wim-main-layout',
  standalone: true,
  imports: [
    RouterModule,
    MainTree,
    SplitterModule,
    MainToolbar,
    ToolTabList,
    ToolGrid,
    ToolDetail,
    ToolLocationGrid,
    ToolLocationDetailComponent,
    TransactionGrid,
    TransactionDetail,
    ToolCategoryGrid,
    ToolCategoryDetail,
    ToolTabDetailComponent,
    ToolLocationTabDetailComponent,
    ToolCategoryTabComponent,
    InspectionTypeGridComponent,
    InspectionTypeDetailComponent
  ],
  templateUrl: './explorer.component.html',
  styleUrl: './explorer.component.scss'
})
export class Explorer implements OnInit {
  constructor(private appStateService: AppStateService,
              private treeHelperService: TreeHelperService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  async ngOnInit() {
    this.navigate(this.cutUrlToRootUrl(this.router.url));

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        this.navigate(this.removeParamsFromUrl(url));
      });
  }

  private cutUrlToRootUrl(url: string) {
    const urlWithoutParam = this.removeParamsFromUrl(url);

    // Split the URL by '/' and filter out any empty segments
    const urlSegments = urlWithoutParam.split('/').filter(segment => segment);

    // Join the first two segments back with a '/'
    return urlSegments.length > 1 ? '/' + urlSegments.slice(0, 2).join('/') : '/' + urlSegments.join('/');
  }

  private removeParamsFromUrl(url: string) {
    return url.split('?')[0];
  }

  private navigate(url: string) {
    const root = this.getRootNode(url);

    if (root) {
      this.appStateService.setRootNode(root);
      this.appStateService.setSelectedNode(root);
      this.router.navigate([root.route], {relativeTo: this.activatedRoute});
    }
  }

  private getRootNode(rootString: string | null): WimTreeNode | null {
    switch (rootString) {
      case '/explorer/' + RouteType.TOOL:
        return this.treeHelperService.toolRootItem;
      case '/explorer/' + RouteType.LOCATION:
        return this.treeHelperService.locationRootItem;
      case '/explorer/' + RouteType.TRANSACTION:
        return this.treeHelperService.transactionRootItem;
      case '/explorer/' + RouteType.CATEGORY:
        return this.treeHelperService.toolCategoryRootItem;
      case '/explorer/' + RouteType.INSPECTION_TYPE:
        return this.treeHelperService.inspectionTypeRootItem;
      case '/explorer/' + RouteType.DOCUMENT:
        return this.treeHelperService.documentRootItem;
      case '/explorer/' + RouteType.RESERVATION:
        return this.treeHelperService.reservationRootItem;
      default:
        return null;
    }
  }

}
