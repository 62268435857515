import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

function valid(value: any) {
  return value !== null
    && value !== undefined
    && typeof value !== 'string'
    && 'id' in value;
}

export const SelectedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>
  valid(control.value) ? null : { matchRequired: true };
