<div class="container ">
  <div class="row">
    <p-table
      #dt
      [value]="reservationItems"
      [sortMode]="'multiple'"
      [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *showIfDebugMode id="id" style="min-width:10rem" pSortableColumn="id" pResizableColumn>
            {{ 'ID' | translate }}
            <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th id="categoryName" style="min-width:10rem" pSortableColumn="categoryName" pResizableColumn>
            {{ 'CATEGORY.CATEGORY' | translate }}
            <p-sortIcon field="categoryName"></p-sortIcon>
          </th>
          <th id="amount" style="min-width:10rem" pSortableColumn="amount" pResizableColumn>
              {{ 'AMOUNT.AMOUNT' | translate }}
            <p-sortIcon field="amount"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td *showIfDebugMode>{{ item.id }}</td>
          <td>{{ item.categoryName }}</td>
          <td>{{ item.amount }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
