import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { OAuthService, provideOAuthClient } from 'angular-oauth2-oidc';
import { oauthInit } from './initializer/oauth.init';
import { environment } from '../environments/environment';
import { LoggedUserService } from './service/logged-user.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LocalizationCustomLoader } from './service/LocalizationCustomLoader';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';

export function HttpLoaderFactory(http: HttpClient, config: PrimeNGConfig) {
  return new LocalizationCustomLoader(http, config);
}

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient, PrimeNGConfig],
  },
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    // provideNativeDateAdapter(MY_DATE_FORMATS),
    // provideNativeDateAdapter(),
    provideMomentDateAdapter(undefined, { strict: true, useUtc: true }),
    MessageService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom([
      TranslateModule.forRoot(provideTranslation())
    ]),
    provideOAuthClient({
        resourceServer: {
          // allowedUrls: ['http://localhost:8081/api', environment.apiUrl],
          allowedUrls: [environment.apiUrl],
          sendAccessToken: true
        }
      }
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: oauthInit,
      multi: true,
      deps: [OAuthService, LoggedUserService],
    },
    provideAnimationsAsync(),
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    }
  ]
};

