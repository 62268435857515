import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import {firstValueFrom, map, tap} from 'rxjs';
import {ToolDocument} from "../ model/tool-document.models";
import {ConsoleLoggerService} from "./console-logger.service";
import {ToolLocationDetail} from "../ model/tool-location-detail.model";
import {ToolDocumentDetail} from "../ model/tool-document-detail.models";
import {ToolCategoryDetailItem} from "../ model/tool-category-detail.model";
import {ToolDetailItem} from "../ model/tool-detail.model";
import {DocumentService} from "./document.service";


@Injectable({
  providedIn: 'root'
})
export class ToolDocumentService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private documentService: DocumentService,
              private log: ConsoleLoggerService) {
  }

  generateImgElementId4Tool(toolid: number |string) :string {
    return 'img_toolid_' + toolid;
  }

  async getDefaultPhotoAndRender(toolId: number){
    this.getDefaultPhotoScaledAndRender(toolId,-1,-1);
  }

  async getDefaultPhotoScaled35x35AndRender(toolId: number){
    this.getDefaultPhotoScaledAndRender(toolId,35,35);
  }

  async getDefaultPhotoScaled70x70AndRender(toolId: number|string){
    this.getDefaultPhotoScaledAndRender(toolId,70,70);
  }

  async getDefaultPhotoScaledAndRender(toolId: number|string, width: number, heigth: number){
    const imgEleId = this.generateImgElementId4Tool(toolId);
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tooldocument/defaultphoto/${toolId}`;
    let toolDocument = await firstValueFrom(this.http.get<ToolDocument>(url, { params: params }));

    if (toolDocument.documentId == -1) {  // NO_DEFAULT_PHOTO_FOUND
      this.documentService.disableImgElement(imgEleId);
    } else {
      let imgBlob;
      if (width == -1 && heigth == -1) {
        imgBlob = await this.documentService.getBlob4Id(toolDocument.documentId);
      } else {
        imgBlob = await this.documentService.getBlob4IdScaled(toolDocument.documentId, width, heigth);
      }
      this.documentService.processImageAndRender(imgBlob, imgEleId);
      this.documentService.enableImgElement(imgEleId);
    }
  }

  async getDefaultPhotoScaled2ImgEleId(toolId: number, imgEleId: string,width: number, heigth: number){
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tooldocument/defaultphoto/${toolId}`;
    let toolDocument = await firstValueFrom(this.http.get<ToolDocument>(url, { params: params }));

    if (toolDocument.documentId == -1) {  // NO_DEFAULT_PHOTO_FOUND
      this.documentService.disableImgElement(imgEleId);
    } else {
      let imgBlob;
      if (width == -1 && heigth == -1) {
        imgBlob = await this.documentService.getBlob4Id(toolDocument.documentId);
      } else {
        imgBlob = await this.documentService.getBlob4IdScaled(toolDocument.documentId, width, heigth);
      }
      this.documentService.processImageAndRender(imgBlob, imgEleId);
      this.documentService.enableImgElement(imgEleId);
    }
  }

  async getDefaultPhoto2ImgEleId(toolId: number, imgEleId: string){
    this.getDefaultPhotoScaled2ImgEleId(toolId, imgEleId, -1,-1);
  }

  async getDefaultPhotoScaled70x70_2ImgEleId(toolId: number, imgEleId: string){
    this.getDefaultPhotoScaled2ImgEleId(toolId, imgEleId, 70,70);
  }

  getToolDocumentDetail(toolDocumentId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tooldocument/${toolDocumentId}`;
    // return firstValueFrom(this.http.get<ToolDocument>(url, { params: params })
    //   .pipe(tap(r => this.log.debug('tool document: ', r))));
    return firstValueFrom(this.http.get<ToolDocument>(url, { params: params }));
  }

  getAllToolDocuments4ToolId(toolId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tooldocument/tool/${toolId}`;
    return firstValueFrom(this.http.get<CollectionDto<ToolDocument>>(url, { params: params })
      .pipe(tap(r => this.log.debug('tool document: ', r))));
  }

  deleteToolDocument(toolDocumentId: number){
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tooldocument/${toolDocumentId}`;
    const observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('toolDocument deleted: ', r)))

    return firstValueFrom(observable)
  }

  newToolDocument(detail: ToolDocumentDetail) {
    const params = this.httpClientHelper.createHttpParams();
    params.append('Content-Type', 'multipart/form-data');

    let url = environment.apiUrl + `/tooldocument/newtooldocument`;
    let observable = this.http.post<number>(url, detail, { params: params })
      .pipe(tap(r => this.log.debug('saved: ', r)))

    return firstValueFrom(observable)
  }

  uploadDocumentForTool(toolId: number, file: File){
    const formData = new FormData();
    formData.append("file", file);

    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tooldocument/uploaddocument/tool/${toolId}`;

    let observable = this.http.post(url, formData, { params: params })
      .pipe(tap(r => this.log.debug('saved: ', r)))

    return firstValueFrom(observable)
  }

  saveToolDocument(detail: ToolDocument) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tooldocument/savetooldocument`;
    let observable = this.http.post<ToolDetailItem>(url, detail, { params: params })
      .pipe(tap(r => this.log.debug('saved tool: ', r)))

    return firstValueFrom(observable)
  }

}
