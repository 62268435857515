<div class="container ">
  <form [formGroup]="detailForm" (ngSubmit)="onSave()">
    <div class="row">
      <div class="col-6 flex" style="display: flex;justify-content: center; ">

        <ng-template [ngIf]="document.fileType == ('jpg')
                          || document.fileType == ('jpeg')
                          || document.fileType == ('bmp')
                          || document.fileType == ('png')"
                     [ngIfElse]="noimgdiv">
        <img id="document_detail_photo" class="img-defaultphoto-icon" src="" style="width:300px; height: 300px;">
        </ng-template>
        <ng-template #noimgdiv>
          <div style="width:300px;
                        height:300px;
                        text-align: center;
                        vertical-align: middle;
                        line-height: 300px;
                        background-color: lightgray;
                        color: black;
                        font-weight: bold;
                        font-size: 60px;
                        border-radius: 25px;
                        border: 1px solid gray">
            {{ document.fileType.toUpperCase() }}
          </div>
        </ng-template>
      </div>
    </div>
    <br>
    <mat-divider></mat-divider>
    <br>
    <div class="row">
      <div *showIfDebugMode class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Id</mat-label>
          <input id="id" matInput formControlName="id">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'STATUS' | translate }}</mat-label>
          <input id="status" matInput formControlName="status">
        </mat-form-field>
      </div>
    </div>

    <div class="row">

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'FILENAME' | translate }}</mat-label>
          <input id="fileName" matInput formControlName="fileName" >
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'FILETYPE' | translate }}</mat-label>
          <input id="fileType" matInput formControlName="fileType">
        </mat-form-field>
      </div>

    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'TEXT' | translate }}</mat-label>
          <input id="text" matInput formControlName="text">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6" *hasPermission="Permission.DOCUMENT_EDIT">
        <button type="submit" mat-raised-button color="primary" [disabled]="detailForm.pristine || !detailForm.valid" class="m-1">
          {{ 'SAVE.SAVE' | translate }}
        </button>
        <button type="button" class="m-1" mat-raised-button (click)="onCancel()" [disabled]="detailForm.pristine">
          {{ 'CANCEL' | translate }}
        </button>
        <button type="button" class="m-1" mat-raised-button (click)="onDelete()">
          {{ 'DELETE.DELETE' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
