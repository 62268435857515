export const Permission = {
  TOOL_READ: "tool_read",
  TOOL_EDIT: "tool_edit",
  TOOL_CREATE: "tool_create",
  TOOL_DELETE: "tool_delete",
  LOCATION_READ: "location_read",
  LOCATION_EDIT: "location_edit",
  LOCATION_CREATE: "location_create",
  LOCATION_DELETE: "location_delete",
  CATEGORY_READ: "category_read",
  CATEGORY_EDIT: "category_edit",
  CATEGORY_CREATE: "category_create",
  CATEGORY_DELETE: "category_delete",
  INSPECTION_TYPE_READ: "inspection_type_read",
  INSPECTION_TYPE_EDIT: "inspection_type_edit",
  INSPECTION_TYPE_CREATE: "inspection_type_create",
  INSPECTION_TYPE_DELETE: "inspection_type_delete",
  TRANSACTION_READ: "transaction_read",
  DOCUMENT_READ: "document_read",
  DOCUMENT_EDIT: "document_edit",
  DOCUMENT_CREATE: "document_create",
  DOCUMENT_DELETE: "document_delete",
  TABLE_READ: "table_read",
  TABLE_EDIT: "table_edit",
  TABLE_CREATE: "table_create",
  TABLE_DELETE: "table_delete",
  BOOKING_MANAGER: "booking_manager",
  RESERVATION_READ: "reservation_read",
  RESERVATION_EDIT: "reservation_edit",
  RESERVATION_CREATE: "reservation_create",
  RESERVATION_DELETE: "reservation_delete",
  DEV_MODE: "dev_mode",
  SYSTEM_INFO: "system_info",
  SYSTEM_JOB: "system_job",
  SYSTEM_SETTINGS: "system_settings",
  SEARCH: "search",
};
