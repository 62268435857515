<div class="container">

  <div class="row">
    <div class="col-4">
      <wim-suggestion-input #toolSuggestionInput
                            label="test search"
                            [fetchSuggestions]="fetchSuggestions"
                            [displayFn]="DisplayHelper.toolDisplayFn"
                            (onOptionSelected)="optionsSelected($event)"/>
    </div>
  </div>

  <button type="button" mat-raised-button (click)="onDisable()">
    disable
  </button>

  <div class="mt-3">
    <button type="button" mat-raised-button (click)="clearChildAutocomplete()">Clear Autocomplete</button>
  </div>

</div>
