import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {PaginatorModule} from 'primeng/paginator';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {AppStateService} from '../../../service/app-state.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {ConsoleLoggerService} from '../../../service/console-logger.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {NodeType} from '../../../constants/node-type';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {InspectionTypeDetail} from '../../../ model/inspection-type.detail';
import {InspectionTypesService} from '../../../service/inspection-types.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteHelper} from '../../../service/helper/route-helper';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {LoggedUserService} from '../../../service/logged-user.service';
import {TranslateModule} from '@ngx-translate/core';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-inspection-type-detail',
  standalone: true,
  imports: [
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    PaginatorModule,
    ReactiveFormsModule,
    HasPermissionDirective,
    TranslateModule,
    ShowIfDebugMode
  ],
  templateUrl: './inspection-type-detail.component.html',
  styleUrl: './inspection-type-detail.component.scss'
})
export class InspectionTypeDetailComponent implements OnInit {
  protected readonly Permission = Permission;
  protected readonly destroyRef = inject(DestroyRef);

  detailForm = this.fb.group({
    id: this.fb.control<number | null>({ value: null, disabled: true }),
    name: ['', [Validators.required]],
    firstInspectionMonth: [0, [Validators.required, Validators.min(0)]],
    nextInspectionMonth: [0, [Validators.required, Validators.min(0)]],
  });

  constructor(private fb: FormBuilder,
              private appStateService: AppStateService,
              private inspectionTypesService: InspectionTypesService,
              private messageService: CantaaMessageService,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService,
              private route: ActivatedRoute,
              private router: Router,
              private loggedUserService: LoggedUserService) {
  }

  async ngOnInit() {
    const onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.INSPECTION_TYPE) {
          return;
        }
        await this.initDetail(selectedNode);
      });

    this.destroyRef.onDestroy(() => {
      onSelectNodeSubscription.unsubscribe();
    });
  }

  private async initDetail(selectedNode: WimTreeNode) {
    const detail = await this.fetchDetail(selectedNode);
    this.setDetailToForm(detail);

    if (!this.loggedUserService.hasPermission(Permission.INSPECTION_TYPE_EDIT)) {
      this.detailForm.disable();
    }
  }

  private async fetchDetail(selectedNode: WimTreeNode) {
    try {
      return await this.inspectionTypesService.getInspectionTypeDetail(selectedNode.id!);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private setDetailToForm(detail: InspectionTypeDetail) {
    this.detailForm.reset(
      {
        id: detail.id,
        name: detail.name,
        firstInspectionMonth: detail.firstInspectionMonth,
        nextInspectionMonth: detail.nextInspectionMonth
      }
    );
  }

  async onSave() {
    this.log.debug('on save', this.detailForm.value);

    const detail = {
      id: this.detailForm.getRawValue().id,
      name: this.detailForm.getRawValue().name,
      firstInspectionMonth: this.detailForm.getRawValue().firstInspectionMonth,
      nextInspectionMonth: this.detailForm.getRawValue().nextInspectionMonth
    } as InspectionTypeDetail;

    try {
      await this.inspectionTypesService.saveInspectionType(detail);
      this.messageService.success('SAVE.SUCCESS');

      if (RouteHelper.isInNewState(this.route)) {
        await RouteHelper.navigateToGrid(this.route, this.router);
      }
    } catch (e) {
      this.errorHandler.handleError(e, 'SAVE.FAILED');
    }
  }

  async onCancel() {
    if (RouteHelper.isInNewState(this.route)) {
      this.detailForm.reset();
    } else {
      let selectedNodeValue = this.appStateService.selectedNodeValue;
      await this.initDetail(selectedNodeValue);
    }
  }

}
