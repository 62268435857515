<div class="container">
  <form [formGroup]="detailForm" (ngSubmit)="onSave()">

    <div class="row">

      <input hidden formControlName="id">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'NAME' | translate }}</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LEVEL_TYPE.LEVEL_TYPE' | translate }}</mat-label>
          <input matInput formControlName="levelType">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field>
          <mat-label>{{ 'CATEGORY.PARENT' | translate }}</mat-label>
          <mat-select formControlName="parentCategory">
            @for (category of parentCategories; track category) {
              <mat-option [value]="category">{{ category.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      @if (detailForm.getRawValue().levelType === ToolConstants.LEVEL_TYPE.TOOL) {
        <div class="col-3">
          <mat-form-field>
            <mat-label>{{ 'TOOLTYPE' | translate }}</mat-label>
            <mat-select formControlName="toolType">
              @for (toolType of toolTypes; track toolType) {
                <mat-option [value]="toolType">{{ toolType.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }


    </div>

    <!--    <div class="row">-->

    <!--      <div class="col-3">-->
    <!--        <label for="firstName">First name</label>-->
    <!--        <input id="firstName" type="text" formControlName="firstName" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-3">-->
    <!--        <label for="lastName">Last name</label>-->
    <!--        <input id="lastName" type="text" formControlName="lastName" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-3">-->
    <!--        <label for="salutation">Salutation</label>-->
    <!--        <input id="salutation" type="text" formControlName="salutation" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--    </div>-->

    <!--    <div class="row">-->

    <!--      <div class="col-3">-->
    <!--        <label for="street">Street</label>-->
    <!--        <input id="street" type="text" formControlName="street" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-3">-->
    <!--        <label for="city">City</label>-->
    <!--        <input id="city" type="text" formControlName="city" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-2">-->
    <!--        <label for="zipCode">Zip code</label>-->
    <!--        <input id="zipCode" type="text" formControlName="zipCode" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-1">-->
    <!--        <label for="countryCode">Country</label>-->
    <!--        <input id="countryCode" type="text" formControlName="countryCode" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--    </div>-->

    <!--    <div class="row">-->

    <!--      <div class="col-3">-->
    <!--        <label for="phone">Phone</label>-->
    <!--        <input id="phone" type="text" formControlName="phone" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-3">-->
    <!--        <label for="email">Email</label>-->
    <!--        <input id="email" type="text" formControlName="email" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-3">-->
    <!--        <label for="poBox">POBox</label>-->
    <!--        <input id="poBox" type="text" formControlName="poBox" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--    </div>-->

    <!--    <div class="row">-->

    <!--      <div class="col-3">-->
    <!--        <label for="parentId">Parent id</label>-->
    <!--        <input id="parentId" type="text" formControlName="parentId" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-3">-->
    <!--        <label for="parentLocationTypeName">Parent location type</label>-->
    <!--        <input id="parentLocationTypeName" type="text" formControlName="parentLocationTypeName" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-3">-->
    <!--        <label for="parentRfid">Parent id</label>-->
    <!--        <input id="parentRfid" type="text" formControlName="parentRfid" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="row">-->

    <!--      <div class="col-3">-->
    <!--        <label for="parentName1">Parent name1</label>-->
    <!--        <input id="parentName1" type="text" formControlName="parentName1" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--      <div class="col-3">-->
    <!--        <label for="parentName2">Parent name2</label>-->
    <!--        <input id="parentName2" type="text" formControlName="parentName2" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->


    <!--      <div class="col-3">-->
    <!--        <label for="parentSalutation">Parent salutation</label>-->
    <!--        <input id="parentSalutation" type="text" formControlName="parentSalutation" pInputText class="p-inputtext-sm w-100">-->
    <!--      </div>-->

    <!--    </div>-->

    <div class="row">
      <div class="col-3 my-2" *hasPermission="Permission.CATEGORY_EDIT">
        <button type="submit" mat-raised-button color="primary" class="me-2" [disabled]="!detailForm.valid">
          {{ 'SAVE.SAVE' | translate }}
        </button>
        <button type="button" mat-raised-button (click)="onCancel()">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>

  </form>

</div>
