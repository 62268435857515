import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {environment} from "../../environments/environment";


@Directive({
  selector: '[showIfDebugMode]',
  standalone: true,
})

export class ShowIfDebugMode {

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef
  ) {
    // Show or hide element based on environment property
    if (environment.debugMode) {
      this.vcRef.createEmbeddedView(this.templateRef);
    } else {
      this.vcRef.clear();
    }
  }
}
