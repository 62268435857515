import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AppInfoService} from '../../service/app-info.service';
import {CantaaErrorHandlerService} from '../../service/cantaa-error-handler.service';
import {AppInfoModel} from '../../ model/app-info.model';
import {KeyValuePipe, NgForOf} from '@angular/common';

@Component({
  selector: 'wim-about',
  standalone: true,
  imports: [
    KeyValuePipe,
    NgForOf
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class About implements OnInit {

  frontendAppVersion: string;
  backendAppInfo: AppInfoModel | null = null;

  constructor(private appInfoService: AppInfoService, private errorHandler: CantaaErrorHandlerService) {
    this.frontendAppVersion = environment.appVersion;
  }

  async ngOnInit() {
    this.backendAppInfo = await this.fetchAppInfo();
  }

  private async fetchAppInfo() {
    try {
      return await this.appInfoService.getAppInfo();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }


}
