import { Injectable } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { GridRequest } from '../../ model/grid-request.model';
import { FilterMetadata, SortMeta } from 'primeng/api';
import { SortDefinition } from '../../ model/sort-definition.model';
import { FilterDefinition } from '../../ model/filter-definition.model';
import { ConsoleLoggerService } from '../console-logger.service';
import { GridFilterConstants } from '../../constants/grid-filter-constants';

@Injectable({
  providedIn: 'root'
})
export class GridHelperService {

  constructor(private log: ConsoleLoggerService) {
  }

  createGridRequest(lazyLoadMetadata: TableLazyLoadEvent) {
    const rowsPerPage = lazyLoadMetadata.rows ?? 10;
    const firstElementIndex = lazyLoadMetadata.first ?? 0;
    const pageNumber = firstElementIndex / rowsPerPage;

    let gridRequest: GridRequest = {
      pageRequest: {
        pageNumber: pageNumber,
        pageSize: rowsPerPage,
      },
      filterDefinitions: this.mapFilterDefinitions(lazyLoadMetadata.filters),
      sortDefinitions: this.mapSortDefinitions(lazyLoadMetadata.multiSortMeta)
    }
    return gridRequest;
  }

  public mapSortDefinitions(multiSortMeta: SortMeta[] | undefined | null): SortDefinition[] {
    if (!multiSortMeta) {
      return [];
    }

    return multiSortMeta
      .map<SortDefinition>((sortMeta, index) => ({
        sortBy: sortMeta.field,
        sortOrder: sortMeta.order > 0 ? 'ASC' : 'DESC',
        index: index
      }));
  }

  public mapFilterDefinitions(filters: { [p: string]: FilterMetadata | FilterMetadata[] | undefined } | undefined): FilterDefinition[] {
    if (!filters) {
      return [];
    }

    const filterDefinitions: FilterDefinition[] = [];

    for (const filterField in filters) {
      const filterMeta = filters[filterField];
      if (Array.isArray(filterMeta)) {

        const items = filterMeta
          .filter(f => f.value !== null || f.matchMode === GridFilterConstants.IS_NOT_EMPTY
            || f.matchMode === GridFilterConstants.IS_EMPTY)
          .map<FilterDefinition>((meta) => ({
            propertyName: filterField,
            value: meta.value,
            filterOperator: meta.matchMode
          }));

        filterDefinitions.push(...items);
      }
    }
    this.log.debug('filters: ', filterDefinitions);

    return filterDefinitions;
  }

}
