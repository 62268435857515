<div class="container ">
  <div class="row">
    <p-table
      #dt
      [value]="inventory"
      [sortMode]="'multiple'"
      [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="defaultpicture" style="width:1px" pResizableColumn>
          </th>
          <th id="locationPretty" style="min-width:10rem" pSortableColumn="locationPretty" pResizableColumn>
            {{ 'LOCATION.LOCATION' | translate }}
            <p-sortIcon field="locationPretty"></p-sortIcon>
          </th>
          <th id="amount" style="min-width:10rem" pSortableColumn="amount" pResizableColumn>
            {{ 'AMOUNT.AMOUNT' | translate }}
            <p-sortIcon field="amount"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-inventory>
        <tr>
          <td style="padding: 0px;"><img id="{{ toolLocationDocumentService.getImgElementId(inventory.locationId) }}" class="img-defaultphoto-icon" src=""></td>
          <td>{{ inventory.locationPretty }}</td>
          <td>{{ inventory.amount }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
