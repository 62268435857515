import {Component, inject} from '@angular/core';
import {BaseNodeGridComponent} from '../../base-components/base-node-grid.component';
import {DocumentService} from '../../../service/document.service';
import {TreeHelperService} from '../../../service/helper/tree-helper.service';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {NodeType} from '../../../constants/node-type';
import {TableLazyLoadEvent, TableModule} from 'primeng/table';
import {SharedModule} from 'primeng/api';
import {DocumentListItem} from '../../../ model/document-list-item.model';
import {WimIcons} from '../../../constants/wim-icons';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {RouteType} from '../../../constants/route-type';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from "@angular/common";
import {UuidHelperService} from '../../../service/helper/uuid-helper.service';
import {TranslateModule} from '@ngx-translate/core';
import {GridRequest} from '../../../ model/grid-request.model';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-document-grid',
  standalone: true,
  imports: [
    SharedModule,
    TableModule,
    MatButton,
    MatIcon,
    NgIf,
    TranslateModule,
    ShowIfDebugMode
  ],
  templateUrl: './document-grid.component.html',
  styleUrl: './document-grid.component.scss'
})
export class DocumentGrid extends BaseNodeGridComponent<DocumentListItem> {
  protected override nodeType = NodeType.DOCUMENT_RELATION;

  private documentService = inject(DocumentService);
  private treeHelperService = inject(TreeHelperService);
  private errorHandler = inject(CantaaErrorHandlerService);

  constructor() {
    super();
  }

  override async fetchData(selectedNode: WimTreeNode, lazyLoadMetadata: TableLazyLoadEvent) {
    await super.fetchData(selectedNode, lazyLoadMetadata);

    this.gridItems.forEach(document => {
      if (document.fileType != 'pdf') {
        // this.documentService.getDocumentFromIdAndRender(document.id, 'img_document_' + document.id);
        this.documentService.getDocumentScaled4IdAndRender(document.id, 'img_document_' + document.id, 70, 70);
      }
    });
  }

  override async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode) {
    try {
      return await this.documentService.getDocuments(gridRequest, selectedNode);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw (e);
    }
  }

  override mapTreeItems(selectedNode: WimTreeNode, items: DocumentListItem[]): WimTreeNode[] {
    return items
      .map<WimTreeNode>(t => {
        let treeNode = {
          id: t.id,
          key: UuidHelperService.generateUuid(),
          label: t.id + ' ' + t.fileName,
          data: t.id + t.fileName,
          icon: WimIcons.DOCUMENT_ICON,
          nodeType: NodeType.DOCUMENT,
          route: RouteType.DOCUMENT,
          children: [] as WimTreeNode[],
          leaf: false,
          parent: selectedNode
        };
        t.treeNode = treeNode;

        treeNode.children = [this.treeHelperService.createToolLocationRelationsTreeItem(treeNode),
          this.treeHelperService.createToolRelationsTreeItem(treeNode)];

        return treeNode;
      });
  }

}
