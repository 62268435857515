import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {AppStateService} from '../../../service/app-state.service';
import {NodeType} from '../../../constants/node-type';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {TransactionService} from '../../../service/transaction.service';
import {TableModule} from 'primeng/table';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatButton} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-transaction-detail',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TableModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatButton,
    TranslateModule,
    ShowIfDebugMode
  ],
  templateUrl: './transaction-detail.component.html',
  styleUrl: './transaction-detail.component.scss'
})
export class TransactionDetail implements OnInit {

  detailForm = this.fb.group({
    id: ({ value: 0, disabled: true }),
    createdOn: ({ value: '', disabled: true }),
    transactionTypeCode: (''),
    transactionTypeName: (''),
    fromId: ({ value: 0, disabled: true }),
    fromName1: (''),
    fromName2: (''),
    fromLocationType: (''),
    toId: ({ value: 0, disabled: true }),
    toName1: (''),
    toName2: (''),
    toLocationType: (''),
    movedLocationId: ({ value: 0, disabled: true }),
    movedLocationName1: (''),
    movedLocationName2: (''),
    movedLocationType: ('')
  });

  protected readonly destroyRef = inject(DestroyRef);

  constructor(private fb: FormBuilder,
              private appStateService: AppStateService,
              private transactionService: TransactionService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  ngOnInit() {
    this.detailForm.disable();

    const onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TRANSACTION) {
          return;
        }
        await this.fetchDetail(selectedNode);
      });

    this.destroyRef.onDestroy(() => {
      onSelectNodeSubscription.unsubscribe();
    });
  }

  private async fetchDetail(selectedNode: WimTreeNode) {
    const detail = await this.fetchTransactionDetail(selectedNode);

    this.detailForm.setValue(
      {
        id: detail.id,
        createdOn: detail.createdOn,
        transactionTypeCode: detail.transactionTypeCode,
        transactionTypeName: detail.transactionTypeName,
        fromId: detail.fromId,
        fromName1: detail.fromName1,
        fromName2: detail.fromName2,
        fromLocationType: detail.fromLocationType,
        toId: detail.toId,
        toName1: detail.toName1,
        toName2: detail.toName2,
        toLocationType: detail.toLocationType,
        movedLocationId: detail.movedLocationId,
        movedLocationName1: detail.movedLocationName1,
        movedLocationName2: detail.movedLocationName2,
        movedLocationType: detail.movedLocationType
      }
    );
  }

  private async fetchTransactionDetail(selectedNode: WimTreeNode) {
    try {
      return await this.transactionService.getTransactionDetail(selectedNode.id!)
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

}
