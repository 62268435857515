<div class="container ">
  <div class="row">
    <p-table
      #dt
      [value]="systemInfos"
      [sortMode]="'multiple'"
      [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="label" style="min-width:18rem" pSortableColumn="label" pResizableColumn>
            {{ 'LABEL' | translate }}
          </th>
          <th id="value" style="min-width:10rem" pSortableColumn="value" pResizableColumn>
            {{ 'VALUE' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.label }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
