import { Component, ElementRef, OnInit, viewChild } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { Table, TableModule } from 'primeng/table';
import { TestDataComponent } from '../../../test-data/test-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToolDetailItem } from '../../../../ model/tool-detail.model';
import { BookToolDto, BulkBookItemDto, ToolBookingDto, ToolBookingValidateItemDto } from '../../../../ model/booking.models';
import { DropdownItemDto } from '../../../../ model/dropdown-item.model';
import { ToolLocationDetail } from '../../../../ model/tool-location-detail.model';
import { ToolService } from '../../../../service/tool.service';
import { InventoryService } from '../../../../service/inventory.service';
import { ToolLocationService } from '../../../../service/tool-location.service';
import { TransactionService } from '../../../../service/transaction.service';
import { CantaaMessageService } from '../../../../service/cantaa-message.service';
import { ToolLocationDocumentService } from '../../../../service/tool-location-document.service';
import { ToolDocumentService } from '../../../../service/tool-document.service';
import { DocumentService } from '../../../../service/document.service';
import { ToolConstants } from '../../../../constants/tool-constants';
import { UuidHelperService } from '../../../../service/helper/uuid-helper.service';
import { PTableHelper } from '../../../../service/helper/p-table-helper';
import { LocationType } from '../../../../constants/location-type';
import { FocusHelper } from '../../../../service/helper/focus-helper';
import { SuggestionInputComponent } from '../../../inputs/suggestion-input/suggestion-input.component';
import { ToolLocationListItem } from '../../../../ model/tool-location-list-item.model';
import { ToolListItem } from '../../../../ model/tool-list-item.model';
import { DisplayHelper } from '../../../../service/helper/DisplayHelper';

@Component({
  selector: 'wim-bulk-booking-return',
  standalone: true,
  imports: [
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatHint,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatRadioButton,
    MatRadioGroup,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    TestDataComponent,
    TranslateModule,
    FormsModule,
    SuggestionInputComponent
  ],
  templateUrl: './bulk-booking-return.component.html',
  styleUrl: './bulk-booking-return.component.scss'
})
export class BulkBookingReturnComponent implements OnInit {
  public readonly RETURN = 'Return';
  protected readonly DisplayHelper = DisplayHelper;

  selectedTool: ToolDetailItem | null = null;

  dataTable = viewChild.required<Table>('dt');
  fromLocationSuggestionInput = viewChild.required<SuggestionInputComponent<ToolLocationListItem>>('fromLocationSuggestionInput');
  toolSuggestionInput = viewChild.required<SuggestionInputComponent<ToolListItem>>('toolSuggestionInput');

  bookItems: BulkBookItemDto[] = [];

  myLocationForm = this.fb.group({
    myLocation: this.fb.control<DropdownItemDto | null>(null)
  });

  amountForm = this.fb.group({
    toolAmount: [{ value: 1, disabled: true }, [Validators.required, Validators.min(0)]]
  });

  toolLocations: DropdownItemDto[] = [];
  selectedFromLocation: ToolLocationDetail | null = null;

  constructor(private fb: FormBuilder,
              private toolService: ToolService,
              private inventoryService: InventoryService,
              private toolLocationService: ToolLocationService,
              private transactionService: TransactionService,
              private messageService: CantaaMessageService,
              private el: ElementRef,
              private toolLocationDocumentService: ToolLocationDocumentService,
              protected toolDocumentService: ToolDocumentService,
              private documentService: DocumentService) {
  }

  async ngOnInit() {
    this.toolLocations = await this.toolLocationService.getToolLocationsForBooking();
    const preselected = await this.preselectDefaultLocation();

    if (!preselected) {
      this.focusToMyLocation();
    }
  }

  private async preselectDefaultLocation() {
    let defaultLocationId = await this.toolLocationService.getDefaultLocationId();

    if (defaultLocationId == null) {
      return false;
    }
    let myLocation = this.toolLocations.find(i => i.id === defaultLocationId);
    if (myLocation) {
      this.myLocationForm.controls.myLocation.setValue(myLocation);
      this.onMyLocationChange();
      return true;
    }
    return false;
  }

  onMyLocationChange() {
    const fromLocationId = this.myLocationForm.value.myLocation?.id;;
    if (fromLocationId) {
      this.toolLocationDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(fromLocationId, 'return_myLocationForm_icon');
    }
    this.fromLocationSuggestionInput().clear();
    this.selectedFromLocation = null;
    this.fromLocationSuggestionInput().enable()
    this.fromLocationSuggestionInput().focus();
  }

  async onFromLocationSelection(toolLocationListItem: ToolLocationListItem) {
    this.selectedFromLocation = await this.toolLocationService.getToolLocationDetailById(toolLocationListItem.id);
    this.messageService.success('LOCATION.FOUND');

    if (this.selectedFromLocation.id !== null) {
      this.toolLocationDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(this.selectedFromLocation.id, 'fromToLocationForm_icon');
    }

    this.toolSuggestionInput().enable();
    this.toolSuggestionInput().focus();

    this.myLocationForm.controls.myLocation.disable();
    this.fromLocationSuggestionInput().disable();
  }

  async onToolSelection(toolListItem: ToolListItem) {
    this.selectedTool = await this.toolService.getToolDetail(toolListItem.id);
    this.messageService.success('TOOL.FOUND');

    if (this.selectedTool.toolProcessingType === ToolConstants.PROCESSING_TYPE.TOOL) {
      await this.processItem();
      return;
    }

    this.amountForm.controls.toolAmount.enable()

    const amount = await this.getToolAmount();
    this.amountForm.controls.toolAmount.setValue(amount);

    this.toolDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(this.selectedTool.id, 'tool_icon');
    this.focusToAmountInput();
  }

  private async getToolAmount() {
    const toolLocation = this.selectedFromLocation!;

    const locationTypeCode = toolLocation?.locationTypeCode;

    if (LocationType.CONTAINER != locationTypeCode && LocationType.EMPLOYEE != locationTypeCode) {
      return null;
    }

    const amount = await this.inventoryService.getInventoryAmountByLocation(toolLocation?.id!, this.selectedTool?.id!);

    return amount ?? null;
  }

  async onAmountSubmit() {
    await this.processItem();
  }

  private async processItem() {
    const fromLocationId = this.selectedFromLocation?.id;
    const toLocationId = this.myLocationForm.value.myLocation?.id;

    const itemToValidate = {
      fromLocationId: fromLocationId,
      toLocationId: toLocationId,
      toolId: this.selectedTool?.id,
      amount: this.amountForm.controls.toolAmount.value,
    } as ToolBookingValidateItemDto;

    let validationMessages = await this.transactionService.validateToolBooking(itemToValidate, this.RETURN);

    const bookItem = {
      id: UuidHelperService.generateUuid(),
      tool: this.selectedTool,
      amount: this.amountForm.controls.toolAmount.value,
      fromLocationId: fromLocationId,
      toLocationId: toLocationId,
      message: '',
      messages: validationMessages,
      active: true
    } as BulkBookItemDto;

    this.bookItems = this.bookItems.concat(bookItem);
    PTableHelper.navigateToBottomOfTable(this.dataTable(), this.bookItems.length);
    this.setUiAfterProcessItem(bookItem);
    this.toolSuggestionInput().focus();
  }

  private setUiAfterProcessItem(bookItem: BulkBookItemDto) {
    this.selectedTool = null;
    this.toolSuggestionInput().clear();

    this.amountForm.controls.toolAmount.setValue(1);
    this.amountForm.controls.toolAmount.disable();

    this.toolDocumentService.getDefaultPhotoScaled70x70AndRender(bookItem.tool.id);
    this.documentService.disableImgElement('tool_icon');
  }

  getActiveItems() {
    return this.bookItems.filter(i => i.active);
  }

  async onBulkBooking() {
    let tools = this.getActiveItems()
      .map(i => ({
        toolId: i.tool.id,
        amount: i.amount,
      } as ToolBookingDto));

    const booking = {
      transactionTypeCode: this.RETURN,
      fromLocationId: this.getActiveItems()[0].fromLocationId,
      toLocationId: this.getActiveItems()[0].toLocationId,
      tools: tools
    } as BookToolDto

    await this.transactionService.makeBooking(booking)

    this.messageService.success('BOOKING.SUCCESSFUL');
    this.setUiAfterBook();
  }

  private setUiAfterBook() {
    this.bookItems = [];

    this.myLocationForm.controls.myLocation.enable();

    this.selectedFromLocation = null;
    this.fromLocationSuggestionInput().clear();
    this.fromLocationSuggestionInput().enable()
    this.fromLocationSuggestionInput().focus();
    this.toolSuggestionInput().disable();
    this.amountForm.controls.toolAmount.disable();
  }

  focusToMyLocation() {
    FocusHelper.focusFormField(this.el, 'myLocation');
  }

  focusToAmountInput() {
    FocusHelper.focusFormField(this.el, 'toolAmount');
  }

  onUpdateActiveState(item: BulkBookItemDto) {
    item.active = !item.active;
  }

  getTdClass(item: BulkBookItemDto) {
    return item.active ? "" : 'strikethrough';
  }

  onCancel() {
    this.setUiAfterBook();
  }

  fromLocationFetchSuggestions = async (query: string): Promise<ToolLocationListItem[]> => {
    return await this.toolLocationService.searchLocations(query);
  };

  toolFetchSuggestions = async (query: string): Promise<ToolListItem[]> => {
    return await this.toolService.searchTools(query);
  };

}


