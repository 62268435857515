<mat-dialog-content>

  <ng-template [ngIf]="edit == false">
    <mat-toolbar>
      <mat-toolbar-row>
        <span>{{ 'DOCUMENT.SHOW' | translate }}</span>
        <span class="example-spacer"></span>
        <button mat-fab color="primary" mat-dialog-close matTooltip="{{ 'CLOSE' | translate }}">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </ng-template>


  <ng-template [ngIf]="edit == true">
    <form [formGroup]="detailForm" (ngSubmit)="onSave()">
      <mat-toolbar>
        <mat-toolbar-row>
          <span>{{ 'DOCUMENT.EDIT' | translate }}</span>
          <span class="example-spacer"></span>
          <button mat-fab color="primary" type="submit" matTooltip="{{ 'SAVE.SAVE' | translate }}">
            <mat-icon>save</mat-icon>
          </button>
          <button mat-fab color="primary" mat-dialog-close matTooltip="{{ 'CLOSE' | translate }}">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
      <div class="row">
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>Id</mat-label>
            <input id="id" matInput formControlName="id">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>{{ 'DOCUMENT.DOCUMENT' | translate }} - Id</mat-label>
            <input id="documentId" matInput formControlName="documentId">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>{{ 'CREATED_ON' | translate }}</mat-label>
            <input disabled id="createdOn" matInput value="{{ this.toolDocument?.createdOn }}">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>{{ 'CREATED_BY' | translate }}</mat-label>
            <input disabled id="createdBy" matInput value="{{ this.toolDocument?.createdBy }}">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>{{ 'MODIFIED_ON' | translate }}</mat-label>
            <input disabled id="modifiedOn" matInput value="{{ this.toolDocument?.modifiedOn }}">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>{{ 'MODIFIED_BY' | translate }}</mat-label>
            <input disabled id="modifiedBy" matInput value="{{ this.toolDocument?.modifiedBy }}">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>{{ 'FILETYPE' | translate }}</mat-label>
            <input disabled id="documentFileType" matInput value="{{ this.toolDocument?.documentFileType }}">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>{{ 'FILENAME' | translate }}</mat-label>
            <input disabled id="documentFileName" matInput value="{{ this.toolDocument?.documentFileName }}">
          </mat-form-field>
        </div>
      </div>


      <mat-form-field class="w-100">
        <mat-label>{{ 'TEXT' | translate }}</mat-label>
        <input matInput id="documentText" formControlName="documentText" type="text" name="text" value="">
      </mat-form-field>

      <mat-form-field class="w-100">
        <mat-label>{{ 'DOCUMENTTYPE' | translate }}</mat-label>
        <mat-select formControlName="documentTypeId">
          @for (documentType of documentTypes; track documentType) {
            <mat-option [value]="documentType">{{ documentType.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

    </form>
  </ng-template>

  <div class="document-wrapper">
    <ng-template [ngIf]="this.toolDocument?.documentFileType == 'pdf'">
      <iframe id="pdfToShow" title="pdf"></iframe>
    </ng-template>
    <img *ngIf="this.toolDocument?.documentFileType != 'pdf'" id="imageToShow" alt="DocumentImage">
  </div>
</mat-dialog-content>
