import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CantaaLocalizationService } from './cantaa-localization.service';

@Injectable({
  providedIn: 'root'
})
export class CantaaMessageService {

  constructor(private messageService: MessageService,
              private localization: CantaaLocalizationService) {
  }

  public success(summaryKey: string) {
    const summary = this.localization.instant(summaryKey);
    this.messageService.add({ severity: 'success', summary: summary, detail: undefined })
  }

  public error(summaryKey: string, detailKey?: string) {
    const summary = this.localization.instant(summaryKey);
    const detail = detailKey ? this.localization.instant(detailKey) : undefined;
    this.messageService.add({ severity: 'error', summary: summary, detail: detail, life: 10000 })
  }

}
