<div class="container ">

  @for (inv of splitDataByLocation(); track $index) {
    @if (!$first) {
      <div><br><h3>{{inv[0].locationPretty}}</h3></div>
    }
    <div class="row">
      <p-table
        #dt
        [value]="inv"
        [sortMode]="'multiple'"
        [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
      >
        <ng-template pTemplate="header">
          <tr>
            <th id="defaultpicture" style="width:1px" pResizableColumn>
            </th>
            <th id="mainCategoryName" style="width: 30%;min-width:10rem" pSortableColumn="mainCategoryName" pResizableColumn>
              {{ 'MAINCATEGORY' | translate }}
              <p-sortIcon field="mainCategoryName"></p-sortIcon>
            </th>
            <th id="subCategoryName" style="width: 30%;min-width:10rem" pSortableColumn="subCategoryName" pResizableColumn>
              {{ 'SUBCATEGORY' | translate }}
              <p-sortIcon field="subCategoryName"></p-sortIcon>
            </th>
            <th id="toolName" style="width: 30%;min-width:10rem" pSortableColumn="toolName" pResizableColumn>
                {{ 'TOOLCATEGORY' | translate }}
              <p-sortIcon field="toolName"></p-sortIcon>
            </th>
            <th id="amount" style="width: 10%;min-width:10rem" pSortableColumn="amount" pResizableColumn>
              {{ 'AMOUNT	' | translate }}
              <p-sortIcon field="amount"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-inv>
          <tr>
            <td style="padding: 0px;"><img id="{{ toolDocumentService.generateImgElementId4Tool(inv.toolId) }}" class="img-defaultphoto-icon" src=""></td>
            <td>{{ inv.mainCategoryName }}</td>
            <td>{{ inv.subCategoryName }}</td>
            <td>{{ inv.toolName }}</td>
            <td>{{ inv.amount }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }

</div>
