import {Component, model, output} from '@angular/core';
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {PaginatorModule} from 'primeng/paginator';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'wim-search-input',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    PaginatorModule,
    TranslateModule
  ],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent {

  onSearch = output<string>()

  // @Input() searchQuery: string | undefined = undefined;
  searchQuery = model<string | undefined>('');
  // protected searchQuery: string | undefined = undefined;
  // protected @Input() searchQuery = model('');

  search() {
    // this.onSearch.emit(this.searchQuery ? this.searchQuery : '');
    const value = this.searchQuery() ? this.searchQuery() : '';
    this.onSearch.emit(value!);
    // this.onSearch.emit(this.searchQuery);
  }

  onClear() {
    this.searchQuery.set('');
    this.search();
  }

}
