import { EMPTY, Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PrimeNGConfig } from 'primeng/api';

import * as primeLocaleDe from '../../assets/i18n/primeng/de.json';
import * as primeLocaleEn from '../../assets/i18n/primeng/en.json';
import * as primeLocaleEs from '../../assets/i18n/primeng/es.json';
import { LocaleHelper } from './locale-helper';

export class LocalizationCustomLoader implements TranslateLoader {
  constructor(private http: HttpClient, private config: PrimeNGConfig) {
  }

  primeLocales: { [index: string]: any } = { ...primeLocaleDe, ...primeLocaleEn, ...primeLocaleEs }

  getTranslation(langMarket: string): Observable<any> {
    const cantaaLocale = LocaleHelper.parseLocale(langMarket);

    let primeLocales = this.primeLocales[cantaaLocale.language];
    this.config.setTranslation(primeLocales)

    const httpParams = new HttpParams()
      .set('lang', cantaaLocale.language)
      .set('marketCode', cantaaLocale.market);

    let url = environment.apiUrl + `/localizations`;

    // return EMPTY;

    return this.http.get(url, { params: httpParams })
    // .pipe(
    //   catchError(this.handleError)
    // );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage); // Log the error message for debugging
    return EMPTY; // Return an empty observable
  }

}
