<div class="container">

  <div class="row">
    <div class="col-6">
      <form [formGroup]="locationForm" autocomplete="off">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION.DEFAULT' | translate }}</mat-label>
          <mat-select class="w-100" formControlName="defaultLocation">
            <mat-option></mat-option>
            @for (location of locations; track location.id) {
              <mat-option [value]="location">{{ location.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>


  <div class="col-4 my-2">
    <button mat-raised-button color="primary" class="me-2" (click)="onSave()">
      {{ 'SAVE.SAVE' | translate }}
    </button>

    <button mat-raised-button (click)="onCancel()">
      {{ 'CANCEL' | translate }}
    </button>

  </div>

</div>
