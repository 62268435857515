import { Component, OnInit } from '@angular/core';
import { MatTab, MatTabContent, MatTabGroup } from "@angular/material/tabs";
import { ToolGrid } from "../tool-grid/tool-grid.component";
import { ToolDetail } from "../tool-detail/tool-detail.component";
import { ToolDetailInventoryComponent } from "../tool-detail-inventory/tool-detail-inventory.component";
import { ToolDetailDocumentsComponent } from "../tool-detail-documents/tool-detail-documents.component";
import { ToolLocationDetailComponent } from '../../tool-location/tool-location-detail/tool-location-detail.component';
import { ToolDetailInspectionsComponent } from '../tool-detail-inspections/tool-detail-inspections.component';
import { TranslateModule } from "@ngx-translate/core";
import { ToolDetailInspectionComponent } from '../tool-detail-inspection/tool-detail-inspection.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'wim-tool-tab-detail',
  standalone: true,
  imports: [
    MatTab,
    MatTabContent,
    MatTabGroup,
    ToolGrid,
    ToolDetail,
    ToolDetailInventoryComponent,
    ToolDetailDocumentsComponent,
    ToolLocationDetailComponent,
    ToolDetailInspectionsComponent,
    TranslateModule,
    ToolDetailInspectionComponent
  ],
  templateUrl: './tool-tab-detail.component.html',
  styleUrl: './tool-tab-detail.component.scss'
})
export class ToolTabDetailComponent implements OnInit {

  showInspectionTab = false;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.showInspectionTab = this.router.url.includes('inspectable-tools');
  }

  ngOnInit() {
  }

}
