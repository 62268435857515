<div class="container ">

  <form [formGroup]="detailForm" (ngSubmit)="onSave()">
    <div class="row">
      <div class="col-9 flex" style="display: flex;justify-content: center; ">
        <img id="toollocation_icon" class="img-defaultphoto-icon" src="" style="width:150px; height: 150px;">
      </div>
    </div>
    <div class="row">

      <div *showIfDebugMode class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'ID' | translate }}</mat-label>
          <input id="id" matInput formControlName="id">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION_TYPE' | translate }}</mat-label>
          <mat-select formControlName="locationType" (selectionChange)="onLocationTypeChange($event.value)">
            @for (t of toolLocationTypes; track t.id) {
              <mat-option [value]="t">{{ t.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'RFID' | translate }}</mat-label>
          <input id="rfid" matInput formControlName="rfid">
          <mat-error>{{ FormErrorHelper.getErrorMessage(detailForm.controls.rfid) }}</mat-error>
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SALUTATION' | translate }}</mat-label>
          <mat-select formControlName="salutation">
            @for (salutation of salutations; track salutation.id) {
              <mat-option [value]="salutation">{{ salutation.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ labelName1() }}</mat-label>
          <input id="name1" matInput formControlName="name1">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ labelName2() }}</mat-label>
          <input id="lastName" matInput formControlName="name2">
        </mat-form-field>
      </div>


    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'STREET' | translate }}</mat-label>
          <input id="street" matInput formControlName="street">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'CITY' | translate }}</mat-label>
          <input id="city" matInput formControlName="city">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'ZIPCODE' | translate }}</mat-label>
          <input id="zipCode" matInput formControlName="zipCode">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'COUNTRY.CODE' | translate }}</mat-label>
          <input id="countryCode" matInput formControlName="countryCode" maxlength="2">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'PHONE' | translate }}</mat-label>
          <input id="phone" matInput formControlName="phone">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'EMAIL.EMAIL' | translate }}</mat-label>
          <input id="email" matInput formControlName="email">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'POBox' | translate }}</mat-label>
          <input id="poBox" matInput formControlName="poBox">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION_NUMBER' | translate }}</mat-label>
          <input id="locationNumber" matInput formControlName="locationNumber">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION.DEFAULT' | translate }}</mat-label>
          <mat-select formControlName="defaultLocation">
            @for (location of toolLocations; track location.id) {
              <mat-option [value]="location">{{ location.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <input hidden formControlName="parentId">

      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION.PARENT' | translate }}</mat-label>
          <input id="parentLocationTypeName" matInput formControlName="parentLocationPretty">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION.PARENT' | translate }} {{ 'RFID' | translate }}</mat-label>
          <input id="parentRfid" matInput formControlName="parentRfid">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3" *hasPermission="Permission.LOCATION_EDIT">
        <button type="submit" mat-raised-button color="primary" [disabled]="detailForm.pristine || !detailForm.valid" class="m-1">
          {{ 'SAVE.SAVE' | translate }}
        </button>
        <button type="button" mat-raised-button (click)="onCancel()" [disabled]="detailForm.pristine">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>

  </form>
</div>
