import { Component, input, output } from '@angular/core';
import { TableModule } from 'primeng/table';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { AssignmentDto } from '../../ model/qualification-assignment.model';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'wim-assignment',
  standalone: true,
  templateUrl: './assignment.component.html',
  styleUrl: './assignment.component.scss',
  imports: [
    MatCheckbox,
    FormsModule,
    TableModule,
    TranslateModule
  ]
})
export class AssignmentComponent {

  title = input<string>('Item');
  assignments = input<AssignmentDto[]>([]);
  updatedAssignment = output<AssignmentDto>();

  updateAssignment(assignment: AssignmentDto) {
    this.updatedAssignment.emit(assignment);
  }
}
