import { Component, ElementRef, input, Input, OnInit, output, viewChild } from '@angular/core';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, map, Observable, startWith, Subject, switchMap, throttleTime } from 'rxjs';
import { FetchSuggestionsFn } from './fetch-suggestions.function';
import { HasId } from '../../../ model/has-id.model';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { SelectedValidator } from '../../../validator/selected.validator';

@Component({
  selector: 'wim-suggestion-input',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    JsonPipe,
  ],
  templateUrl: './suggestion-input.component.html',
  styleUrl: './suggestion-input.component.scss'
})

// debounceTime: Emits the last value in the sequence after a specified pause between values.
//   It is useful for scenarios where you want to wait for a pause before taking action.
//
// throttleTime: Emits the first value immediately and then ignores subsequent values for a specified duration.
//   It is useful for rate-limiting the emission of values to avoid overwhelming systems.

export class SuggestionInputComponent<T extends HasId> implements OnInit {
  @Input() fetchSuggestions!: FetchSuggestionsFn<T>;
  @Input() displayFn!: (option: T) => string;
  label = input.required<string>();

  // only for focus
  input = viewChild.required<ElementRef>('suggestionInput');

  // need for throttling
  private internalEmitter = new Subject<any>();
  onOptionSelected = output<T>();

  searchControl = new FormControl<string | T>('', SelectedValidator);
  filteredOptions?: Observable<T[]>;

  constructor(private errorHandler: CantaaErrorHandlerService,
              private el: ElementRef) {
  }

  ngOnInit() {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      map(value => {
        return typeof value === 'string' ? value : value?.id.toString()
      }),
      switchMap(value => this.fetchSuggestionsInternal(value!))
    );

    this.internalEmitter.pipe(
      throttleTime(2000) // Throttle in milliseconds, ensures that the event is emitted at most once during period
    ).subscribe(value => {
      this.onOptionSelected.emit(value);
    });
  }

  async fetchSuggestionsInternal(query: string) {
    if (query.length < 3) {
      return [];
    }

    try {
      return await this.fetchSuggestions(query);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      return [];
    }
  }

  onOptionSelectedInternal($event: MatAutocompleteSelectedEvent) {
    // this.onOptionSelected.emit($event.option.value);
    this.internalEmitter.next($event.option.value);
  }

  clear(): void {
    this.searchControl.reset();
    this.searchControl.setValue('');
  }

  enable(): void {
    this.searchControl.enable();
  }

  disable(): void {
    this.searchControl.disable();
  }

  focus(): void {
    // FocusHelper.focusElementByClass(this.el, 'suggestionInput');
    // this.myInput.nativeElement.focus();
    setTimeout(() => this.input().nativeElement.focus(), 50);
  }

  setValue(value: any): void {
    this.searchControl.setValue(value);
  }

  onEnter() {
    const value = this.searchControl.value;
    if (value && typeof value !== 'string') {
      this.internalEmitter.next(value);
    }
  }
}
