<mat-tab-group>
  <mat-tab label="{{ 'DETAIL' | translate }}">
    <ng-template matTabContent>
      <wim-reservation-detail/>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'ITEMS' | translate }}">
    <ng-template matTabContent>
      <wim-reservation-detail-items/>
    </ng-template>
  </mat-tab>

</mat-tab-group>
