import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ManufacturerListItem } from '../ model/manufacturer.model';
import { ConsoleLoggerService } from './console-logger.service';
import { GridRequest } from '../ model/grid-request.model';


@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getManufacturers() {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/manufacturers`;
    const observable = this.http.get<CollectionDto<ManufacturerListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('manufacturers: ', r)))
      .pipe(map(c => c.items))

    return firstValueFrom(observable)
  }

  getPagedManufacturers(request: GridRequest) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/manufacturers/paged`;
    const observable = this.http.post<CollectionDto<ManufacturerListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('ManufacturerListItem: ', r)));
    return firstValueFrom(observable);
  }

  saveManufacturer(manufacturer: ManufacturerListItem) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/manufacturers`;
    const observable = this.http.post<ManufacturerListItem>(url, manufacturer, { params: params })
      .pipe(tap(r => this.log.debug('manufacturer save response: ', r)));

    return firstValueFrom(observable);
  }

  deleteManufacturer(id: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/manufacturers/${id}`;
    const observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('manufacturer deleted: ', r)))

    return firstValueFrom(observable)
  }

  updateManufacturerStatus(id: number, active: boolean) {
    let params = this.httpClientHelper.createHttpParams();
    params = params.append('active', active);

    let url = environment.apiUrl + `/manufacturers/${id}/status`;
    const observable = this.http.put<boolean>(url, null, { params: params })
      .pipe(tap(r => this.log.debug('CostCenters updated: ', r)))

    return firstValueFrom(observable)
  }
}
