import { Component, OnInit, signal } from '@angular/core';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { ToolLocationDocumentService } from "../../service/tool-location-document.service";
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatLine, MatOption } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProfileService } from '../../service/profile.service';
import { Profile } from '../../ model/profile.model';
import { CantaaErrorHandlerService } from '../../service/cantaa-error-handler.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wim-profile',
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatLine,
    MatOption,
    MatExpansionModule,
    TranslateModule
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  authoritiesPanelOpenState = signal(false);
  profile = signal<Profile | null>(null);

  constructor(private toolLocationDocumentService: ToolLocationDocumentService,
              private profileService: ProfileService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  async ngOnInit() {
    this.profile.set(await this.fetchProfile());
    this.toolLocationDocumentService.getDefaultPhotoScaled70x70_4Email2ImgEleId(this.profile()?.email, 'img_profile_loggedUser');
  }

  private async fetchProfile() {
    try {
      return await this.profileService.getProfile();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }
}
