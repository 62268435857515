<div class="card p-input-filled full-height">
  <p-table
    #dt
    dataKey="id"
    [value]="gridItems"
    [lazy]="true"
    selectionMode="single"
    [(selection)]="selectedItem"
    [resizableColumns]="true" columnResizeMode="expand"
    (onLazyLoad)="fetchDataViaTableComponent($event)"
    [paginator]="true"
    [first]="gridMeta.lazyLoadMeta?.first ?? 0"
    [rows]="gridMeta.lazyLoadMeta?.rows ?? 20"
    [rowsPerPageOptions]="[10, 20, 50]"
    [paginatorDropdownAppendTo]="'body'"
    [totalRecords]="totalRecords"
    [sortMode]="'multiple'"
    [multiSortMeta]="gridMeta.lazyLoadMeta?.multiSortMeta"
    [filters]="gridMeta.filters!"
    [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *showIfDebugMode id="id" style="min-width:8rem" pSortableColumn="id" pResizableColumn>
          Id
          <p-sortIcon field="id"></p-sortIcon>
          <p-columnFilter type="numeric" field="id" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="name" style="min-width:10rem" pSortableColumn="name" pResizableColumn>
          {{ 'CATEGORY.CATEGORY' | translate }}
          <p-sortIcon field="name"></p-sortIcon>
          <p-columnFilter type="text" field="name" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="levelType" style="min-width:10rem" pSortableColumn="levelType" pResizableColumn>
          {{ 'LEVEL_TYPE.LEVEL_TYPE' | translate }}
          <p-sortIcon field="levelType"></p-sortIcon>
          <p-columnFilter type="text" field="levelType" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-toolCategory>
      <tr [pSelectableRow]="toolCategory" (dblclick)="onRowDblClick($event, toolCategory.treeNode)">
        <td *showIfDebugMode>{{ toolCategory.id }}</td>
        <td>{{ toolCategory.name }}</td>
        <td>{{ toolCategory.levelType }}</td>
      </tr>
    </ng-template>
    <ng-template id="buttonCreate" pTemplate="paginatorleft">
      <button mat-flat-button color="primary" [disabled]="buttonCreateDisabled" (click)="createNew()"
              *hasPermission="Permission.CATEGORY_CREATE">
        <mat-icon>add</mat-icon>
        {{ 'CREATE' | translate }}
      </button>
      <button mat-flat-button [disabled]="selectedItem === null" (click)="delete()" class="mx-1"
              *hasPermission="Permission.CATEGORY_DELETE">
        <mat-icon>clear</mat-icon>
        {{ 'DELETE.DELETE' | translate }}
      </button>
    </ng-template>
  </p-table>
</div>
