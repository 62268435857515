<div class="container ">
  @if (detailForm.controls.id.value === null) {
    <h2 class="mt-3 text-center">{{ 'INSPECTION.NOT_FOUND_PENDING' | translate }}</h2>
  } @else {
    <form [formGroup]="detailForm">
      <div class="row">

        <div class="col-3">
          <mat-form-field class="w-100">
            <mat-label>{{ 'ID' | translate }}</mat-label>
            <input id="id" matInput formControlName="id">
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>{{ 'NAME' | translate }}</mat-label>
            <input id="name" matInput formControlName="name">
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col-3">
          <mat-form-field class="w-100">
            <mat-label>{{ 'DATE.DUE_DATE' | translate }}</mat-label>
            <input id="dueDate" matInput formControlName="dueDate">
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="w-100">
            <mat-label>{{ 'INSPECTION.FIRST_INSPECTION_AFTER_X_MONTHS' | translate }}</mat-label>
            <input id="firstInspectionMonth" matInput formControlName="firstInspectionMonth">
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="w-100">
            <mat-label>{{ 'INSPECTION.NEXT_INSPECTION_AFTER_X_MONTHS' | translate }}</mat-label>
            <input id="nextInspectionMonth" matInput formControlName="nextInspectionMonth">
          </mat-form-field>
        </div>

      </div>

    </form>
  }

</div>
