// https://primeng.org/icons
export const WimIcons = {
  TOOL_ICON: 'pi pi-wrench',
  TRANSACTION_ICON: 'pi pi-arrow-right-arrow-left',
  TOOL_LOCATION_ICON: 'pi pi-building',
  TOOL_CATEGORY_ICON: 'pi pi-share-alt',
  RELATION_ICON: 'pi pi-arrow-right',
  INSPECTION_ICON: 'pi pi-exclamation-triangle',
  DOCUMENT_ICON: 'pi pi-file',
  RESERVATION_ICON: 'pi pi-file-edit',
};
