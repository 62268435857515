import {AfterViewInit, Component, ElementRef, inject, viewChild} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Table, TableLazyLoadEvent, TableModule} from 'primeng/table';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {GridHelperService} from '../../../service/helper/grid-helper.service';
import {FocusHelper} from '../../../service/helper/focus-helper';
import {GridRequest} from '../../../ model/grid-request.model';
import {ToolLocationTypeListItem} from '../../../ model/tool-location-type-list-item.model';
import {LocationTypeService} from '../../../service/location-type.service';
import {MatCheckbox} from '@angular/material/checkbox';
import {GridMetaService} from '../../../service/grid-meta.service';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {CantaaGridMeta} from "../../../ model/GridMeta";

@Component({
  selector: 'wim-tool-location-type-grid',
  templateUrl: './tool-location-type-grid.component.html',
  styleUrl: './tool-location-type-grid.component.scss',
  standalone: true,
  imports: [
    TableModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatIconModule,
    MatIconButton,
    MatCheckbox,
    HasPermissionDirective
  ]
})
export class ToolLocationTypeGridComponent implements AfterViewInit {
  dataTable = viewChild.required<Table>('dt');
  protected readonly Permission = Permission;
  readonly gridName = 'TOOL_LOCATION_TYPE';

  private gridMetaService = inject(GridMetaService);
  private fb = inject(FormBuilder);
  private messageService = inject(CantaaMessageService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private el = inject(ElementRef);
  private gridHelperService = inject(GridHelperService);
  private locationTypeService = inject(LocationTypeService);

  locationTypes: ToolLocationTypeListItem[] = [];
  public totalRecords: number = 0;
  editMode = false;

  gridMeta!: CantaaGridMeta;

  createNewForm = this.fb.group({
    code: ['', Validators.required],
    name: ['', Validators.required],
    mobileLocation: [true],
  });

  clonedItems: { [s: number]: ToolLocationTypeListItem } = {};

  constructor() {
    this.initGridMeta();
  }

  private initGridMeta() {
    const defaultSortMeta = [{field: 'name', order: 1}];
    this.gridMeta = this.gridMetaService.getGridMeta(this.gridName, defaultSortMeta);
  }

  async ngAfterViewInit() {
    await this.reloadData();
  }

  onRowEditInit(locationType: ToolLocationTypeListItem) {
    this.clonedItems[locationType.id] = {...locationType};
    this.editMode = true;
  }

  async onRowEditSave(locationType: ToolLocationTypeListItem) {
    await this.save(locationType);
    this.editMode = false;
    await this.reloadData();
  }

  private async save(locationType: ToolLocationTypeListItem) {
    try {
      await this.locationTypeService.saveLocationType(locationType);
      this.messageService.success('SAVE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, 'SAVE.FAILED');
    }
  }

  onRowEditCancel(locationType: ToolLocationTypeListItem, index: number) {
    this.locationTypes[index] = this.clonedItems[locationType.id];
    delete this.clonedItems[locationType.id];
    this.editMode = false;
  }

  async onCreateNew() {
    const locationType = {
      code: this.createNewForm.value.code,
      name: this.createNewForm.value.name,
      mobileLocation: this.createNewForm.value.mobileLocation
    } as ToolLocationTypeListItem;

    await this.save(locationType);
    await this.reloadData();
    this.createNewForm.reset();
    FocusHelper.focusFormField(this.el, 'code');
  }

  onCancel() {
    this.createNewForm.reset();
  }

  async onUpdateStatus(locationType: ToolLocationTypeListItem) {
    try {
      await this.locationTypeService.updateLocationTypeStatus(locationType.id, !locationType.active);
      this.messageService.success('UPDATE.SUCCESS');
      await this.reloadData();
    } catch (e) {
      this.errorHandler.handleError(e, 'UPDATE.FAILED');
    }
  }

  async fetchDataViaTableComponent(lazyLoadMeta: TableLazyLoadEvent) {
    this.gridMetaService.setGridMeta(this.gridName, {lazyLoadMeta});
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMeta);
    await this.fetchDAta(gridRequest);
  }

  async reloadData() {
    let lazyLoadMetadata = this.dataTable().createLazyLoadMetadata();
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMetadata);
    await this.fetchDAta(gridRequest);
  }

  async fetchDAta(gridRequest: GridRequest) {
    try {
      const collection = await this.locationTypeService.getPagedToolLocationTypes(gridRequest);
      this.locationTypes = collection.items;
      this.totalRecords = collection.totalNumberOfItems;
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
    }
  }

  getTdClass(item: any) {
    return item.active ? "" : 'strikethrough';
  }

}
