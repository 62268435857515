import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReservationService} from '../../../service/reservation.service';
import {JsonPipe} from '@angular/common';
import {ReservationItemModel} from '../../../ model/reservation/reservation-item.model';
import {TableModule} from 'primeng/table';
import {TranslateModule} from '@ngx-translate/core';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-reservation-detail-items',
  standalone: true,
  imports: [
    JsonPipe,
    TableModule,
    TranslateModule,
    ShowIfDebugMode
  ],
  templateUrl: './reservation-detail-items.component.html',
  styleUrl: './reservation-detail-items.component.scss'
})
export class ReservationDetailItemsComponent implements OnInit {
  reservationItems: ReservationItemModel[] = [];

  constructor(private route: ActivatedRoute,
              private reservationService: ReservationService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async params => {
      const reservationId = Number(params.get('id'));
      this.reservationItems = await this.fetchReservationItems(reservationId);
    });
  }

  private async fetchReservationItems(reservationId: number) {
    try {
      return await this.reservationService.getReservationItems(reservationId);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

}
