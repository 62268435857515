<mat-tab-group>
  <mat-tab label="{{ 'DETAIL' | translate }}">
    <ng-template matTabContent>
      <wim-tool-category-detail/>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'QUALIFICATIONS' | translate }}">
    <ng-template matTabContent>
      <wim-qualification-assignment-category/>
    </ng-template>
  </mat-tab>
    <mat-tab label="{{ 'INSPECTIONS.INSPECTIONS' | translate }}">
    <ng-template matTabContent>
      <wim-inspection-assignment-category/>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'DISPOSITIONS' | translate }}">
    <ng-template matTabContent>
      <wim-tool-category-disposition-grid/>
    </ng-template>
  </mat-tab>
</mat-tab-group>
