export const NodeType = {
  TOOL: 'tool',
  TOOL_RELATION: 'toolRelation',
  TRANSACTION: 'transaction',
  TRANSACTION_RELATION: 'transactionRelation',
  TOOL_LOCATION: 'toolLocation',
  TOOL_LOCATION_RELATION: 'toolLocationRelation',
  TOOL_CATEGORY: 'toolCategory',
  TOOL_SUB_CATEGORY_RELATION: 'toolSubCategoryRelation',
  TOOL_PARENT_CATEGORY_RELATION: 'toolParentCategoryRelation',
  INSPECTION_TYPE: 'inspectionType',
  INSPECTION_TYPE_RELATION: 'inspectionTypeRelation',
  DOCUMENT: 'document',
  DOCUMENT_RELATION: 'documentRelation',
  RESERVATION: 'reservation',
  RESERVATION_RELATION: 'reservationRelation',
};
