import {AfterViewInit, Component, ElementRef, inject, viewChild} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {ManufacturerService} from '../../../service/manufacturer.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {ManufacturerListItem} from '../../../ model/manufacturer.model';
import {Table, TableLazyLoadEvent, TableModule} from 'primeng/table';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {FocusHelper} from '../../../service/helper/focus-helper';
import {GridHelperService} from '../../../service/helper/grid-helper.service';
import {GridRequest} from '../../../ model/grid-request.model';
import {GridMetaService} from '../../../service/grid-meta.service';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {CantaaGridMeta} from "../../../ model/GridMeta";

@Component({
  selector: 'wim-manufacturer-grid',
  standalone: true,
  imports: [
    TableModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatIconModule,
    MatIconButton,
    HasPermissionDirective
  ],
  templateUrl: './manufacturer-grid.component.html',
  styleUrl: './manufacturer-grid.component.scss'
})
export class ManufacturerGridComponent implements AfterViewInit {
  dataTable = viewChild.required<Table>('dt');
  readonly gridName = 'MANUFACTURER';
  protected readonly Permission = Permission;

  private gridMetaService = inject(GridMetaService);
  private fb = inject(FormBuilder);
  private messageService = inject(CantaaMessageService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private el = inject(ElementRef);
  private gridHelperService = inject(GridHelperService);
  private manufacturerService = inject(ManufacturerService);

  manufacturers: ManufacturerListItem[] = [];
  public totalRecords: number = 0;
  editMode = false;

  gridMeta!: CantaaGridMeta;

  createNewForm = this.fb.group({
    // ce3e0408019016e0
    code: ['', Validators.required],
    name: ['', Validators.required],
  });

  clonedManufacturers: { [s: number]: ManufacturerListItem } = {};

  constructor() {
    this.initGridMeta();
  }

  private initGridMeta() {
    const defaultSortMeta = [{ field: 'name', order: 1 }];
    this.gridMeta = this.gridMetaService.getGridMeta(this.gridName, defaultSortMeta);
  }

  async ngAfterViewInit() {
    await this.reloadData();
  }

  onRowEditInit(manufacturer: ManufacturerListItem) {
    this.clonedManufacturers[manufacturer.id] = { ...manufacturer };
    this.editMode = true;
  }

  async onRowEditSave(manufacturer: ManufacturerListItem) {
    await this.save(manufacturer);
    this.editMode = false;
    await this.reloadData();
  }

  private async save(manufacturer: ManufacturerListItem) {
    try {
      await this.manufacturerService.saveManufacturer(manufacturer);
      this.messageService.success('SAVE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, 'SAVE.FAILED');
    }
  }

  onRowEditCancel(manufacturer: ManufacturerListItem, index: number) {
    this.manufacturers[index] = this.clonedManufacturers[manufacturer.id];
    delete this.clonedManufacturers[manufacturer.id];
    this.editMode = false;
  }

  async onCreateNew() {
    const manufacturer = {
      code: this.createNewForm.value.code,
      name: this.createNewForm.value.name
    } as ManufacturerListItem;

    await this.save(manufacturer);
    await this.reloadData();
    this.createNewForm.reset();
    FocusHelper.focusFormField(this.el, 'code');
  }

  onCancel() {
    this.createNewForm.reset();
  }

  async onUpdateStatus(manufacturer: ManufacturerListItem) {
    try {
      await this.manufacturerService.updateManufacturerStatus(manufacturer.id, !manufacturer.active);
      this.messageService.success('UPDATE.SUCCESS');
      await this.reloadData();
    } catch (e) {
      this.errorHandler.handleError(e, 'UPDATE.FAILED');
    }
  }

  async fetchDataViaTableComponent(lazyLoadMeta: TableLazyLoadEvent) {
    this.gridMetaService.setGridMeta(this.gridName, {lazyLoadMeta});
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMeta);
    await this.fetchDAta(gridRequest);
  }

  async reloadData() {
    let lazyLoadMetadata = this.dataTable().createLazyLoadMetadata();
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMetadata);
    await this.fetchDAta(gridRequest);
  }

  async fetchDAta(gridRequest: GridRequest) {
    try {
      const collection = await this.manufacturerService.getPagedManufacturers(gridRequest);
      this.manufacturers = collection.items;
      this.totalRecords = collection.totalNumberOfItems;
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
    }
  }

  getTdClass(item: any) {
    return item.active ? "" : 'strikethrough';
  }
}
