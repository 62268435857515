import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from "./console-logger.service";
import { SystemInfo } from '../ model/system-info.model';

@Injectable({
  providedIn: 'root'
})
export class SystemInfoService {

  constructor(private http: HttpClient, private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getSystemInfo() {
    const url = environment.apiUrl + `/system-info`;
    let params = this.httpClientHelper.createHttpParams();

    let observable = this.http.get<CollectionDto<SystemInfo>>(url, { params: params })
      .pipe(tap(r => this.log.debug('System info: ', r)))
      .pipe(map(c => c.items))
    return firstValueFrom(observable);
  }

}
