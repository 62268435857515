import { Component, ElementRef, OnInit, viewChild } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { Table, TableModule } from 'primeng/table';
import { TestDataComponent } from '../../../test-data/test-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToolDetailItem } from '../../../../ model/tool-detail.model';
import { BookToolDto, BulkBookItemDto, ToolBookingDto, ToolBookingValidateItemDto } from '../../../../ model/booking.models';
import { DropdownItemDto } from '../../../../ model/dropdown-item.model';
import { ToolLocationDetail } from '../../../../ model/tool-location-detail.model';
import { ToolService } from '../../../../service/tool.service';
import { InventoryService } from '../../../../service/inventory.service';
import { ToolLocationService } from '../../../../service/tool-location.service';
import { TransactionService } from '../../../../service/transaction.service';
import { CantaaMessageService } from '../../../../service/cantaa-message.service';
import { ToolLocationDocumentService } from '../../../../service/tool-location-document.service';
import { ToolDocumentService } from '../../../../service/tool-document.service';
import { DocumentService } from '../../../../service/document.service';
import { ReservationService } from '../../../../service/reservation.service';
import { ToolConstants } from '../../../../constants/tool-constants';
import { UuidHelperService } from '../../../../service/helper/uuid-helper.service';
import { PTableHelper } from '../../../../service/helper/p-table-helper';
import { LocationType } from '../../../../constants/location-type';
import { ReservationDropdownItemDto } from '../../../../ model/reservation/reservation-dropdown-item.model';
import { FocusHelper } from '../../../../service/helper/focus-helper';
import { MatListItemIcon } from '@angular/material/list';
import { DatePipe } from '@angular/common';
import { SuggestionInputComponent } from '../../../inputs/suggestion-input/suggestion-input.component';
import { ToolListItem } from '../../../../ model/tool-list-item.model';
import { ToolLocationListItem } from '../../../../ model/tool-location-list-item.model';
import { DisplayHelper } from '../../../../service/helper/DisplayHelper';

@Component({
  selector: 'wim-bulk-booking-handout',
  standalone: true,
  imports: [
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatHint,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatRadioButton,
    MatRadioGroup,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    TestDataComponent,
    TranslateModule,
    FormsModule,
    MatListItemIcon,
    DatePipe,
    SuggestionInputComponent
  ],
  templateUrl: './bulk-booking-handout.component.html',
  styleUrl: './bulk-booking-handout.component.scss'
})
export class BulkBookingHandoutComponent implements OnInit {
  protected readonly DisplayHelper = DisplayHelper;
  private readonly HANDOVER = 'Handover';
  selectedTool: ToolDetailItem | null = null;

  dataTable = viewChild.required<Table>('dt');
  toolSuggestionInput = viewChild.required<SuggestionInputComponent<ToolListItem>>('toolSuggestionInput');
  toLocationSuggestionInput = viewChild.required<SuggestionInputComponent<ToolLocationListItem>>('toLocationSuggestionInput');

  bookItems: BulkBookItemDto[] = [];

  myLocationForm = this.fb.group({
    myLocation: this.fb.control<DropdownItemDto | null>(null)
  });

  amountForm = this.fb.group({
    toolAmount: [{ value: 1, disabled: true }, [Validators.required, Validators.min(0)]]
  });

  reservationForm = this.fb.group({
    reservation: this.fb.control<ReservationDropdownItemDto | null>({ value: null, disabled: true }),
    returnDate: this.fb.control<Date | null>({ value: null, disabled: true }),
  });

  toolLocations: DropdownItemDto[] = [];
  reservations: ReservationDropdownItemDto[] = [];
  selectedToLocation: ToolLocationDetail | null = null;

  constructor(private fb: FormBuilder,
              private toolService: ToolService,
              private inventoryService: InventoryService,
              private toolLocationService: ToolLocationService,
              private transactionService: TransactionService,
              private messageService: CantaaMessageService,
              private el: ElementRef,
              private toolLocationDocumentService: ToolLocationDocumentService,
              protected toolDocumentService: ToolDocumentService,
              private documentService: DocumentService,
              private reservationService: ReservationService) {
  }

  async ngOnInit() {
    this.toolSuggestionInput().disable();
    this.toolLocations = await this.toolLocationService.getToolLocationsForBooking();
    const preselected = await this.preselectDefaultLocation();

    if (!preselected) {
      this.focusToMyLocation();
    }
  }

  private async preselectDefaultLocation(): Promise<boolean> {
    const defaultLocationId = await this.toolLocationService.getDefaultLocationId();

    if (defaultLocationId == null) {
      return false;
    }
    const myLocation = this.toolLocations.find(i => i.id === defaultLocationId);

    if (myLocation) {
      this.myLocationForm.controls.myLocation.setValue(myLocation);
      this.onMyLocationChange();
      return true;
    }
    return false;
  }

  async onToLocationSelected(location: ToolLocationListItem) {
    this.selectedToLocation = await this.toolLocationService.getToolLocationDetailById(location.id);
    this.messageService.success('LOCATION.FOUND');

    if (this.selectedToLocation.id) {
      this.toolLocationDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(this.selectedToLocation.id, 'fromToLocationForm_icon');
    }

    this.toolSuggestionInput().enable();
    this.toolSuggestionInput().focus();

    this.myLocationForm.controls.myLocation.disable();
    this.toLocationSuggestionInput().disable();
  }

  onMyLocationChange() {
    const fromLocationId = this.myLocationForm.value.myLocation?.id;
    if (fromLocationId) {
      this.toolLocationDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(fromLocationId, 'myLocationForm_icon');
    }
    this.toLocationSuggestionInput().clear();
    this.selectedToLocation = null;
    this.toLocationSuggestionInput().enable()
    this.toLocationSuggestionInput().focus();
  }

  async onToolSelected(toolListItem: ToolListItem) {
    this.selectedTool = await this.toolService.getToolDetail(toolListItem.id);
    if (this.selectedTool) {
      this.toolDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(this.selectedTool.id, 'tool_icon');
    }
    if (this.selectedTool.toolProcessingType === ToolConstants.PROCESSING_TYPE.TOOL) {
      const locationId = this.myLocationForm.value.myLocation?.id;
      this.reservations = await this.reservationService.getReservationsForBooking(this.selectedTool.id, locationId!, 1);

      this.reservationForm.controls.returnDate.enable();

      if (this.reservations.length == 0) {
        this.focusToReturnDate();
        return;
      }
      this.reservationForm.controls.reservation.enable();
      this.focusToReservation();

      return;
    }

    this.amountForm.controls.toolAmount.enable()

    const amount = await this.getToolAmount();
    this.amountForm.controls.toolAmount.setValue(amount);

    this.focusToAmountInput();
  }

  private async getToolAmount() {
    const toolLocationId = this.myLocationForm.controls.myLocation.value!.id;
    const toolLocation = await this.toolLocationService.getToolLocationDetailById(toolLocationId)

    const locationTypeCode = toolLocation?.locationTypeCode;

    if (LocationType.CONTAINER != locationTypeCode && LocationType.EMPLOYEE != locationTypeCode) {
      return null;
    }

    const amount = await this.inventoryService.getInventoryAmountByLocation(toolLocation?.id!, this.selectedTool?.id!);

    return amount ?? null;
  }

  async onAmountSubmit() {
    const locationId = this.myLocationForm.value.myLocation?.id;
    const amount = this.amountForm.value.toolAmount;

    this.reservations = await this.reservationService.getReservationsForBooking(this.selectedTool!.id, locationId!, amount!);

    if (this.reservations.length == 0) {
      await this.processItem();
      return;
    }

    this.reservationForm.controls.returnDate.enable();
    this.focusToReturnDate();
  }

  private async processItem() {
    const fromLocationId = this.myLocationForm.value.myLocation?.id;
    const toLocationId = this.selectedToLocation?.id;

    const itemToValidate = {
      fromLocationId: fromLocationId,
      toLocationId: toLocationId,
      toolId: this.selectedTool?.id,
      amount: this.amountForm.controls.toolAmount.value,
    } as ToolBookingValidateItemDto;

    const validationMessages = await this.transactionService.validateToolBooking(itemToValidate, this.HANDOVER);

    const bookItem = {
      id: UuidHelperService.generateUuid(),
      tool: this.selectedTool,
      amount: this.amountForm.controls.toolAmount.value,
      fromLocationId: fromLocationId,
      toLocationId: toLocationId,
      messages: validationMessages,
      active: true,
      reservationId: this.reservationForm.value.reservation?.id,
      reservationItemId: this.reservationForm.value.reservation?.reservationItemId,
      returnDate: this.reservationForm.value.returnDate
    } as BulkBookItemDto;

    this.bookItems = this.bookItems.concat(bookItem);
    PTableHelper.navigateToBottomOfTable(this.dataTable(), this.bookItems.length);

    this.setUiAfterProcessedItem(bookItem);
    this.toolSuggestionInput().focus();
  }

  private setUiAfterProcessedItem(bookItem: BulkBookItemDto) {
    this.selectedTool = null;

    this.toolSuggestionInput().clear();

    this.amountForm.controls.toolAmount.setValue(1);
    this.amountForm.controls.toolAmount.disable();

    this.reservations = [];
    this.reservationForm.controls.reservation.disable();

    this.reservationForm.controls.returnDate.setValue(null);
    this.reservationForm.controls.returnDate.disable();

    //this.toolDocumentService.getDefaultPhotoScaled70x70AndRender();

    const imEleId = 'img_toolid_' + bookItem.id;
    this.toolDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(bookItem.tool.id, imEleId);

    this.documentService.disableImgElement('tool_icon');
  }

  onReservationChange(event: MatSelectChange) {
    if (this.selectedTool?.toolProcessingType !== ToolConstants.PROCESSING_TYPE.TOOL) {
      return;
    }

    this.reservationForm.controls.returnDate.enable();

    let reservation = event.value as ReservationDropdownItemDto;
    this.reservationForm.patchValue({
      returnDate: reservation?.dateUntil
    })
    this.focusToReturnDate();
  }

  onUpdateActiveState(item: BulkBookItemDto) {
    item.active = !item.active;
  }

  getTdClass(item: BulkBookItemDto) {
    return item.active ? "" : 'strikethrough';
  }

  getActiveItems() {
    return this.bookItems.filter(i => i.active);
  }

  async onBulkBooking() {
    let tools = this.getActiveItems()
      .map(i => ({
        toolId: i.tool.id,
        amount: i.amount,
        reservationItemId: i.reservationItemId,
        returnDate: i.returnDate
      } as ToolBookingDto));

    const booking = {
      transactionTypeCode: this.HANDOVER,
      fromLocationId: this.myLocationForm.value.myLocation?.id,
      toLocationId: this.selectedToLocation?.id,
      tools: tools
    } as BookToolDto

    await this.transactionService.makeBooking(booking)
    this.messageService.success('BOOKING.SUCCESSFUL');
    this.setUiAfterBook();
  }

  private setUiAfterBook() {
    this.bookItems = [];

    this.myLocationForm.controls.myLocation.enable();

    this.selectedToLocation = null;
    this.toLocationSuggestionInput().clear();
    this.toLocationSuggestionInput().enable()
    this.toLocationSuggestionInput().focus();

    this.selectedTool = null;

    this.toolSuggestionInput().clear();
    this.toolSuggestionInput().disable();

    this.amountForm.controls.toolAmount.setValue(1);
    this.amountForm.controls.toolAmount.disable();

    this.reservations = [];
    this.reservationForm.controls.reservation.disable();

    this.reservationForm.controls.returnDate.setValue(null);
    this.reservationForm.controls.returnDate.disable();
  }

  onReturnDateChanged() {
    this.focusToReturnDate();
  }

  async onReturnDateEnter() {
    await this.processItem();
  }

  onCancel() {
    this.setUiAfterBook();
  }

  focusToMyLocation() {
    FocusHelper.focusFormField(this.el, 'myLocation');
  }

  focusToAmountInput() {
    FocusHelper.focusFormField(this.el, 'toolAmount');
  }

  focusToReservation() {
    FocusHelper.focusFormField(this.el, 'reservation');
  }

  focusToReturnDate() {
    FocusHelper.focusFormFieldLongTimeout(this.el, 'returnDate');
  }

  toolFetchSuggestions = async (query: string): Promise<ToolListItem[]> => {
    return await this.toolService.searchTools(query);
  };

  toLocationFetchSuggestions = async (query: string): Promise<ToolLocationListItem[]> => {
    return await this.toolLocationService.searchLocations(query);
  };

}

