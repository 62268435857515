<div class="container" style="min-height: 80vh">
  <mat-card class="user-info-card">
    <mat-card-header class="p-4">
      <img mat-card-avatar
           class="user-image img-defaultphoto-icon iconw70xh70"
           id="img_profile_loggedUser"
           src=""
           alt="Profile"
      >
      <mat-card-title>{{ profile()?.username }}</mat-card-title>
      <mat-card-subtitle>{{ profile()?.name }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p>{{ 'EMAIL.EMAIL' | translate }}: {{ profile()?.email }}</p>
      <p>{{ 'TENANT.TENANT' | translate }}: {{ profile()?.tenant }}</p>
      <p>{{ 'LOCATION.RELATED_EMPLOYEE' | translate }}: {{ profile()?.relatedEmployee }}</p>
      <p>{{ 'LOCATION.DEFAULT' | translate }}: {{ profile()?.defaultLocationName }}</p>

      <mat-expansion-panel (opened)="authoritiesPanelOpenState.set(true)" (closed)="authoritiesPanelOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title>Authorities</mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
          @for (item of profile()?.authorities; track item) {
            <div class="col-4">
              <mat-list-item>
                <h4 mat-line>{{ item }}</h4>
              </mat-list-item>
            </div>
          }
        </div>
      </mat-expansion-panel>
    </mat-card-content>
  </mat-card>
</div>


