import {Component, inject} from '@angular/core';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {SharedModule} from 'primeng/api';
import {TableLazyLoadEvent, TableModule} from 'primeng/table';
import {BaseNodeGridComponent} from '../../base-components/base-node-grid.component';
import {TreeHelperService} from '../../../service/helper/tree-helper.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {NodeType} from '../../../constants/node-type';
import {GridRequest} from '../../../ model/grid-request.model';
import {WimIcons} from '../../../constants/wim-icons';
import {RouteType} from '../../../constants/route-type';
import {Permission} from '../../../constants/Permission';
import {InspectableToolService} from '../../../service/inspectable-tool.service';
import {InspectableToolListItem} from '../../../ model/inspectable-tool-list-item.model';
import {UuidHelperService} from '../../../service/helper/uuid-helper.service';
import {NgClass} from '@angular/common';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-inspectable-tool-grid',
  standalone: true,
  imports: [
    HasPermissionDirective,
    MatButton,
    MatIcon,
    SharedModule,
    TableModule,
    NgClass,
    ShowIfDebugMode
  ],
  templateUrl: './inspectable-tool-grid.component.html',
  styleUrl: './inspectable-tool-grid.component.scss'
})
export class InspectableToolGridComponent extends BaseNodeGridComponent<InspectableToolListItem> {
  protected override nodeType = NodeType.TOOL_RELATION;
  protected readonly Permission = Permission;

  private inspectableToolService = inject(InspectableToolService);
  private treeHelperService = inject(TreeHelperService);
  private errorHandler = inject(CantaaErrorHandlerService);


  override getDefaultSort() {
    return [{field: 'toolInspectionDueDateFormatted', order: 1}];
  }

  override async fetchData(selectedNode: WimTreeNode, lazyLoadMetadata: TableLazyLoadEvent) {
    await super.fetchData(selectedNode, lazyLoadMetadata);

    this.gridItems = this.gridItems
      .map(i => ({
        ...i,
        dataKey: UuidHelperService.generateUuid()
      }));
  }

  override async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode) {
    try {
      return await this.inspectableToolService.getInspectableTools(gridRequest, selectedNode);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  override mapTreeItems(selectedNode: WimTreeNode, items: InspectableToolListItem[]): WimTreeNode[] {
    return items
      .map<WimTreeNode>(t => {

        let treeNode = {
          id: t.id,
          key: UuidHelperService.generateUuid(),
          label: `${t.category} ${t.productName}`,
          data: t.productName,
          icon: WimIcons.TOOL_ICON,
          nodeType: NodeType.TOOL,
          route: RouteType.TOOL_INSPECTABLE,
          children: [] as WimTreeNode[],
          leaf: false,
          parent: selectedNode,
          highlighterClass: this.getRowClass(t)
        };

        t.treeNode = treeNode;

        treeNode.children = [this.treeHelperService.createToolLocationRelationsTreeItem(treeNode),
          this.treeHelperService.createTransactionRelationsTreeItem(treeNode)];

        return treeNode;
      });
  }

  async doInspection() {
    const id = this.selectedItem?.id;
    await this.router.navigate(['booking/inspection'], { state: { id: id } });
  }

  getRowClass(tool: InspectableToolListItem) {
    return 'due-date-status_' + tool.toolInspectionDueDateStatus;
  }

  isInspectionButtonDisabled() {
    return !this.selectedItem || !this.selectedItem.rfid || !this.selectedItem.inspectionName;
  }
}
