import {Injectable} from '@angular/core';
import {AppStateService} from './app-state.service';
import {SortMeta} from 'primeng/api/sortmeta';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {CantaaGridMeta} from "../ model/GridMeta";


@Injectable({
  providedIn: 'root'
})
export class GridMetaService {

  defaultFilterFallback = {
    "id": [
      {
        "value": null,
        "matchMode": "equals",
        "operator": "and"
      }
    ]
  } as {
    [s: string]: FilterMetadata[];
  };

  constructor(private appStateService: AppStateService) {
  }

  getGridMeta(gridName: string, defaultSortMeta?: SortMeta[], defaultFilter?: { [s: string]: FilterMetadata[] }) {
    let gridMeta = this.appStateService.getGridMeta(gridName);

    // if no previous sort, default set sorting
    if (!gridMeta.lazyLoadMeta?.multiSortMeta) {
      gridMeta = {
        ...gridMeta,
        lazyLoadMeta: {
          ...gridMeta.lazyLoadMeta,
          multiSortMeta: defaultSortMeta,
        }
      }
    }

    if (gridMeta.lazyLoadMeta?.filters) {
      gridMeta.filters = gridMeta.lazyLoadMeta.filters as { [s: string]: FilterMetadata[] };
    } else {
      const filter = defaultFilter || this.defaultFilterFallback;
      gridMeta.filters = {...filter};
    }
    return gridMeta;
  }

  setGridMeta(gridName: string, lazyLoadMetadata: CantaaGridMeta) {
    this.appStateService.setGridMeta(gridName, lazyLoadMetadata);
  }

}
