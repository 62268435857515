<div class="container ">

  <form [formGroup]="reservationForm">

    <div class="row">
      <div *showIfDebugMode class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'ID' | translate }}</mat-label>
          <input id="id" type="number" matInput formControlName="id">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION.PICK_UP' | translate }}</mat-label>
          <input id="pickUpLocation" type="text" matInput formControlName="pickUpLocation">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'DATE.FROM' | translate }}</mat-label>
          <input id="fromDate" type="text" matInput formControlName="fromDate">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'DATE.TO' | translate }}</mat-label>
          <input id="toDate" type="text" matInput formControlName="toDate">
        </mat-form-field>
      </div>

    </div>

    <div class="row">
      <div class="col-4">
        <mat-form-field class="w-100">
            <mat-label>{{ 'EMPLOYEE.EMPLOYEE' | translate }}</mat-label>
          <input id="employee" type="text" matInput formControlName="employee">
        </mat-form-field>
      </div>
    </div>

  </form>

</div>
