import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridRequest } from '../ model/grid-request.model';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { WimTreeNode } from '../ model/wim-tree.node';
import { NodeType } from '../constants/node-type';
import { ToolListItem } from '../ model/tool-list-item.model';
import { ToolDetailItem } from '../ model/tool-detail.model';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { ValidatorResponse } from '../ model/ValidatorResponse.model';
import {ToolTransactionListItem} from '../ model/tool-transaction-list-item.model';


@Injectable({
  providedIn: 'root'
})
export class ToolService {

  constructor(private http: HttpClient, private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getTools(request: GridRequest, selectedNode: WimTreeNode) {
    const params = this.httpClientHelper.createHttpParams();
    let url = this.createUrl(selectedNode);
    const observable = this.http.post<CollectionDto<ToolListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('Tools: ', r)));
    return firstValueFrom(observable);
  }

  getToolsByTransaction(request: GridRequest, transactionId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/transactions/tools/${transactionId}`;
    const observable = this.http.get<CollectionDto<ToolTransactionListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('Tools: ', r)))
      .pipe(map(c => c.items));
    return firstValueFrom(observable);
  }

  saveToolDetail(detail: ToolDetailItem) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tools/save`;
    let observable = this.http.post<ToolDetailItem>(url, detail, { params: params })
    return firstValueFrom(observable)
  }

  saveToolDetailTest(detail: ToolDetailItem) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tools/save/test`;
    let observable = this.http.post<ToolDetailItem>(url, detail, { params: params })
      .pipe(tap(r => this.log.debug('saved tool: ', r)))

    return firstValueFrom(observable)
  }

  private createUrl(selectedNode: WimTreeNode) {
    return `${environment.apiUrl}${this.getUrl(selectedNode)}`;
  }

  private getUrl(selectedNode: WimTreeNode): string {
    const parent = (selectedNode.parent as WimTreeNode);
    const parentNodeType = parent?.nodeType;

    switch (parentNodeType) {
      case undefined:
        return `/tools`;
      case NodeType.TRANSACTION:
        return `/tools/transaction/${parent.id}`;
      case NodeType.TOOL_LOCATION:
        return `/tools/location/${parent.id}`;
      case NodeType.TOOL_CATEGORY:
        return `/tools/category/${parent.id}`;
      case NodeType.DOCUMENT:
        return `/tools/document/${parent.id}`;
      default:
        throw new Error(`not Implemented for ${parentNodeType}`);
    }

  }

  getToolDetail(toolId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tools/${toolId}`;
    // const observable = this.http.get<ToolDetailItem>(url, { params: params })
    //   .pipe(tap(r => this.log.debug('tool detail: ', r)))
    // return firstValueFrom(observable);

    return firstValueFrom(this.http.get<ToolDetailItem>(url, { params: params })
      .pipe(tap(r => this.log.debug('tool detail: ', r))));

  }

  validateToolRfid(rfid: string) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tools/validate/rfid/${rfid}`;
    return this.http.get<ValidatorResponse>(url, { params: params })
      .pipe(tap(r => this.log.debug('response: ', r)))
  }

  async deleteTool(item: ToolListItem) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tools/${item.id}`;
    let observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('Tool deleted ')))

    return firstValueFrom(observable)
  }

  searchTools(searchQuery: string) {
    const gridRequest: GridRequest = {
      pageRequest: {
        pageSize: 15,
        pageNumber: 0
      },
      searchQuery: searchQuery,
    };

    const params = this.httpClientHelper.createHttpParams();
    let url = `${environment.apiUrl}/tools`;
    const observable = this.http.post<CollectionDto<ToolListItem>>(url, gridRequest, { params: params })
      .pipe(
        tap(r => this.log.debug('Tools: ', r)),
        map(response => response.items)
      );

    return firstValueFrom(observable);
  }

}
