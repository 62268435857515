import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { LoggedUserService } from '../service/logged-user.service';


// @Injectable({
//   providedIn: 'root'
// })
// export class CanActiveGuard implements CanActivate {
//   constructor(private loggedUserService: LoggedUserService) {
//   }
//
//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): MaybeAsync<GuardResult> {
//     let authorities = this.loggedUserService.loggedUser.authorities;
//
//     let requiredAuthorities = route.data['authorities'] as Array<string>;
//
//     return requiredAuthorities.every(p => authorities.includes(p));
//   }
// }

export const HasAnyRoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    let loggedUserService = inject(LoggedUserService);

    let requiredAuthorities = route.data['authorities'] as Array<string>;

    return loggedUserService.hasAnyPermission(requiredAuthorities);
}

