import {Component, inject} from '@angular/core';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {SharedModule} from 'primeng/api';
import {TableLazyLoadEvent, TableModule} from 'primeng/table';
import {TranslateModule} from '@ngx-translate/core';
import {BaseNodeGridComponent} from '../../base-components/base-node-grid.component';
import {NodeType} from '../../../constants/node-type';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {TreeHelperService} from '../../../service/helper/tree-helper.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {GridRequest} from '../../../ model/grid-request.model';
import {UuidHelperService} from '../../../service/helper/uuid-helper.service';
import {WimIcons} from '../../../constants/wim-icons';
import {RouteType} from '../../../constants/route-type';
import {MatListItem} from '@angular/material/list';
import {Permission} from '../../../constants/Permission';
import {ReservationService} from '../../../service/reservation.service';
import {ReservationListItemModel} from '../../../ model/reservation/reservation-list-item.model';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-reservation-grid',
  standalone: true,
  imports: [
    SharedModule,
    TableModule,
    MatButton,
    MatIcon,
    HasPermissionDirective,
    MatListItem,
    TranslateModule,
    ShowIfDebugMode
  ],
  templateUrl: './reservation-grid.component.html',
  styleUrl: './reservation-grid.component.scss'
})
export class ReservationGridComponent extends BaseNodeGridComponent<ReservationListItemModel> {
  protected readonly Permission = Permission;
  protected override nodeType = NodeType.TOOL_RELATION;

  defaultSort = [{ field: 'dateFromFormatted', order: 1 }, { field: 'id', order: 1 }];

  defaultFilter = {
    "status": [
      {
        "value": 'done',
        "matchMode": "notEquals",
        "operator": "and"
      }
    ]
  };

  private reservationService = inject(ReservationService);
  private messageService = inject(CantaaMessageService);
  private treeHelperService = inject(TreeHelperService);
  private errorHandler = inject(CantaaErrorHandlerService);


  override async fetchData(selectedNode: WimTreeNode, lazyLoadMetadata: TableLazyLoadEvent) {
    await super.fetchData(selectedNode, lazyLoadMetadata);
  }

  override async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode) {
    try {
      return await this.reservationService.getReservations(gridRequest, selectedNode);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  override mapTreeItems(selectedNode: WimTreeNode, items: ReservationListItemModel[]): WimTreeNode[] {
    return items
      .map<WimTreeNode>(i => {

        let treeNode = {
          id: i.id,
          key: UuidHelperService.generateUuid(),
          label: `${i.id} ${i.locationPretty}`,
          data: i.name,
          icon: WimIcons.RESERVATION_ICON,
          nodeType: NodeType.RESERVATION,
          route: RouteType.RESERVATION,
          children: [] as WimTreeNode[],
          leaf: false,
          parent: selectedNode
        };

        i.treeNode = treeNode;

        treeNode.children = [
          this.treeHelperService.createToolSubCategoryRelationsTreeItem(treeNode)
        ];

        return treeNode;
      });
  }

  async delete() {
    try {
      await this.reservationService.deleteReservation(this.selectedItem!);
      this.messageService.success('DELETE.SUCCESS');
      await this.reFetchData();
    } catch (e) {
      throw this.errorHandler.handleError(e, 'DELETE.FAILED');
    }
  }

  public override async createNew() {
    await this.router.navigate(['/booking/reservation']);
  }

}
