<div class="container ">
  <form [formGroup]="detailForm" (ngSubmit)="onSave()">

    <div class="row">

      <div *showIfDebugMode class="col-4">
        <mat-form-field class="w-100">
          <mat-label>Id</mat-label>
          <input id="id" matInput formControlName="id">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'NAME' | translate }}</mat-label>
          <input id="name" matInput formControlName="name">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'INSPECTION.FIRST_INSPECTION_AFTER_X_MONTHS' | translate }}</mat-label>
          <input id="firstInspectionMonth" matInput formControlName="firstInspectionMonth" type="number">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'INSPECTION.NEXT_INSPECTION_AFTER_X_MONTHS' | translate }}</mat-label>
          <input id="nextInspectionMonth" matInput formControlName="nextInspectionMonth" type="number">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3" *hasPermission="Permission.INSPECTION_TYPE_EDIT">
        <button type="submit" mat-raised-button color="primary" [disabled]="detailForm.pristine || !detailForm.valid" class="m-1">
          {{ 'SAVE.SAVE' | translate }}
        </button>
        <button type="button" mat-raised-button (click)="onCancel()" [disabled]="detailForm.pristine">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>

  </form>


</div>
