import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'wim-test-data',
  standalone: true,
  imports: [],
  templateUrl: './test-data.component.html',
  styleUrl: './test-data.component.scss'
})
export class TestDataComponent {

  protected readonly environment = environment;
}
