import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {MatOption, MatSelect} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {CantaaLocalizationService} from '../../service/cantaa-localization.service';
import {ReloadPageService} from '../../service/reload-page.service';

@Component({
  selector: 'wim-language-market-selector',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatInputModule
  ],
  templateUrl: './language-market-selector.component.html',
  styleUrl: './language-market-selector.component.scss'
})
export class LanguageMarketSelectorComponent {

  languages: string[] = [];
  selectedLanguage = '';

  markets: string[] = [];
  selectedMarket = '';

  constructor(private cantaaLocalizationService: CantaaLocalizationService,
              private reloadPageService: ReloadPageService) {
    this.languages = cantaaLocalizationService.languages;
    this.selectedLanguage = cantaaLocalizationService.currentLanguage;

    this.markets = cantaaLocalizationService.markets;
    this.selectedMarket = cantaaLocalizationService.currentMarket;
  }

  async onLanguageChange($event: any) {
    this.cantaaLocalizationService.currentLanguage = $event.value;
    await this.reloadPageService.hardPageReload();
  }

  async onMarketChange($event: any) {
    this.cantaaLocalizationService.currentMarket = $event.value;
    await this.reloadPageService.hardPageReload();
  }

}
