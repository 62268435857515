import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReloadPageService {
  constructor(private router: Router) {
  }

  async hardPageReload() {
    window.location.reload();
  }

  async softPageReload() {
    const currentUrl = this.router.url;
    await this.router.navigateByUrl('/', {skipLocationChange: true});
    await this.router.navigate([currentUrl]);
  }

}
