import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator } from '@angular/forms';
import { ToolService } from '../service/tool.service';
import { catchError, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToolRfidValidator implements AsyncValidator {
  constructor(private toolService: ToolService) {
  }

  validate(control: AbstractControl) {
    return this.toolService.validateToolRfid(control.value)
      .pipe(
        map((r) => (r.valid ? null : { invalidRfid: true })),
        catchError(() => of(null)),
      );
  }
}
