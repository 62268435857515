import { Component, ElementRef, OnInit, viewChild } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { PaginatorModule } from 'primeng/paginator';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ToolService } from '../../../service/tool.service';
import { TransactionService } from '../../../service/transaction.service';
import { CantaaMessageService } from '../../../service/cantaa-message.service';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { ToolDocumentService } from '../../../service/tool-document.service';
import { InspectionBookingDto } from '../../../ model/booking.models';
import { FocusHelper } from '../../../service/helper/focus-helper';
import { ToolDetailItem } from '../../../ model/tool-detail.model';
import { InspectionService } from '../../../service/inspection.service';
import { ToolInspectionListItem } from '../../../ model/tool-inspection-list-item.model';
import { TranslatedValue } from '../../../ model/inspection-result.model';
import { JsonPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DebugComponent } from '../../debug/debug.component';
import { Router } from '@angular/router';
import { TestDataComponent } from '../../test-data/test-data.component';
import { SuggestionInputComponent } from '../../inputs/suggestion-input/suggestion-input.component';
import { ToolListItem } from '../../../ model/tool-list-item.model';
import { DisplayHelper } from '../../../service/helper/DisplayHelper';

@Component({
  selector: 'wim-tool-inspection-booking',
  standalone: true,
  templateUrl: './tool-inspection-booking.component.html',
  styleUrl: './tool-inspection-booking.component.scss',
  imports: [
    MatButton,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatRadioButton,
    MatRadioGroup,
    MatSelect,
    PaginatorModule,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    JsonPipe,
    TranslateModule,
    DebugComponent,
    TestDataComponent,
    SuggestionInputComponent
  ],
})
export class ToolInspectionBookingComponent implements OnInit {
  protected readonly DisplayHelper = DisplayHelper;
  selectedTool: ToolDetailItem | null = null;
  inspections: ToolInspectionListItem[] = [];
  receivedToolId: number | null = null;
  toolSuggestionInput = viewChild.required<SuggestionInputComponent<ToolListItem>>('toolSuggestionInput');

  results: TranslatedValue[] = [
    { value: 'SUCCESS', translateKey: 'RESULT.SUCCESS' },
    { value: 'FAILURE', translateKey: 'RESULT.FAILURE' }
  ];

  inspectionForm = this.fb.group({
    inspection: this.fb.control<ToolInspectionListItem | null>({ value: null, disabled: true }, Validators.required),
    result: this.fb.control<TranslatedValue | null>({ value: null, disabled: true }, Validators.required),
    comment: this.fb.control<string | null>({ value: null, disabled: true }),
  });

  constructor(private fb: FormBuilder,
              private toolService: ToolService,
              private transactionService: TransactionService,
              private messageService: CantaaMessageService,
              private el: ElementRef,
              private errorHandler: CantaaErrorHandlerService,
              protected toolDocumentService: ToolDocumentService,
              private inspectionService: InspectionService,
              private router: Router) {
    const receivedToolIdString = this.router.getCurrentNavigation()?.extras?.state?.['id'];
    this.receivedToolId = receivedToolIdString ? +receivedToolIdString : null;
  }

  async ngOnInit() {
    this.toolSuggestionInput().focus();

    await this.handleReceivedRfid();
  }

  private async handleReceivedRfid() {
    if (!this.receivedToolId) {
      return;
    }

    const toolDetail = await this.fetchToolDetail(this.receivedToolId);

    const listItem = {
      id: toolDetail.id,
      category: toolDetail.category,
      rfid: toolDetail.rfid
    } as ToolListItem;

    this.toolSuggestionInput().setValue(listItem);

    await this.onToolSelected(listItem, toolDetail);
  }

  private async fetchToolDetail(toolId: number) {
    try {
      return await this.toolService.getToolDetail(toolId);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async onToolSelected(toolListItem: ToolListItem, toolDetailItem: ToolDetailItem | null = null) {
    this.inspectionForm.reset();
    this.inspectionForm.disable();

    if (toolDetailItem) {
      this.selectedTool = toolDetailItem;
    } else {
      this.selectedTool = await this.toolService.getToolDetail(toolListItem.id);
    }

    this.messageService.success('TOOL.FOUND');

    this.toolDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(this.selectedTool.id, 'inspection_tool_icon');

    this.inspections = await this.fetchToolInspectionsByToolId(this.selectedTool.id);

    if (this.inspections.length == 0) {
      this.messageService.error('INSPECTION.NOT_FOUND');
      return;
    }

    this.inspectionForm.controls.inspection.enable();

    if (this.inspections.length > 1) {
      this.focusToInspection();
      return;
    }

    this.inspectionForm.patchValue({
      inspection: this.inspections[0]
    })
    this.inspectionForm.controls.result.enable();
    this.focusToResult();
  }

  private async fetchToolInspectionsByToolId(id: number) {
    try {
      return await this.inspectionService.getToolInspectionsByTool(id, false);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  onInspectionChange() {
    this.inspectionForm.controls.result.enable();
    this.focusToResult();
  }

  onResultChange() {
    this.inspectionForm.controls.comment.enable();
    this.focusToComment();
  }

  focusToInspection() {
    FocusHelper.focusFormField(this.el, 'inspection');
  }

  focusToResult() {
    FocusHelper.focusFormField(this.el, 'result');
  }

  focusToComment() {
    FocusHelper.focusFormField(this.el, 'comment');
  }

  onCancel() {
    this.initUi();
  }

  private initUi() {
    this.selectedTool = null;
    this.toolSuggestionInput().clear();
    this.inspectionForm.reset();
    this.inspectionForm.disable();
  }

  async onBooking() {
    const booking = {
      transactionTypeCode: 'Inspection',
      toolId: this.selectedTool?.id,
      toolInspectionId: this.inspectionForm.controls.inspection.value?.id,
      result: this.inspectionForm.controls.result.value?.value,
      comment: this.inspectionForm.controls.comment.value
    } as InspectionBookingDto

    await this.makeBooking(booking);

    this.messageService.success('BOOKING.SUCCESSFUL');
    this.initUi();
  }

  private async makeBooking(booking: InspectionBookingDto) {
    try {
      await this.transactionService.makeInspectionBooking(booking)
    } catch (e) {
      this.errorHandler.handleError(e, 'BOOKING.FAILED');
      throw e;
    }
  }

  toolFetchSuggestions = async (query: string): Promise<ToolListItem[]> => {
    return await this.toolService.searchTools(query);
  };

}
