<div class="card p-input-filled full-height">
  <p-table
    #dt
    dataKey="id"
    [value]="gridItems"
    [lazy]="true"
    [resizableColumns]="true" columnResizeMode="expand"
    (onLazyLoad)="fetchDataViaTableComponent($event)"
    [paginator]="true"
    [first]="gridMeta.lazyLoadMeta?.first ?? 0"
    [rows]="gridMeta.lazyLoadMeta?.rows ?? 20"
    [rowsPerPageOptions]="[10, 20, 50]"
    [paginatorDropdownAppendTo]="'body'"
    [totalRecords]="totalRecords"
    [sortMode]="'multiple'"
    [multiSortMeta]="gridMeta.lazyLoadMeta?.multiSortMeta"
    [filters]="gridMeta.filters!"
    [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <th id="defaultpicture" style="width:1px" pResizableColumn>
        </th>
        <th *showIfDebugMode id="id" style="min-width:8rem" pSortableColumn="id" pResizableColumn>
          Id
          <p-sortIcon field="id"></p-sortIcon>
          <p-columnFilter type="numeric" field="id" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="createdOn" style="min-width:8rem" pSortableColumn="id" pResizableColumn>
          {{ 'CREATED_ON' | translate }}
          <p-sortIcon field="createdOn"></p-sortIcon>
          <p-columnFilter type="date" field="createdOn" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="status" style="min-width:10rem" pSortableColumn="status" pResizableColumn>
          {{ 'STATUS' | translate }}
          <p-sortIcon field="status"></p-sortIcon>
          <p-columnFilter type="text" field="status" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="fileType" style="min-width:10rem" pSortableColumn="fileType" pResizableColumn>
          {{ 'FILETYPE' | translate }}
          <p-sortIcon field="fileType"></p-sortIcon>
          <p-columnFilter type="text" field="fileType" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="fileName" style="min-width:10rem" pSortableColumn="fileName" pResizableColumn>
          {{ 'FILENAME' | translate }}
          <p-sortIcon field="fileName"></p-sortIcon>
          <p-columnFilter type="text" field="fileName" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="text" style="min-width:10rem;" pSortableColumn="text" pResizableColumn>
          {{ 'TEXT' | translate }}
          <p-sortIcon field="text"></p-sortIcon>
          <p-columnFilter type="text" field="text" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-documents>
      <tr [pSelectableRow]="documents" (dblclick)="onRowDblClick($event, documents.treeNode)">
        <td style="padding: 0px;">

          <ng-template [ngIf]="documents.fileType == ('jpg')
                          || documents.fileType == ('jpeg')
                          || documents.fileType == ('bmp')
                          || documents.fileType == ('png')"
                       [ngIfElse]="noimgdiv">
            <img id="img_document_{{ documents.id }}" class="img-defaultphoto-icon iconw70xh70" src="">
          </ng-template>
          <ng-template #noimgdiv>
            <div style="width:70px;
                        height:75px;
                        text-align: center;
                        vertical-align: middle;
                        line-height: 75px;
                        background-color: lightgray;
                        color: black;
                        font-weight: bold;
                        font-size: 25px;
                        border-radius: 5px;
                        border: 1px solid gray">
              {{ documents.fileType.toUpperCase() }}
            </div>
          </ng-template>

        </td>
        <td *showIfDebugMode>{{ documents.id }}</td>
        <td>{{ documents.createdOn }}</td>
        <td>{{ documents.status }}</td>
        <td>{{ documents.fileType }}</td>
        <td>{{ documents.fileName }}</td>
        <td style="white-space: normal">{{ documents.text }}</td>
      </tr>
    </ng-template>
    <ng-template id="buttonCreate" pTemplate="paginatorleft">
    </ng-template>
  </p-table>
</div>
