<div class="container ">
  <div class="row">
    <p-table
      #dt
      [value]="dispositions"
      [sortMode]="'multiple'"
      [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="locationPretty" style="min-width:18rem" pSortableColumn="locationPretty" pResizableColumn>
            {{ 'LOCATION.LOCATION' | translate }}
            <p-sortIcon field="locationPretty"></p-sortIcon>
            <p-columnFilter type="text" field="locationPretty" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
          @if (isSubCategoryNameVisible()) {
            <th id="subCategoryName" style="min-width:12rem" pSortableColumn="subCategoryName" pResizableColumn>
              {{ 'SUBCATEGORY' | translate }}
              <p-sortIcon field="subCategoryName"></p-sortIcon>
              <p-columnFilter type="text" field="subCategoryName" display="menu" [showOperator]="false"></p-columnFilter>
            </th>
          }
          @if (isToolCategoryName()) {
            <th id="toolCategoryName" style="min-width:10rem" pSortableColumn="toolCategoryName" pResizableColumn>
              {{ 'TOOLCATEGORY' | translate }}
              <p-sortIcon field="toolCategoryName"></p-sortIcon>
              <p-columnFilter type="text" field="toolCategoryName" display="menu" [showOperator]="false"></p-columnFilter>
            </th>
          }
          <th id="dateFromFormatted" style="min-width:10rem" pSortableColumn="dateFromFormatted" pResizableColumn>
            {{ 'DATE.FROM' | translate }}
            <p-sortIcon field="dateFromFormatted"></p-sortIcon>
            <p-columnFilter type="date" field="dateFromFormatted" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
          <th id="dateUntilFormatted" style="min-width:10rem" pSortableColumn="dateUntilFormatted" pResizableColumn>
            {{ 'DATE.UNTIL' | translate }}
            <p-sortIcon field="dateUntilFormatted"></p-sortIcon>
            <p-columnFilter type="date" field="dateUntilFormatted" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
          <th id="reservedAmount" style="min-width:10rem" pSortableColumn="reservedAmount" pResizableColumn>
            {{ 'AMOUNT.RESERVED' | translate }}
            <p-sortIcon field="reservedAmount"></p-sortIcon>
            <p-columnFilter type="numeric" field="reservedAmount" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
          <th id="stockAmount" style="min-width:10rem" pSortableColumn="stockAmount" pResizableColumn>
            {{ 'AMOUNT.STOCK' | translate }}
            <p-sortIcon field="stockAmount"></p-sortIcon>
            <p-columnFilter type="numeric" field="stockAmount" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
          <th id="availability" style="min-width:12rem" pSortableColumn="availability" pResizableColumn>
            {{ 'AVAILABILITY.AVAILABILITY' | translate }}
            <p-sortIcon field="availability"></p-sortIcon>
            <p-columnFilter type="text" field="availability" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-disposition>
        <tr>
          <td>{{ disposition.locationPretty }}</td>
          @if (isSubCategoryNameVisible()) {
            <td>{{ disposition.subCategoryName }}</td>
          }
          @if (isToolCategoryName()) {
            <td>{{ disposition.toolCategoryName }}</td>
          }
          <td>{{ disposition.dateFrom | date: ('DATE.FORMAT' | translate) }}</td>
          <td>{{ disposition.dateUntil | date: ('DATE.FORMAT' | translate) }}</td>
          <td>{{ disposition.reservedAmount }}</td>
          <td>{{ disposition.stockAmount }}</td>
          <td>{{ ('AVAILABILITY.' + disposition.availability) | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
