import {Component, DestroyRef, ElementRef, inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {TreeModule, TreeNodeSelectEvent} from 'primeng/tree';
import {ButtonModule} from 'primeng/button';
import {CommonModule} from '@angular/common';
import {TreeNode} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {AppStateService} from '../../service/app-state.service';
import {WimTreeNode} from '../../ model/wim-tree.node';
import {ConsoleLoggerService} from '../../service/console-logger.service';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';

@Component({
  selector: 'wim-main-tree',
  standalone: true,
  imports: [CommonModule, ButtonModule, TreeModule, TableModule, RouterLink],
  templateUrl: './main-tree.component.html',
  styleUrl: './main-tree.component.scss'
})
export class MainTree implements OnInit {

  wimTreeNodes: WimTreeNode[] = [];
  @ViewChildren('nodeReference', { read: ElementRef }) nodeReferences!: QueryList<ElementRef>;

  selectedNodeForUI: TreeNode | TreeNode[] | null | undefined

  protected readonly destroyRef = inject(DestroyRef);

  constructor(private appState: AppStateService,
              private log: ConsoleLoggerService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    const onRootSubscription = this.appState.getRootNode$()
      .subscribe(root => {
        this.wimTreeNodes = [root];
      });

    const onCurrentTreeItemsSubscription = this.appState.getCurrentTreeItems$()
      .subscribe(currentTreeItems => {
        this.log.debug('currentTreeItems', currentTreeItems);
        if (this.getSelectedNode()) {
          this.getSelectedNode().children = currentTreeItems;
        }
      });

    const onSelectedNodeSubscription = this.appState.getSelectedNode$()
      .subscribe(selectedNode => {
        this.selectedNodeForUI = selectedNode;
        this.scrollTreeNodeIntoView(selectedNode);
      });

    this.destroyRef.onDestroy(() => {
      onRootSubscription.unsubscribe();
      onCurrentTreeItemsSubscription.unsubscribe();
      onSelectedNodeSubscription.unsubscribe();
    });
  }

  async nodeSelect(event: TreeNodeSelectEvent) {
    let node = event.node as WimTreeNode;
    await this.selectNodeAndNavigate(node);
  }

  async nodeExpand(event: any) {
    let node = event.node;
    this.selectedNodeForUI = node;
    await this.selectNodeAndNavigate(node);
  }

  private async selectNodeAndNavigate(node: WimTreeNode) {
    this.appState.setSelectedNode(node);

    if (node.id) {
      await this.router.navigate([node.route, node.id], { relativeTo: this.activatedRoute });
    } else {
      await this.router.navigate([node.route], { relativeTo: this.activatedRoute });
    }
  }

  nodeDoubleClick(event: any) {
    this.log.debug('Node DoubleClick', event);
    this.selectedNodeForUI = event.node;
    // this.selectedNodeForUI.expanded = true;
    this.appState.setSelectedNode(this.getSelectedNode());
    this.appState.setSelectedNode(event.node);
  }

  nodeCollapse(event: any) {
    this.log.debug('Node collapse');
  }

  nodeUnselect(event: any) {
    this.log.debug('node unselect');
  }

  getSelectedNode(): WimTreeNode {
    return this.selectedNodeForUI as WimTreeNode
  }

  private scrollTreeNodeIntoView(searchedNode: WimTreeNode): void {

    if (searchedNode && searchedNode.id) {
      let parent = searchedNode.parent;
      while (parent !== null && parent !== undefined) {
        parent.expanded = true;
        parent = parent.parent;
      }

      let node = this.nodeReferences.find((row) => row.nativeElement.id == searchedNode.key);
      node?.nativeElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }

  doubleClick(nodeKey: string) {

    let node = this.findNode(nodeKey, this.wimTreeNodes);
    if (node) {
      node.expanded = !node.expanded;
    }
  }

  findNode(nodeKey: string, nodes: WimTreeNode[] | undefined): WimTreeNode {

    if (!nodes) {
      return {}
    }
    let foundNode = nodes.find(n => n.key == nodeKey);
    if (foundNode) {
      return foundNode;
    }

    nodes.forEach((node) => {
      if (!foundNode) foundNode = this.findNode(nodeKey, node.children)
    });
    if (foundNode) {
      return foundNode;
    }

    return {};
  }
}
