import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {AppStateService} from '../../../service/app-state.service';
import {ToolService} from '../../../service/tool.service';
import {NodeType} from '../../../constants/node-type';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {ConsoleLoggerService} from '../../../service/console-logger.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {JsonPipe, NgIf, UpperCasePipe} from '@angular/common';
import {TableModule} from 'primeng/table';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {MatFormField, MatInput, MatLabel} from '@angular/material/input';
import {MatButton} from '@angular/material/button';
import {MatOption, MatSelect} from '@angular/material/select';
import {ToolDetailItem} from '../../../ model/tool-detail.model';
import {ToolCategoryListItem} from '../../../ model/tool-category-list-item.model';
import {ToolCategoryService} from '../../../service/tool-category.service';
import {ManufacturerListItem} from '../../../ model/manufacturer.model';
import {ManufacturerService} from '../../../service/manufacturer.service';
import {DropdownItemDto} from '../../../ model/dropdown-item.model';
import {SupplierService} from '../../../service/supplier.service';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatError, MatFormFieldModule} from '@angular/material/form-field';
import {TranslateModule} from '@ngx-translate/core';
import {RouteHelper} from '../../../service/helper/route-helper';
import {ActivatedRoute, Router} from '@angular/router';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {LoggedUserService} from '../../../service/logged-user.service';
import {ToolDocumentService} from "../../../service/tool-document.service";
import {CantaaLocalizationService} from '../../../service/cantaa-localization.service';
import {FormErrorHelper} from '../../../service/helper/form-error-helper';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-tool-detail',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TableModule,
    MatInput,
    MatLabel,
    MatFormField,
    MatButton,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatFormFieldModule,
    UpperCasePipe,
    TranslateModule,
    JsonPipe,
    HasPermissionDirective,
    ShowIfDebugMode,
    NgIf,
    MatError
  ],
  templateUrl: './tool-detail.component.html',
  styleUrl: './tool-detail.component.scss'
})
export class ToolDetail implements OnInit {

  protected readonly FormErrorHelper = FormErrorHelper;
  protected readonly Permission = Permission;

  private fb = inject(FormBuilder);
  private appStateService = inject(AppStateService);
  private toolService = inject(ToolService);
  private toolCategoryService = inject(ToolCategoryService);
  private manufacturerService = inject(ManufacturerService);
  private supplierService = inject(SupplierService);
  private messageService = inject(CantaaMessageService);
  private log = inject(ConsoleLoggerService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  protected toolDocumentService = inject(ToolDocumentService);
  private localization = inject(CantaaLocalizationService);
  private loggedUserService = inject(LoggedUserService);
  protected readonly destroyRef = inject(DestroyRef);

  detailForm = this.fb.group({
    id: this.fb.control<number | null>({ value: null, disabled: true }),
    category: this.fb.control<ToolCategoryListItem | null>({ value: null, disabled: false }, Validators.required),
    rfid: (''),
    productName: ['', [Validators.required]],
    serialNumber: (''),
    acquisitionDate: (new Date()),
    lastTransactionDate: ({ value: '', disabled: true }),
    warrantyUntil: (new Date()),
    acquisitionPrice: undefined,
    inventoryNumber: (''),
    unitNumber: (''),
    weight: undefined,
    length: undefined,
    width: undefined,
    height: undefined,
    manufacturer: this.fb.control<ManufacturerListItem | null>({ value: null, disabled: false }),
    locationPretty: ({ value: '', disabled: true }),
    supplier: this.fb.control<DropdownItemDto | null>({ value: null, disabled: false }),
    mainCategoryDesignation: ({ value: '', disabled: true }),
    subCategoryDesignation: ({ value: '', disabled: true }),
    toolProcessingType: ({ value: '', disabled: true })
  });

  categories: DropdownItemDto[] = [];
  manufacturers: ManufacturerListItem[] = [];
  suppliers: DropdownItemDto[] = [];
  initialValue: any | undefined | null;


  async ngOnInit() {

    this.categories = await this.fetchToolCategories();
    this.manufacturers = await this.fetchManufacturers();
    this.suppliers = await this.fetchSuppliers();

    if (this.manufacturers.length > 0) {
      this.detailForm.controls.manufacturer.enable();
    } else {
      this.detailForm.controls.manufacturer.disable();
    }

    if (this.suppliers.length > 0) {
      this.detailForm.controls.supplier.enable();
    } else {
      this.detailForm.controls.supplier.disable();
    }

    if (RouteHelper.isInNewState(this.route)) {
      await this.initFormForNewEntry();
    }

    const onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL) {
          return;
        }
        await this.initFormForEdit(selectedNode);
      });

    if (!this.loggedUserService.hasPermission(Permission.TOOL_EDIT)) {
      this.detailForm.disable();
    }

    this.destroyRef.onDestroy(() => {
      onSelectNodeSubscription.unsubscribe();
    });
  }

  private async initFormForNewEntry() {
  }

  private async initFormForEdit(selectedNode: WimTreeNode) {

    const detail = await this.toolService.getToolDetail(selectedNode.id!);

    let category = this.categories.find(c => c.id === detail.categoryId) ?? null;
    let manufacturer = this.manufacturers.find(c => c.id === detail.manufacturerId) ?? null;
    let supplier = this.suppliers.find(c => c.id === detail.supplierId) ?? null;

    this.initialValue =
      {
        id: detail.id,
        category: category,
        rfid: detail.rfid,
        productName: detail.productName,
        serialNumber: detail.serialNumber,
        acquisitionDate: detail.acquisitionDate,
        lastTransactionDate: detail.lastTransactionDate,
        warrantyUntil: detail.warrantyUntil,
        acquisitionPrice: detail.acquisitionPrice,
        inventoryNumber: detail.inventoryNumber,
        unitNumber: detail.unitNumber,
        weight: detail.weight,
        length: detail.length,
        width: detail.width,
        height: detail.height,
        manufacturer: manufacturer,
        locationPretty: detail.locationPretty,
        supplier: supplier,
        mainCategoryDesignation: detail.mainCategoryDesignation,
        subCategoryDesignation: detail.subCategoryDesignation,
        toolProcessingType: detail.toolProcessingType
      };

    // this.detailForm.markAsPristine();
    //this.toolDocumentService.getDefaultPhoto2ImgEleId(detail.id, 'tool_detail_photo');
    this.toolDocumentService.getDefaultPhotoScaled2ImgEleId(detail.id, 'tool_detail_photo',150,150);
    this.detailForm.reset(this.initialValue);
  }

  private async fetchToolCategories() {
    try {
      return await this.toolCategoryService.findCategoriesForDropdown();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private async fetchManufacturers() {
    try {
      return await this.manufacturerService.getManufacturers();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private async fetchSuppliers() {
    try {
      return await this.supplierService.getSuppliers();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async onSave() {
    this.log.debug('on save', this.detailForm.value);

    const toolDetailItem = {
      id: this.detailForm.getRawValue().id,
      categoryId: this.detailForm.getRawValue().category?.id,
      rfid: this.detailForm.getRawValue().rfid,
      productName: this.detailForm.getRawValue().productName,
      serialNumber: this.detailForm.getRawValue().serialNumber,
      acquisitionDate: this.detailForm.getRawValue().acquisitionDate,
      lastTransactionDate: this.detailForm.getRawValue().lastTransactionDate,
      warrantyUntil: this.detailForm.getRawValue().warrantyUntil,
      acquisitionPrice: this.detailForm.getRawValue().acquisitionPrice,
      inventoryNumber: this.detailForm.getRawValue().inventoryNumber,
      unitNumber: this.detailForm.getRawValue().unitNumber,
      weight: this.detailForm.getRawValue().weight,
      length: this.detailForm.getRawValue().length,
      width: this.detailForm.getRawValue().width,
      height: this.detailForm.getRawValue().height,
      manufacturerId: this.detailForm.getRawValue().manufacturer?.id,
      supplierId: this.detailForm.getRawValue().supplier?.id
    } as ToolDetailItem

    this.log.debug('on save toolDetailItem', toolDetailItem);

    try {
      await this.toolService.saveToolDetail(toolDetailItem);
      this.messageService.success('SAVE.SUCCESS');

      if (RouteHelper.isInNewState(this.route)) {
        await RouteHelper.navigateToGrid(this.route, this.router);
      }
    } catch (e) {
      this.errorHandler.handleFormError(this.detailForm, e, this.localization.instant('SAVE.FAILED'));
    }
  }

  async onCancel() {
    if (RouteHelper.isInNewState(this.route)) {
      this.detailForm.reset();
    } else {
      await this.initFormForEdit(this.appStateService.selectedNodeValue);
    }
  }

  hasCategories() {
    return this.categories && this.categories.length > 0;
  }

}
