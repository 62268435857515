import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridRequest } from '../ model/grid-request.model';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { WimTreeNode } from '../ model/wim-tree.node';
import { NodeType } from '../constants/node-type';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { ReservationListItemModel } from '../ model/reservation/reservation-list-item.model';
import { ReservationDetailModel } from '../ model/reservation/reservation-detail.model';
import { ReservationItemModel } from '../ model/reservation/reservation-item.model';
import { ReservationDropdownItemDto } from '../ model/reservation/reservation-dropdown-item.model';
import { CantaaErrorHandlerService } from './cantaa-error-handler.service';


@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  getReservations(request: GridRequest, selectedNode: WimTreeNode) {
    const params = this.httpClientHelper.createHttpParams();
    let url = this.createUrl(selectedNode);
    const observable = this.http.post<CollectionDto<ReservationListItemModel>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('Reservations: ', r)));
    return firstValueFrom(observable);
  }

  private createUrl(selectedNode: WimTreeNode) {
    return `${environment.apiUrl}${this.getUrl(selectedNode)}`;
  }

  private getUrl(selectedNode: WimTreeNode): string {
    const parent = (selectedNode.parent as WimTreeNode);
    const parentNodeType = parent?.nodeType;

    switch (parentNodeType) {
      case undefined:
        return `/reservations`;
      case NodeType.TOOL_CATEGORY:
        return `/reservations/category/${parent.id}`;
      default:
        throw new Error(`not Implemented for ${parentNodeType}`);
    }
  }

  getReservationDetail(reservationId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/reservations/${reservationId}`;

    return firstValueFrom(this.http.get<ReservationDetailModel>(url, { params: params })
      .pipe(tap(r => this.log.debug('reservation detail: ', r))));
  }

  getReservationItems(reservationId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/reservations/${reservationId}/items`;

    return firstValueFrom(this.http.get<CollectionDto<ReservationItemModel>>(url, { params: params })
      .pipe(tap(r => this.log.debug('reservation items: ', r)))
      .pipe(map(r => r.items)));
  }

  async deleteReservation(item: ReservationListItemModel) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/reservations/${item.id}`;
    let observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('Reservation deleted.')))

    return firstValueFrom(observable)
  }

  getReservationsForBooking(toolId: number, locationId: number, amount: number) {
    const params = this.httpClientHelper.createHttpParams()
      .append('toolId', toolId)
      .append('locationId', locationId)
      .append('amount', amount);

    const url = environment.apiUrl + `/reservations/booking`;

    const observable = this.http.get<CollectionDto<ReservationDropdownItemDto>>(url, { params: params })
      .pipe(tap(r => this.log.debug('reservations for booking: ', r)))
      .pipe(map(r => r.items));

    try {
      return firstValueFrom(observable);
    } catch (e) {
      throw this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
    }
  }

}
