import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridRequest } from '../ model/grid-request.model';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { WimTreeNode } from '../ model/wim-tree.node';
import { firstValueFrom, tap } from 'rxjs';
import { HttpClientHelper } from './http-client-helper.service';
import { ConsoleLoggerService } from './console-logger.service';
import { InspectionTypeListItem } from '../ model/inspection-type-list.item';
import { InspectionTypeDetail } from '../ model/inspection-type.detail';


@Injectable({
  providedIn: 'root'
})
export class InspectionTypesService {

  constructor(private http: HttpClient, private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getInspectionTypes(request: GridRequest, selectedNode: WimTreeNode) {
    const params = this.httpClientHelper.createHttpParams();
    let url = this.createUrl(selectedNode);
    const observable = this.http.post<CollectionDto<InspectionTypeListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('Fetched inspection types: ', r)))
    return firstValueFrom(observable);
  }

  private createUrl(selectedNode: WimTreeNode) {
    return environment.apiUrl + this.getUrl(selectedNode);
  }

  private getUrl(selectedNode: WimTreeNode): string {
    const parent = (selectedNode.parent as WimTreeNode);
    const parentNodeType = parent?.nodeType;

    switch (parentNodeType) {
      case undefined:
        return `/inspectionTypes/paged`;
      default:
        throw new Error("not Implemented");
    }

  }

  getInspectionTypeDetail(id: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/inspectionTypes/${id}`;
    const observable = this.http.get<InspectionTypeDetail>(url, { params: params })
      .pipe(tap(r => this.log.debug('tool inspection type: ', r)));
    return firstValueFrom(observable);
  }

  saveInspectionType(detail: InspectionTypeDetail) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/inspectionTypes`;
    let observable = this.http.post<number>(url, detail, { params: params })
      .pipe(tap(r => this.log.debug('saved: ', r)))

    return firstValueFrom(observable)
  }

  deleteInspectionType(item: InspectionTypeDetail | null) {
    if (item == null) {
      return;
    }

    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/inspectionTypes/${item.id}`;
    let observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('inspection type deleted ')))

    return firstValueFrom(observable)
  }

}
