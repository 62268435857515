import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatCheckbox} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {AssignmentDto} from '../../../ model/qualification-assignment.model';
import {AppStateService} from '../../../service/app-state.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {QualificationAssignmentService} from '../../../service/qualification-assignment.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {NodeType} from '../../../constants/node-type';
import {AssignmentComponent} from '../../assignment/assignment.component';


@Component({
  selector: 'wim-qualification-assignment-category',
  standalone: true,
  templateUrl: './qualification-assignment-category.component.html',
  styleUrl: './qualification-assignment-category.component.scss',
  imports: [
    MatCheckbox,
    FormsModule,
    TableModule,
    AssignmentComponent
  ],
})
export class QualificationAssignmentCategoryComponent implements OnInit {

  qualificationAssignments: AssignmentDto[] = [];
  onSelectNodeSubscription!: Subscription;

  protected readonly destroyRef = inject(DestroyRef);

  constructor(private appStateService: AppStateService,
              private errorHandler: CantaaErrorHandlerService,
              private qualificationAssignmentService: QualificationAssignmentService,
              private messageService: CantaaMessageService) {
  }

  async ngOnInit() {
    const onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL_CATEGORY) {
          return;
        }
        await this.fetchAssignments(selectedNode.id);
      });

    this.destroyRef.onDestroy(() => {
      onSelectNodeSubscription.unsubscribe();
    });
  }

  private async fetchAssignments(id: number) {
    try {
      this.qualificationAssignments = await this.qualificationAssignmentService.getQualificationAssignmentsByCategory(id);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async updateAssignment(assignment: AssignmentDto) {
    const id = this.appStateService.selectedNodeValue.id!;

    try {
      await this.qualificationAssignmentService.updateQualificationToCategoryAssignment(id, assignment.assignmentId, assignment.assigned);
      this.messageService.success('UPDATE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, 'UPDATE.FAILED');
    }
    await this.fetchAssignments(id);
  }
}
