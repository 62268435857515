<div class="container">
  <mat-tab-group>
    <mat-tab label="{{ 'HANDOVER' | translate }}">
      <ng-template matTabContent>
        <wim-bulk-booking-handout/>
      </ng-template>
    </mat-tab>

    <mat-tab label="{{ 'RETURN' | translate }}">
      <ng-template matTabContent>
        <wim-bulk-booking-return/>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
</div>
