import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, tap } from 'rxjs';
import { ConsoleLoggerService } from "./console-logger.service";
import { SystemSettings } from '../ model/system-settings.model';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getSystemSettings() {
    const url = environment.apiUrl + `/system-settings`;
    let params = this.httpClientHelper.createHttpParams();

    const observable = this.http.get<SystemSettings>(url, { params: params })
      .pipe(tap(r => this.log.debug('System settings: ', r)))
    return firstValueFrom(observable);
  }

  saveSystemSettings(systemSettings: SystemSettings) {
    const params = this.httpClientHelper.createHttpParams();
    const url = environment.apiUrl + `/system-settings`;
    const observable = this.http.put<void>(url, systemSettings, { params: params })

    return firstValueFrom(observable)
  }

}
