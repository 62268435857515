import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridRequest } from '../ model/grid-request.model';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { WimTreeNode } from '../ model/wim-tree.node';
import { HttpClientHelper } from './http-client-helper.service';
import { ToolCategoryListItem } from '../ model/tool-category-list-item.model';
import { firstValueFrom, map, tap } from 'rxjs';
import { ToolCategoryDetailItem } from '../ model/tool-category-detail.model';
import { NodeType } from '../constants/node-type';
import { LevelType } from '../ model/level-type.model';
import { ConsoleLoggerService } from './console-logger.service';
import { DropdownItemDto } from '../ model/dropdown-item.model';
import { CantaaErrorHandlerService } from './cantaa-error-handler.service';


@Injectable({
  providedIn: 'root'
})
export class ToolCategoryService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService,) {
  }

  getToolCategories(selectedNode: WimTreeNode, request: GridRequest) {
    const params = this.httpClientHelper.createHttpParams();

    const url = this.createUrl(selectedNode);
    const observable = this.http.post<CollectionDto<ToolCategoryListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('Fetched tool categories: ', r)))

    return firstValueFrom(observable);
  }

  private createUrl(selectedNode: WimTreeNode) {
    return environment.apiUrl + this.getUrl(selectedNode);
  }

  private getUrl(selectedNode: WimTreeNode): string {
    const parent = (selectedNode.parent as WimTreeNode);
    const parentNodeType = parent?.nodeType;

    switch (parentNodeType) {
      case undefined:
        return `/categories/top`;
      case NodeType.TOOL_CATEGORY:
        if (selectedNode.nodeType == NodeType.TOOL_SUB_CATEGORY_RELATION) {
          return `/categories/${parent.id}/subcategories`;
        } else {
          return `/categories/${parent.id}/parentcategories`;
        }
      case NodeType.INSPECTION_TYPE:
        return `/categories/inspection/${parent.id}`;
      case NodeType.RESERVATION:
        return `/categories/reservation/${parent.id}`;
      default:
        throw new Error("not Implemented");
    }

  }

  findAllWithSameLevelType(categoryId: number | null) {
    if (!categoryId) {
      return [];
    }

    let params = this.httpClientHelper.createHttpParams()
      .append('categoryId', categoryId);

    let url = environment.apiUrl + `/categories/with-same-level-type`;

    let observable = this.http.get<CollectionDto<ToolCategoryListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('Fetched tool categories: ', r)))
      .pipe(map(r => r.items));

    return firstValueFrom(observable);
  }

  findCategoriesForDropdown() {
    let url = environment.apiUrl + `/categories/dropdown`;
    return this.fetchCategoriesForDropDown(url);
  }

  findCategoriesForBookingDropdown() {
    let url = environment.apiUrl + `/categories/booking/dropdown`;
    return this.fetchCategoriesForDropDown(url);
  }

  private fetchCategoriesForDropDown(url: string) {
    let params = this.httpClientHelper.createHttpParams();

    let observable = this.http.get<CollectionDto<DropdownItemDto>>(url, { params: params })
      .pipe(tap(r => this.log.debug('Fetched tool categories: ', r)))
      .pipe(map(r => r.items));

    return firstValueFrom(observable);
  }

  async getToolCategoryDetail(toolCategoryId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/categories/${toolCategoryId}`;
    const call = this.http.get<ToolCategoryDetailItem>(url, { params: params });

    try {
      return await firstValueFrom(call);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  saveToolCategoryDetail(detail: ToolCategoryDetailItem) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/categories`;
    let observable = this.http.post<void>(url, detail, { params: params })

    return firstValueFrom(observable)
  }

  getToolCategoryLevelTypeByParentCategoryId(parentCategoryId: number | null) {
    let params = this.httpClientHelper.createHttpParams();

    if (parentCategoryId) {
      params = params.append('parentCategoryId', parentCategoryId);
    }
    let url = environment.apiUrl + `/categories/level-types`;
    let observable = this.http.get<LevelType>(url, { params: params })
      .pipe(tap(levelType => this.log.debug('Fetched level type: ', levelType)))
    return firstValueFrom(observable);
  }

  deleteToolCategory(toolCategory: ToolCategoryListItem | null) {
    if (toolCategory == null) {
      return;
    }

    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/categories/${toolCategory.id}`;
    let observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('category deleted ')))

    return firstValueFrom(observable)
  }

}
