import { Component } from '@angular/core';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { ToolLocationDetailComponent } from '../../tool-location/tool-location-detail/tool-location-detail.component';
import { ToolLocationInventoryComponent } from '../../tool-location/tool-location-inventory/tool-location-inventory.component';
import { ToolLocationGrid } from '../../tool-location/tool-location-grid/tool-location-grid.component';
import { ToolCategoryDetail } from '../tool-category-detail/tool-category-detail.component';
import {
  QualificationAssignmentLocationComponent
} from '../../qualification/qualification-assignment-location/qualification-assignment-location.component';
import {
  QualificationAssignmentCategoryComponent
} from '../../qualification/qualification-assignment-category/qualification-assignment-category.component';
import {
  InspectionAssignmentCategoryComponent
} from '../../inspection/inspection-assignment-category/inspection-assignment-category.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToolCategoryDispositionGridComponent } from '../tool-category-disposition-grid/tool-category-disposition-grid.component';

@Component({
  selector: 'wim-tool-category-tab',
  standalone: true,
  imports: [
    MatTab,
    MatTabContent,
    MatTabGroup,
    QualificationAssignmentLocationComponent,
    ToolLocationDetailComponent,
    ToolLocationInventoryComponent,
    ToolLocationGrid,
    QualificationAssignmentCategoryComponent,
    ToolCategoryDetail,
    InspectionAssignmentCategoryComponent,
    TranslateModule,
    ToolCategoryDispositionGridComponent
  ],
  templateUrl: './tool-category-tab.component.html',
  styleUrl: './tool-category-tab.component.scss'
})
export class ToolCategoryTabComponent {

}
