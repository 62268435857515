export const ToolConstants = {
  LEVEL_TYPE: {
    TOOL: 'TOOL',
  },

  PROCESSING_TYPE: {
    TOOL: 'TOOL',
    PART: 'PART',
    SMALL_PART: 'SMALLPART',
    CONSUMABLE: 'CONSUMABLE',
  }

};
