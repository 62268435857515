<div class="app-wrapper">
  <p-toast></p-toast>
  <wim-loading-indicator/>
  <div style="height: 100%">
    <wim-main-toolbar (sidenavToggled)="sidenav.toggle()"></wim-main-toolbar>
    <mat-sidenav-container>
      <mat-sidenav #sidenav opened [mode]="'side'" class="sidenav">
        <wim-sidenav-items></wim-sidenav-items>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
