<p-splitter
  id="main-layout-splitter"
  [panelSizes]="[25, 75]"
  [minSizes]="[15,30]"
  [style]="{ 'min-height': '80vh', 'height': '100%'}"
>

  <ng-template pTemplate>
    <wim-main-tree class="main-tree"></wim-main-tree>
  </ng-template>

  <ng-template pTemplate>
    <router-outlet></router-outlet>
  </ng-template>
</p-splitter>

