import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReservationService} from '../../../service/reservation.service';
import {JsonPipe} from '@angular/common';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatFormField, MatHint, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {SharedModule} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {TranslateModule} from '@ngx-translate/core';
import {ReservationDetailModel} from '../../../ model/reservation/reservation-detail.model';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-reservation-detail',
  standalone: true,
  imports: [
    JsonPipe,
    HasPermissionDirective,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatHint,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    TranslateModule,
    ShowIfDebugMode
  ],
  templateUrl: './reservation-detail.component.html',
  styleUrl: './reservation-detail.component.scss'
})
export class ReservationDetailComponent implements OnInit {
  protected readonly Permission = Permission;

  reservationForm = this.fb.group({
    id: this.fb.control<number | null>(null),
    pickUpLocation: this.fb.control<string | null>(null),
    employee: this.fb.control<string | null>(null),
    fromDate: this.fb.control<string | null>(null),
    toDate: this.fb.control<string | null>(null),
  })

  constructor(private route: ActivatedRoute,
              private reservationService: ReservationService,
              private fb: FormBuilder,
              private errorHandler: CantaaErrorHandlerService) {
  }

  async ngOnInit() {
    this.reservationForm.disable();
    this.route.paramMap.subscribe(async params => {
      const reservationId = Number(params.get('id'));
      const reservationDetail = await this.fetchReservationDetail(reservationId);
      this.setFormData(reservationDetail);
    });
  }

  private async fetchReservationDetail(reservationId: number) {
    try {
      return await this.reservationService.getReservationDetail(reservationId);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private setFormData(reservationDetail: ReservationDetailModel) {
    this.reservationForm.setValue({
        id: reservationDetail.id,
        pickUpLocation: reservationDetail.locationPretty,
        employee: reservationDetail.employeePretty,
        fromDate: reservationDetail.dateFromFormatted,
        toDate: reservationDetail.dateUntilFormatted
      }
    );
  }
}
