import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { SalutationsListItem } from '../ model/salutations-list-item.model';


@Injectable({
  providedIn: 'root'
})
export class SalutationService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getSalutations() {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/salutations`;
    const observable = this.http.get<CollectionDto<SalutationsListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('salutations: ', r)))
      .pipe(map(c => c.items))

    return firstValueFrom(observable)
  }


}
