import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoggedUserService } from '../service/logged-user.service';

// https://juri.dev/blog/2018/02/angular-permission-directive/
@Directive({
  selector: '[hasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnInit {
  private permission = '';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private loggedUserService: LoggedUserService
  ) {
  }

  ngOnInit() {
    this.loggedUserService.loggedUser$
      .subscribe(loggedUser => {
        this.updateView();
      });
  }

  @Input()
  set hasPermission(permission: string) {
    this.permission = permission;
    this.updateView();
  }

  private updateView() {
    if (this.loggedUserService.hasPermission(this.permission)) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
