import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PaginatorModule } from 'primeng/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { AppStateService } from '../../../service/app-state.service';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { NodeType } from '../../../constants/node-type';
import { Subscription } from 'rxjs';
import { ToolService } from '../../../service/tool.service';
import { GridRequest } from '../../../ model/grid-request.model';
import { PageDefinition } from '../../../ model/page-definition.model';
import {ToolDocumentService} from "../../../service/tool-document.service";
import { TranslateModule } from '@ngx-translate/core';
import {ToolTransactionListItem} from '../../../ model/tool-transaction-list-item.model';

@Component({
  selector: 'wim-transaction-detail-tools',
  standalone: true,
  imports: [
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    PaginatorModule,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    TranslateModule
  ],
  templateUrl: './transaction-detail-tools.component.html',
  styleUrl: './transaction-detail-tools.component.scss'
})
export class TransactionDetailToolsComponent implements OnInit {

  onSelectNodeSubscription!: Subscription;

  tools: ToolTransactionListItem[] = [];

  constructor(private appStateService: AppStateService,
              private toolService: ToolService,
              protected toolDocumentService: ToolDocumentService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  ngOnInit() {
    this.onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TRANSACTION) {
          return;
        }
        this.tools = await this.fetchTransactions(selectedNode.id);

        this.tools.forEach(tool => {
          this.toolDocumentService.getDefaultPhotoAndRender(tool.id);
        });

      });
  }

  private async fetchTransactions(transactionId: number) {
    const gridRequest = {
      pageRequest: { pageNumber: 0, pageSize: 1000 } as PageDefinition
    } as GridRequest;

    try {
      return await this.toolService.getToolsByTransaction(gridRequest, transactionId)
    } catch (e) {
      throw this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
    }
  }

}
