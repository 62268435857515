import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {AssignmentDto} from '../../../ model/qualification-assignment.model';
import {AppStateService} from '../../../service/app-state.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {QualificationAssignmentService} from '../../../service/qualification-assignment.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {NodeType} from '../../../constants/node-type';
import {AssignmentComponent} from '../../assignment/assignment.component';
import {TranslateModule} from '@ngx-translate/core';
import {CantaaLocalizationService} from '../../../service/cantaa-localization.service';


@Component({
  selector: 'wim-qualification-assignment-location',
  standalone: true,
  templateUrl: './qualification-assignment-location.component.html',
  styleUrl: './qualification-assignment-location.component.scss',
  imports: [
    MatCheckbox,
    FormsModule,
    TableModule,
    AssignmentComponent,
    TranslateModule
  ],
})
export class QualificationAssignmentLocationComponent implements OnInit {

  qualificationAssignments: AssignmentDto[] = [];

  protected readonly destroyRef = inject(DestroyRef);

  constructor(private appStateService: AppStateService,
              private errorHandler: CantaaErrorHandlerService,
              private localization: CantaaLocalizationService,
              private qualificationAssignmentService: QualificationAssignmentService,
              private messageService: CantaaMessageService) {
  }

  async ngOnInit() {
    const onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL_LOCATION) {
          return;
        }
        await this.fetchAssignment(selectedNode.id);
      });

    this.destroyRef.onDestroy(() => {
      onSelectNodeSubscription.unsubscribe();
    });
  }

  private async fetchAssignment(id: number) {
    try {
      this.qualificationAssignments = await this.qualificationAssignmentService.getQualificationAssignmentsByLocation(id);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  async updateAssignment(assignment: AssignmentDto) {
    const locationId = this.appStateService.selectedNodeValue.id!;

    try {
      await this.qualificationAssignmentService.updateQualificationToLocationAssignment(locationId, assignment.assignmentId, assignment.assigned);
      this.messageService.success('SAVE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, this.localization.instant('SAVE.FAILED'));
    }
    await this.fetchAssignment(locationId);
  }
}
