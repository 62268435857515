import { Component, OnInit, signal } from '@angular/core';
import { KeyValuePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'wim-error',
  standalone: true,
  imports: [
    KeyValuePipe,
    MatButton,
    MatIcon,
    MatTooltip,
    RouterLink
  ],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent implements OnInit {

  errorMessage = signal(null);

  ngOnInit(): void {
    this.errorMessage.set(history.state.errorMessage);
  }

}
