import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { Profile } from '../ model/profile.model';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getProfile() {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/profile`;
    const observable = this.http.get<Profile>(url, { params: params })
      .pipe(tap(r => this.log.debug('Profile: ', r)));

    return firstValueFrom(observable)
  }

}
