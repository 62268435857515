import {Component, inject} from '@angular/core';
import {BaseNodeGridComponent} from '../../base-components/base-node-grid.component';
import {TransactionService} from '../../../service/transaction.service';
import {TreeHelperService} from '../../../service/helper/tree-helper.service';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {NodeType} from '../../../constants/node-type';
import {TableModule} from 'primeng/table';
import {SharedModule} from 'primeng/api';
import {TransactionListItem} from '../../../ model/transaction-list-item.model';
import {WimIcons} from '../../../constants/wim-icons';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {RouteType} from '../../../constants/route-type';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {UuidHelperService} from '../../../service/helper/uuid-helper.service';
import {TranslateModule} from '@ngx-translate/core';
import {GridRequest} from '../../../ model/grid-request.model';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-transaction-grid',
  standalone: true,
  imports: [
    SharedModule,
    TableModule,
    MatButton,
    MatIcon,
    TranslateModule,
    ShowIfDebugMode
  ],
  templateUrl: './transaction-grid.component.html',
  styleUrl: './transaction-grid.component.scss'
})
export class TransactionGrid extends BaseNodeGridComponent<TransactionListItem> {
  protected override nodeType = NodeType.TRANSACTION_RELATION;

  private transactionService = inject(TransactionService);
  private treeHelperService = inject(TreeHelperService);
  private errorHandler = inject(CantaaErrorHandlerService);

  override async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode) {
    try {
      return await this.transactionService.getTransactions(gridRequest, selectedNode);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw (e);
    }
  }

  override mapTreeItems(selectedNode: WimTreeNode, items: TransactionListItem[]): WimTreeNode[] {
    return items
      .map<WimTreeNode>(t => {
        let treeNode = {
          id: t.id,
          key: UuidHelperService.generateUuid(),
          label: t.id + ' ' + t.transactionTypeName,
          data: t.id + t.transactionTypeName,
          icon: WimIcons.TRANSACTION_ICON,
          nodeType: NodeType.TRANSACTION,
          route: RouteType.TRANSACTION,
          children: [] as WimTreeNode[],
          leaf: false,
          parent: selectedNode
        };
        t.treeNode = treeNode;

        treeNode.children = [this.treeHelperService.createToolLocationRelationsTreeItem(treeNode),
          this.treeHelperService.createToolRelationsTreeItem(treeNode)];

        return treeNode;
      });
  }

}
