<div class="card p-input-filled full-height">
  <p-table
    #dt
    dataKey="id"
    [value]="gridItems"
    [lazy]="true"
    selectionMode="single"
    [resizableColumns]="true" columnResizeMode="expand"
    (onLazyLoad)="fetchDataViaTableComponent($event)"
    [paginator]="true"
    [first]="gridMeta.lazyLoadMeta?.first ?? 0"
    [rows]="gridMeta.lazyLoadMeta?.rows ?? 20"
    [rowsPerPageOptions]="[10, 20, 50]"
    [paginatorDropdownAppendTo]="'body'"
    [totalRecords]="totalRecords"
    [sortMode]="'multiple'"
    [multiSortMeta]="gridMeta.lazyLoadMeta?.multiSortMeta"
    [filters]="gridMeta.filters!"
    [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *showIfDebugMode id="id" style="min-width:8rem" pSortableColumn="id" pResizableColumn>
          Id
          <p-sortIcon field="id"></p-sortIcon>
          <p-columnFilter type="numeric" field="id" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="createdOn" style="min-width:8rem" pSortableColumn="createdOn" pResizableColumn>
          {{ 'CREATED_ON' | translate }}
          <p-sortIcon field="createdOn"></p-sortIcon>
          <p-columnFilter type="date" field="createdOn" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="transactionTypeCode" style="min-width:10rem" pSortableColumn="transactionTypeCode" pResizableColumn>
          {{ 'TRANSACTION.CODE' | translate }}
          <p-sortIcon field="transactionTypeCode"></p-sortIcon>
          <p-columnFilter type="text" field="transactionTypeCode" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="transactionTypeName" style="min-width:10rem" pSortableColumn="transactionTypeName" pResizableColumn>
          {{ 'TRANSACTION.TYPE' | translate }}
          <p-sortIcon field="transactionTypeName"></p-sortIcon>
          <p-columnFilter type="text" field="transactionTypeName" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="fromPretty" style="min-width:10rem" pSortableColumn="fromPretty" pResizableColumn>
          {{ 'FROM' | translate }}
          <p-sortIcon field="fromPretty"></p-sortIcon>
          <p-columnFilter type="text" field="fromPretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="toPretty" style="min-width:10rem" pSortableColumn="toPretty" pResizableColumn>
          {{ 'TO' | translate }}
          <p-sortIcon field="toPretty"></p-sortIcon>
          <p-columnFilter type="text" field="toPretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transaction>
      <tr [pSelectableRow]="transaction" (dblclick)="onRowDblClick($event, transaction.treeNode)">
        <td *showIfDebugMode>{{ transaction.id }}</td>
        <td>{{ transaction.createdOn }}</td>
        <td>{{ transaction.transactionTypeCode }}</td>
        <td>{{ transaction.transactionTypeName }}</td>
        <td>{{ transaction.fromPretty }}</td>
        <td>{{ transaction.toPretty }}</td>
      </tr>
    </ng-template>
    <ng-template id="buttonCreate" pTemplate="paginatorleft">
    </ng-template>
  </p-table>
</div>
