import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from "./console-logger.service";
import { ToolInspectionListItem } from '../ model/tool-inspection-list-item.model';
import { ToolInspectionDetail } from '../ model/tool-inspection-detail.model';


@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  constructor(private http: HttpClient, private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getToolInspectionsByTool(toolId: number, done: boolean | null = null) {
    const url = environment.apiUrl + `/inspections/tool/${toolId}`;
    let params = this.httpClientHelper.createHttpParams();

    if (done != null) {
      params = params.append('done', done);
    }

    let observable = this.http.get<CollectionDto<ToolInspectionListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('inspections: ', r)))
      .pipe(map(c => c.items))
    return firstValueFrom(observable);
  }

  findFirstPendingToolInspection(toolId: number) {
    const url = environment.apiUrl + `/inspections/tool/${toolId}/first-pending`;
    let params = this.httpClientHelper.createHttpParams();

    let observable = this.http.get<ToolInspectionDetail>(url, { params: params })
      .pipe(tap(r => this.log.debug('inspection: ', r)))
    return firstValueFrom(observable);
  }

}
