<mat-tab-group>
  <mat-tab label="{{ 'DETAIL' | translate }}">
    <ng-template matTabContent>
      <wim-tool-detail></wim-tool-detail>
    </ng-template>
  </mat-tab>
    <mat-tab label="{{ 'DOCUMENTS.DOCUMENTS' | translate }}">
    <ng-template matTabContent>
      <wim-tool-detail-documents></wim-tool-detail-documents>
    </ng-template>
  </mat-tab>
    <mat-tab label="{{ 'INVENTORY.INVENTORY' | translate }}">
    <ng-template matTabContent>
      <wim-tool-detail-inventory></wim-tool-detail-inventory>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'INSPECTIONS.INSPECTIONS' | translate }}">
    <ng-template matTabContent>
      <wim-tool-detail-inspections></wim-tool-detail-inspections>
    </ng-template>
  </mat-tab>
  @if (showInspectionTab) {
    <mat-tab label="{{ 'INSPECTION.INSPECTION' | translate }}">
      <ng-template matTabContent>
        <wim-tool-detail-inspection></wim-tool-detail-inspection>
      </ng-template>
    </mat-tab>
  }

</mat-tab-group>
