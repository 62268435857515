import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemJobService } from '../../../../service/system-job.service';
import { HasPermissionDirective } from '../../../../directive/has-permission.directive';
import { MatButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { SystemJobUpdate } from '../../../../ model/system-job-update.model';
import { CantaaMessageService } from '../../../../service/cantaa-message.service';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'wim-system-job-edit',
  standalone: true,
  imports: [
    FormsModule,
    HasPermissionDirective,
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    TranslateModule,
    MatCheckbox,
    MatHint,
    MatIcon,
    MatMiniFabButton,
    MatIconButton
  ],
  templateUrl: './system-job-edit.component.html',
  styleUrl: './system-job-edit.component.scss'
})
export class SystemJobEditComponent implements OnInit {

  detailForm = this.fb.group({
    jobId: { value: '', disabled: true },
    jobName: { value: '', disabled: true },
    jobDescription: '',
    enabled: false,
    cron: ''
  });

  constructor(private fb: FormBuilder,
              private systemJobService: SystemJobService,
              private messageService: CantaaMessageService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const jobId = this.route.snapshot.paramMap.get('jobId');
    console.log(jobId);
    await this.fetchJob(jobId);
  }

  private async fetchJob(jobId: string | null) {
    const systemJob = await this.systemJobService.getSystemJob(jobId!);

    this.detailForm.setValue(
      {
        jobId: systemJob.jobId,
        jobName: systemJob.jobName,
        jobDescription: systemJob.jobDescription,
        enabled: systemJob.enabled,
        cron: systemJob.cron,
      });
  }

  async onSave() {
    const jobId = this.detailForm.getRawValue().jobId;

    const systemJobUpdate = {
      jobId: jobId,
      jobDescription: this.detailForm.value.jobDescription,
      enabled: this.detailForm.value.enabled,
      cron: this.detailForm.value.cron
    } as SystemJobUpdate;

    await this.systemJobService.updateSystemJob(systemJobUpdate);
    this.messageService.success("UPDATE.SUCCESS");

    // await this.fetchJob(jobId);
    await this.navigateToJobList();
  }

  async onCancel() {
    await this.navigateToJobList();
  }

  private async navigateToJobList() {
    await this.router.navigate(['../'], { relativeTo: this.route });
  }

  openLinkInNewTab(url: string) {
    window.open(url, '_blank');
  }
}
