import { Injectable } from '@angular/core';
import { WimTreeNode } from '../../ model/wim-tree.node';
import { NodeType } from '../../constants/node-type';
import { WimIcons } from '../../constants/wim-icons';
import { RouteType } from '../../constants/route-type';
import { UuidHelperService } from './uuid-helper.service';
import { CantaaLocalizationService } from '../cantaa-localization.service';

@Injectable({
  providedIn: 'root'
})
export class TreeHelperService {

  private _toolRootItem: WimTreeNode;
  private _locationRootItem: WimTreeNode;
  private _transactionRootItem: WimTreeNode;
  private _toolCategoryRootItem: WimTreeNode;
  private _inspectionTypeRootItem: WimTreeNode;
  private _documentRootItem: WimTreeNode;
  private _reservationRootItem: WimTreeNode;

  constructor(private localization: CantaaLocalizationService) {
    this._toolRootItem = this.createToolRootTreeItem();
    this._locationRootItem = this.createToolLocationRootTreeItem();
    this._transactionRootItem = this.createTransactionRootTreeItem();
    this._toolCategoryRootItem = this.createToolCategoryRootTreeItem();
    this._inspectionTypeRootItem = this.createInspectionTypeRootTreeItem();
    this._documentRootItem = this.createDocumentRootTreeItem();
    this._reservationRootItem = this.createReservationRootTreeItem();
  }

  get toolRootItem(): WimTreeNode {
    return this._toolRootItem;
  }

  get locationRootItem(): WimTreeNode {
    return this._locationRootItem;
  }

  get transactionRootItem(): WimTreeNode {
    return this._transactionRootItem;
  }

  get toolCategoryRootItem(): WimTreeNode {
    return this._toolCategoryRootItem;
  }


  get inspectionTypeRootItem(): WimTreeNode {
    return this._inspectionTypeRootItem;
  }

  get documentRootItem(): WimTreeNode {
    return this._documentRootItem;
  }


  get reservationRootItem(): WimTreeNode {
    return this._reservationRootItem;
  }

  private createToolLocationRootTreeItem(): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('LOCATIONS.LOCATIONS'),
      data: 'Locations',
      icon: WimIcons.TOOL_LOCATION_ICON,
      nodeType: NodeType.TOOL_LOCATION_RELATION,
      route: RouteType.LOCATIONS,
      children: [],
      leaf: false,
      parent: undefined
    };
  }

  private createReservationRootTreeItem(): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('RESERVATION.RESERVATIONS'),
      data: 'reservations',
      icon: WimIcons.RESERVATION_ICON,
      nodeType: NodeType.RESERVATION_RELATION,
      route: RouteType.RESERVATIONS,
      children: [],
      leaf: false,
      parent: undefined
    };
  }

  public createReservationRelationsTreeItem(parent: WimTreeNode): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('RESERVATION.RESERVATIONS'),
      data: 'reservations',
      icon: WimIcons.RESERVATION_ICON,
      nodeType: NodeType.RESERVATION_RELATION,
      route: RouteType.RESERVATIONS,
      children: [],
      leaf: false,
      parent: parent
    };
  }

  public createToolLocationRelationsTreeItem(parent: WimTreeNode): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('LOCATIONS.LOCATIONS'),
      data: 'Locations',
      icon: WimIcons.RELATION_ICON,
      nodeType: NodeType.TOOL_LOCATION_RELATION,
      route: RouteType.LOCATIONS,
      children: [],
      leaf: false,
      parent: parent
    };
  }

  private createTransactionRootTreeItem(): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('TRANSACTIONS'),
      data: 'Transactions',
      icon: WimIcons.TRANSACTION_ICON,
      nodeType: NodeType.TRANSACTION_RELATION,
      route: RouteType.TRANSACTIONS,
      children: [],
      leaf: false,
      parent: undefined
    };
  }

  public createTransactionRelationsTreeItem(parent: WimTreeNode): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('TRANSACTIONS'),
      data: 'Transactions',
      icon: WimIcons.RELATION_ICON,
      nodeType: NodeType.TRANSACTION_RELATION,
      route: RouteType.TRANSACTIONS,
      children: [],
      leaf: false,
      parent: parent
    };
  }

  private createToolRootTreeItem(): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('TOOLS'),
      data: 'Tools',
      icon: WimIcons.TOOL_ICON,
      nodeType: NodeType.TOOL_RELATION,
      route: RouteType.TOOLS,
      children: [],
      leaf: false,
      parent: undefined
    };
  }

  public createToolRelationsTreeItem(parent: WimTreeNode): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('TOOLS'),
      data: 'Tools',
      icon: WimIcons.RELATION_ICON,
      nodeType: NodeType.TOOL_RELATION,
      route: RouteType.TOOLS,
      children: [],
      leaf: false,
      parent: parent
    };
  }

  private createToolCategoryRootTreeItem(): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('CATEGORIES.CATEGORIES'),
      data: 'ToolCategories',
      icon: WimIcons.TOOL_CATEGORY_ICON,
      nodeType: NodeType.TOOL_SUB_CATEGORY_RELATION,
      route: RouteType.CATEGORIES,
      children: [],
      leaf: false,
      parent: undefined
    };
  }

  public createToolSubCategoryRelationsTreeItem(parent: WimTreeNode): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('CATEGORY.CHILDREN'),
      data: 'ToolCategories',
      icon: WimIcons.RELATION_ICON,
      nodeType: NodeType.TOOL_SUB_CATEGORY_RELATION,
      route: RouteType.CATEGORIES,
      children: [],
      leaf: false,
      parent: parent
    };
  }

  public createToolParentCategoryRelationsTreeItem(parent: WimTreeNode): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('CATEGORY.PARENT'),
      data: 'ToolCategories',
      icon: WimIcons.RELATION_ICON,
      nodeType: NodeType.TOOL_PARENT_CATEGORY_RELATION,
      route: RouteType.CATEGORIES,
      children: [],
      leaf: false,
      parent: parent
    };
  }

  private createInspectionTypeRootTreeItem(): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('INSPECTIONTYPES.INSPECTIONTYPES'),
      data: 'Inspections',
      icon: WimIcons.INSPECTION_ICON,
      nodeType: NodeType.INSPECTION_TYPE_RELATION,
      route: RouteType.INSPECTION_TYPES,
      children: [],
      leaf: false,
      parent: undefined
    };
  }

  public createInspectionRelationsTreeItem(parent: WimTreeNode): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('INSPECTIONTYPES.INSPECTIONTYPES'),
      data: 'Inspections',
      icon: WimIcons.RELATION_ICON,
      nodeType: NodeType.INSPECTION_TYPE_RELATION,
      route: RouteType.INSPECTION_TYPES,
      children: [],
      leaf: false,
      parent: parent
    };
  }

  private createDocumentRootTreeItem(): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('DOCUMENTS.DOCUMENTS'),
      data: 'Documents',
      icon: WimIcons.DOCUMENT_ICON,
      nodeType: NodeType.DOCUMENT_RELATION,
      route: RouteType.DOCUMENTS,
      children: [],
      leaf: false,
      parent: undefined
    };
  }

  public createDocumentRelationsTreeItem(parent: WimTreeNode): WimTreeNode {
    return {
      key: UuidHelperService.generateUuid(),
      label: this.localization.instant('DOCUMENTS.DOCUMENTS'),
      data: 'Documents',
      icon: WimIcons.RELATION_ICON,
      nodeType: NodeType.TOOL_RELATION,
      route: RouteType.DOCUMENTS,
      children: [],
      leaf: false,
      parent: parent
    };
  }
}
