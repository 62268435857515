import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridRequest } from '../ model/grid-request.model';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { WimTreeNode } from '../ model/wim-tree.node';
import { NodeType } from '../constants/node-type';
import { ToolLocationListItem } from '../ model/tool-location-list-item.model';
import { firstValueFrom, map, tap } from 'rxjs';
import { ToolLocationDetail } from '../ model/tool-location-detail.model';
import { HttpClientHelper } from './http-client-helper.service';
import { ConsoleLoggerService } from './console-logger.service';
import { VisibleTabsModel } from '../ model/visible-tabs.model';
import { DropdownItemDto } from '../ model/dropdown-item.model';
import { CantaaErrorHandlerService } from './cantaa-error-handler.service';
import { FilterDefinition } from '../ model/filter-definition.model';


@Injectable({
  providedIn: 'root'
})
export class ToolLocationService {

  constructor(private http: HttpClient, private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  getToolLocations(request: GridRequest, selectedNode: WimTreeNode) {
    const params = this.httpClientHelper.createHttpParams();
    let url = this.createUrl(selectedNode);
    const observable = this.http.post<CollectionDto<ToolLocationListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('Fetched tool locations: ', r)))
    return firstValueFrom(observable);
  }

  private createUrl(selectedNode: WimTreeNode) {
    return environment.apiUrl + this.getUrl(selectedNode);
  }

  private getUrl(selectedNode: WimTreeNode): string {
    const parent = (selectedNode.parent as WimTreeNode);
    const parentNodeType = parent?.nodeType;

    switch (parentNodeType) {
      case undefined:
        return `/locations/paged`;
      case NodeType.TRANSACTION:
        return `/locations/transaction/${parent.id}`;
      case NodeType.TOOL:
        return `/locations/tool/${parent.id}`;
      case NodeType.DOCUMENT:
        return `/locations/document/${parent.id}`;
      default:
        throw new Error("not Implemented 4 nodetype : " + parentNodeType);
    }

  }

  async getToolLocationDetailById(toolLocationId: number, handleError = true) {
    const url = environment.apiUrl + `/locations/${toolLocationId}`;

    const params = this.httpClientHelper.createHttpParams();
    const observable = this.http.get<ToolLocationDetail>(url, { params: params })
      .pipe(tap(r => this.log.debug('tool location detail: ', r)));

    try {
      return await firstValueFrom(observable);
    } catch (e) {
      if (handleError) {
        this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      }
      throw e;
    }
  }

  async getDefaultLocationId() {
    const params = this.httpClientHelper.createHttpParams();
    let url = `${environment.apiUrl}/locations/defaultLocation`;
    const request = this.http.get<number>(url, { params: params });

    try {
      const defaultLocation = await firstValueFrom(request);
      this.log.debug('default location: ', defaultLocation);
      return defaultLocation;
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }

  }

  getToolLocationVisibleTabs(toolLocationId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/locations/${toolLocationId}/visibleTabs`;
    const observable = this.http.get<VisibleTabsModel>(url, { params: params })
      .pipe(tap(r => this.log.debug('VisibleTabs: ', r)));
    return firstValueFrom(observable);
  }

  async getToolLocationsForBooking() {
    let url = `${environment.apiUrl}/locations/booking`;

    try {
      return await this.getLocationsForDropdown(url);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  getToolLocationsForReservation() {
    let url = `${environment.apiUrl}/locations/reservation`;
    return this.getLocationsForDropdown(url);
  }

  getToolLocationsForDropdown() {
    let url = `${environment.apiUrl}/locations/dropdown`;
    return this.getLocationsForDropdown(url);
  }

  getLocationsForDropdown(url: string) {
    const params = this.httpClientHelper.createHttpParams();
    const observable = this.http.get<CollectionDto<DropdownItemDto>>(url, { params: params })
      .pipe(tap(r => this.log.debug('tool locations: ', r)))
      .pipe(map(c => c.items));

    return firstValueFrom(observable);
  }

  saveToolLocationDetail(detail: ToolLocationDetail) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/locations`;
    let observable = this.http.post<number>(url, detail, { params: params })
      .pipe(tap(r => this.log.debug('saved: ', r)))

    return firstValueFrom(observable)
  }

  async deleteLocation(item: ToolLocationListItem) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/locations/${item.id}`;
    let observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('Location deleted ')))

    return firstValueFrom(observable)
  }

  searchLocations(searchQuery: string, mobile?: boolean | undefined) {
    const gridRequest: GridRequest = {
      pageRequest: {
        pageSize: 15,
        pageNumber: 0
      },
      filterDefinitions: this.getFilterDefinitions(mobile),
      searchQuery: searchQuery,
    };

    const params = this.httpClientHelper.createHttpParams();
    let url = `${environment.apiUrl}/locations/paged`;
    const observable = this.http.post<CollectionDto<ToolLocationListItem>>(url, gridRequest, { params: params })
      .pipe(
        tap(r => this.log.debug('Locations: ', r)),
        map(response => response.items)
      );

    return firstValueFrom(observable);
  }


  private getFilterDefinitions(mobile: boolean | undefined) {
    if (!mobile) {
      return undefined;
    }

    const filterDefinition = {
      propertyName: 'mobile',
      value: String(mobile),
      filterOperator: '=='
    } as FilterDefinition;

    return [filterDefinition];
  }
}
