import { Component } from '@angular/core';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { ToolDetail } from '../../tool/tool-detail/tool-detail.component';
import { ToolDetailDocumentsComponent } from '../../tool/tool-detail-documents/tool-detail-documents.component';
import { ToolDetailInspectionComponent } from '../../tool/tool-detail-inspection/tool-detail-inspection.component';
import { ToolDetailInspectionsComponent } from '../../tool/tool-detail-inspections/tool-detail-inspections.component';
import { ToolDetailInventoryComponent } from '../../tool/tool-detail-inventory/tool-detail-inventory.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReservationDetailComponent } from '../reservation-detail/reservation-detail.component';
import { ReservationDetailItemsComponent } from '../reservation-detail-items/reservation-detail-items.component';

@Component({
  selector: 'wim-reservation-tab-detail',
  standalone: true,
  imports: [
    MatTab,
    MatTabContent,
    MatTabGroup,
    ToolDetail,
    ToolDetailDocumentsComponent,
    ToolDetailInspectionComponent,
    ToolDetailInspectionsComponent,
    ToolDetailInventoryComponent,
    TranslateModule,
    ReservationDetailComponent,
    ReservationDetailItemsComponent
  ],
  templateUrl: './reservation-tab-detail.component.html',
  styleUrl: './reservation-tab-detail.component.scss'
})
export class ReservationTabDetailComponent {

}
