import {CantaaLocale} from '../ model/CantaaLocale';

export class LocaleHelper {

  public static parseLocale(languageMarket: string): CantaaLocale {
    const fields = languageMarket.split('-');

    const lang = fields[0];
    const market = fields[1];

    return {
      language: lang,
      market: market
    } as CantaaLocale;
  }

}
