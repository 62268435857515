import { Component, OnInit } from '@angular/core';
import { LanguageMarketSelectorComponent } from '../language-market-selector/language-market-selector.component';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatListItem, MatListModule } from '@angular/material/list';
import { MatLine } from '@angular/material/core';
import { RouteType } from '../../constants/route-type';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { HasPermissionDirective } from '../../directive/has-permission.directive';
import { Permission } from '../../constants/Permission';
import { HasAnyPermissionDirective } from '../../directive/has-any-permission.directive';

@Component({
  selector: 'wim-sidenav-items',
  standalone: true,
  imports: [
    LanguageMarketSelectorComponent,
    MatButton,
    MatDivider,
    MatIcon,
    RouterLink,
    RouterLinkActive,
    MatListItem,
    MatListModule,
    MatLine,
    MatExpansionModule,
    TranslateModule,
    HasPermissionDirective,
    HasAnyPermissionDirective,
  ],
  templateUrl: './sidenav-items.component.html',
  styleUrl: './sidenav-items.component.scss'
})
export class SidenavItemsComponent implements OnInit {

  protected readonly RouteType = RouteType;
  protected readonly Permission = Permission;

  isExplorer = false;
  isTable = false;
  isBooking = false;
  isAdmin = false;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkRoute();
      }
    });
    this.checkRoute(); // initial check when component loads
  }

  checkRoute(): void {
    this.isExplorer = this.router.url.includes('/explorer');
    this.isTable = this.router.url.includes('/table');
    this.isBooking = this.router.url.includes('/booking');
    this.isAdmin = this.router.url.includes('/admin');
  }

}
