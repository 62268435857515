<div class="container highlight-cursor">

  <div class="row">
    <div class="col-6">
      <form [formGroup]="myLocationForm" autocomplete="off">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION.MY' | translate }}</mat-label>
          <mat-select class="w-100"
                      formControlName="myLocation"
                      (selectionChange)="onMyLocationChange()">
            @for (toolLocation of toolLocations; track toolLocation.id) {
              <mat-option [value]="toolLocation">{{ toolLocation.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </div>

    <div class="col-1">
      <img class="img-defaultphoto-icon iconw70xh70" id="myLocationForm_icon" src="">
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <wim-suggestion-input id="toLocationSuggestionInput"
                            #toLocationSuggestionInput
                            [label]="'HAND_OUT_TO_LOCATION' | translate"
                            [fetchSuggestions]="toLocationFetchSuggestions"
                            [displayFn]="DisplayHelper.locationDisplayFn"
                            (onOptionSelected)="onToLocationSelected($event)"/>
    </div>
    <div class="col-1">
      <img class="img-defaultphoto-icon iconw70xh70" id="fromToLocationForm_icon" src="">
    </div>
    <div class="col-4 mt-3 ms-3">
      <label class="description" for="toLocationSuggestionInput">{{ selectedToLocation?.locationPretty }}</label>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <wim-suggestion-input #toolSuggestionInput
                            [label]="'TOOL.TOOL' | translate"
                            [fetchSuggestions]="toolFetchSuggestions"
                            [displayFn]="DisplayHelper.toolDisplayFn"
                            (onOptionSelected)="onToolSelected($event)"/>
    </div>
    <div class="col-2">
      <form [formGroup]="amountForm" autocomplete="off" (ngSubmit)="onAmountSubmit()">
        <mat-form-field class="w-100">
          <mat-label>{{ 'AMOUNT.AMOUNT' | translate }}</mat-label>
          <input id="toolAmount" type="number" matInput formControlName="toolAmount">
        </mat-form-field>
      </form>
    </div>
    <div class="col-1">
      <img class="img-defaultphoto-icon iconw70xh70" id="tool_icon" src="">
    </div>
    <div class="col-4 mt-3 ms-3">
      <label class="description" for="toolAmount">{{ selectedTool?.category }}</label>
    </div>
  </div>

  <form [formGroup]="reservationForm" autocomplete="off">
    <div class="row">
      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'RESERVATION.RESERVATION' | translate }}</mat-label>
          <mat-select class="w-100"
                      formControlName="reservation"
                      (selectionChange)="onReservationChange($event)">
            <mat-option></mat-option>
            @for (reservation of reservations; track reservation.id) {
              <mat-option [value]="reservation">{{ reservation.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{ 'DATE.RETURN' | translate }}</mat-label>
          <input matInput [matDatepicker]="returnDateDatePicker" formControlName="returnDate"
                 (dateChange)="onReturnDateChanged()"
                 (keydown.enter)="onReturnDateEnter()">
          <mat-hint>{{ 'DATE.FORMAT' | translate }}</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="returnDateDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #returnDateDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>


  <div class="row">
    <p-table
      #dt
      [value]="bookItems"
      [scrollable]="true" scrollHeight="40vh"
      [resizableColumns]="true" columnResizeMode="expand"
      dataKey="id"
      styleClass="p-datatable-sm strikethrough-able-grid grid-shadow p-datatable-gridlines">

      <ng-template id="simple-header" pTemplate="header">
        <tr>
          <th id="defaultpicture" style="width:1px"></th>
          <th id="header_id">{{ 'TOOL.TOOL' | translate }}</th>
          <th id="header_name">{{ 'AMOUNT.AMOUNT' | translate }}</th>
          <th id="header_via_reservation">R</th>
          <th id="header_message">{{ 'MESSAGE' | translate }}</th>
          <th id="header_return_date">{{ 'DATE.RETURN' | translate }}</th>
          <th id="header_action"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="display: flex; justify-content: center;"><img id="{{ toolDocumentService.generateImgElementId4Tool(item.id) }}"
                                                                   class="img-defaultphoto-icon iconw70xh70" src=""></td>
          <td [class]="getTdClass(item)">{{ item?.tool.category }}</td>
          <td [class]="getTdClass(item)">{{ item.amount }}</td>
          <td [class]="getTdClass(item)">
            @if (item.reservationId) {
              <mat-icon>check</mat-icon>
            } @else {
              <mat-icon>horizontal_rule</mat-icon>
            }
          </td>
          <td [class]="getTdClass(item)">
            <ul class="message-list">
              @for (m of item.messages; track m) {
                <li>{{ m }}</li>
              }
            </ul>
          </td>
          <td [class]="getTdClass(item)">{{ item.returnDate | date: ('DATE.FORMAT' | translate) }}</td>
          <td>

            <button type="button" mat-icon-button (click)="onUpdateActiveState(item)">
              @if (item.active) {
                <mat-icon>delete</mat-icon>
              } @else {
                <mat-icon fontIcon="restart_alt"></mat-icon>
              }
            </button>

          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="col-4 my-2">
    <button mat-raised-button color="primary" class="me-2" (click)="onBulkBooking()" [disabled]="getActiveItems().length == 0">
      {{ 'SAVE.SAVE' | translate }}
    </button>

    <button mat-raised-button (click)="onCancel()">
      {{ 'CANCEL' | translate }}
    </button>

  </div>

  <br>
  <br>

  <wim-test-data>
    <hr>
    <h2>[DEBUG] Test Werkzeuge [DEBUG]</h2>
    <table style="border: 1px solid black;">
      <tr>
        <td>Werkzeug</td>
        <td>Stichsäge</td>
        <td>a4130408019016e01</td>
      </tr>
      <tr>
        <td>Werkzeug</td>
        <td>Abgastester</td>
        <td>e12c0408019016e0</td>
      </tr>
      <tr>
        <td>Werkzeug</td>
        <td>Pressbacke</td>
        <td>e847011c019016e0</td>
      </tr>
      <tr>
        <td>Kleinteil</td>
        <td>Handschuh</td>
        <td>handschuhe</td>
        <td>f0cfcc22500104e0</td>
      </tr>
    </table>

    <h2>[DEBUG] Test Mitarbeiter [DEBUG]</h2>
    <table style="border: 1px solid black;">
      <tr>
        <td>Mitarbeiter</td>
        <td>Alwine Anakonda</td>
        <td>anakonda</td>
      </tr>
      <tr>
        <td>Mitarbeiter</td>
        <td>Amir Admiral</td>
        <td>68e23109000104e0</td>
      </tr>
    </table>
  </wim-test-data>

</div>
