import { Table } from 'primeng/table';

export class PTableHelper {

  public static navigateToBottomOfTable(dataTable: Table, itemsLength: number) {
    setTimeout(() => dataTable.scrollTo({ top: itemsLength * 500, behavior: 'smooth' }), 0);
    // setTimeout(() => {
    //   let tableBody = this.dataTable?.containerViewChild?.nativeElement.getElementsByClassName("p-element p-datatable-tbody")[0];
    //   tableBody.scrollTop = tableBody.scrollHeight;
    //
    //   this.dataTable.scrollTo({ top: tableBody.scrollHeight, behavior: 'smooth' });
    // }, 0)
  }

}
