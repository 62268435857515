import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { DropdownItemDto } from '../ model/dropdown-item.model';


@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getSuppliers() {
    const params = this.httpClientHelper.createHttpParams();
    let url = `${environment.apiUrl}/suppliers/dropdown`;
    const observable = this.http.get<CollectionDto<DropdownItemDto>>(url, { params: params })
      .pipe(tap(r => this.log.debug('suppliers: ', r)))
      .pipe(map(c => c.items))

    return firstValueFrom(observable)
  }

}
