import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CantaaSnackBarComponent } from '../components/cantaa-snack-bar/cantaa-snack-bar.component';
import { SnackbarData } from '../components/cantaa-snack-bar/snackbar-data';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar) {
  }

  openSuccessSnackBar(summary: string, detail?: string) {
    this._snackBar.openFromComponent(CantaaSnackBarComponent, {
      panelClass: 'success-snackbar',
      data: { summary: summary, detail: detail } as SnackbarData,
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      politeness: 'assertive'
    });
  }

}
