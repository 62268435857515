import {Routes} from '@angular/router';
import {About} from './components/about/about.component';
import {LogoutComponent} from './components/logout/logout.component';
import {
  MobileLocationBookingComponent
} from './components/booking/mobile-location-booking/mobile-location-booking.component';
import {Explorer} from './components/explorer/explorer.component';
import {ProfileComponent} from './components/profile/profile.component';
import {TestComponent} from './components/test/test-component/test.component';
import {ManufacturerGridComponent} from './components/quick-grid/manufacturer-grid/manufacturer-grid.component';
import {
  ToolLocationTypeGridComponent
} from './components/quick-grid/tool-location-type-grid/tool-location-type-grid.component';
import {DocumentTypeGridComponent} from './components/quick-grid/document-type-grid/document-type-grid.component';
import {CostCenterGridComponent} from './components/quick-grid/cost-center-grid/cost-center-grid.component';
import {ToolTabList} from './components/tool/tool-tab-list/tool-tab-list.component';
import {ToolDetail} from './components/tool/tool-detail/tool-detail.component';
import {ToolLocationGrid} from './components/tool-location/tool-location-grid/tool-location-grid.component';
import {
  ToolLocationTabDetailComponent
} from './components/tool-location/tool-location-tab-detail/tool-location-tab-detail.component';
import {TransactionGrid} from './components/transaction/transaction-grid/transaction-grid.component';
import {ToolCategoryDetail} from './components/tool-category/tool-category-detail/tool-category-detail.component';
import {ToolCategoryGrid} from './components/tool-category/tool-category-grid/tool-category-grid.component';
import {
  InspectionTypeDetailComponent
} from './components/inspection/inspection-type-detail/inspection-type-detail.component';
import {InspectionTypeGridComponent} from './components/inspection/inspection-type-grid/inspection-type-grid.component';
import {
  ToolLocationDetailComponent
} from './components/tool-location/tool-location-detail/tool-location-detail.component';
import {RouteType} from './constants/route-type';
import {ToolTabDetailComponent} from './components/tool/tool-tab-detail/tool-tab-detail.component';
import {QualificationGridComponent} from './components/quick-grid/qualification-grid/qualification-grid.component';
import {ToolCategoryTabComponent} from './components/tool-category/tool-category-tab/tool-category-tab.component';
import {BulkBookingComponent} from './components/booking/bulk-booking/bulk-booking.component';
import {
  TransactionTabDetailComponent
} from './components/transaction/transaction-tab-detail/transaction-tab-detail.component';
import {HasAnyRoleGuard} from './security/CustomGuards';
import {Permission} from './constants/Permission';
import {
  ToolInspectionBookingComponent
} from './components/booking/tool-inspection-booking/tool-inspection-booking.component';
import {DocumentGrid} from "./components/document/document-grid/document-grid.component";
import {DocumentDetailComponent} from "./components/document/document-detail/document-detail";
import {ReservationBookingComponent} from './components/booking/reservation-booking/reservation-booking.component';
import {ReservationGridComponent} from './components/reservation/reservation-grid/reservation-grid.component';
import {
  ReservationTabDetailComponent
} from './components/reservation/reservation-tab-detail/reservation-tab-detail.component';
import {SystemInfoComponent} from './components/system/system-info/system-info.component';
import {SystemJobListComponent} from './components/system/system-job/system-job-list/system-job-list.component';
import {SystemJobEditComponent} from './components/system/system-job/system-job-edit/system-job-edit.component';
import {ErrorComponent} from './components/error/error/error.component';
import {TestSearchComponent} from './components/test/test-search/test-search.component';
import {SystemSettingsComponent} from './components/system/system-settings/system-settings.component';

export const routes: Routes = [
  { path: '', redirectTo: 'explorer/tool', pathMatch: 'full' },
  {
    path: 'explorer/:rootType', component: Explorer,
    children: [
      {
        path: RouteType.TOOLS,
        component: ToolTabList,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.TOOL_READ] }
      },
      {
        path: RouteType.TOOLS + '/new',
        component: ToolDetail,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.TOOL_CREATE] }
      },
      {
        path: RouteType.TOOL_ALL + '/:id',
        component: ToolTabDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.TOOL_READ] }
      }, {
        path: RouteType.TOOL_INSPECTABLE + '/:id',
        component: ToolTabDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.TOOL_READ] }
      },
      {
        path: RouteType.LOCATIONS,
        component: ToolLocationGrid,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.LOCATION_READ] }
      },
      {
        path: RouteType.LOCATIONS + '/new',
        component: ToolLocationDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.LOCATION_CREATE] }
      },
      {
        path: RouteType.LOCATION + '/:id',
        component: ToolLocationTabDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.LOCATION_READ] }
      },
      {
        path: RouteType.TRANSACTIONS,
        component: TransactionGrid,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.TRANSACTION_READ] }
      },
      {
        path: RouteType.TRANSACTION + '/:id',
        component: TransactionTabDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.TRANSACTION_READ] }
      },
      {
        path: RouteType.DOCUMENTS,
        component: DocumentGrid,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.DOCUMENT_READ] }
      },
      {
        path: RouteType.DOCUMENT + '/:id',
        component: DocumentDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.DOCUMENT_READ] }
      },
      {
        path: RouteType.CATEGORIES,
        component: ToolCategoryGrid,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.CATEGORY_READ] }
      },
      {
        path: RouteType.CATEGORIES + '/new',
        component: ToolCategoryDetail,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.CATEGORY_CREATE] }
      },
      {
        path: RouteType.CATEGORY + '/:id',
        component: ToolCategoryTabComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.CATEGORY_READ] }
      },
      {
        path: RouteType.INSPECTION_TYPES,
        component: InspectionTypeGridComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.INSPECTION_TYPE_READ] }
      },
      {
        path: RouteType.INSPECTION_TYPES + '/new',
        component: InspectionTypeDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.INSPECTION_TYPE_CREATE] }
      },
      {
        path: RouteType.INSPECTION_TYPE + '/:id',
        component: InspectionTypeDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.INSPECTION_TYPE_READ] }
      },
      {
        path: RouteType.RESERVATIONS,
        component: ReservationGridComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.RESERVATION_READ] }
      },
      {
        path: RouteType.RESERVATION + '/:id',
        component: ReservationTabDetailComponent,
        canActivate: [HasAnyRoleGuard],
        data: { authorities: [Permission.RESERVATION_READ] }
      },
    ]
  },
  {
    path: 'table/manufacturer-grid',
    component: ManufacturerGridComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.TABLE_READ] }
  },
  {
    path: 'table/tool-location-type-grid',
    component: ToolLocationTypeGridComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.TABLE_READ] }
  },
  {
    path: 'table/document-type-grid',
    component: DocumentTypeGridComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.TABLE_READ] }
  },
  {
    path: 'table/cost-center-grid',
    component: CostCenterGridComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.TABLE_READ] }
  },
  {
    path: 'table/qualification-grid',
    component: QualificationGridComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.TABLE_READ] }
  },
  {
    path: 'booking/tool',
    component: BulkBookingComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.BOOKING_MANAGER] }
  },
  {
    path: 'booking/location',
    component: MobileLocationBookingComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.BOOKING_MANAGER] }
  },
  {
    path: 'booking/inspection',
    component: ToolInspectionBookingComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.BOOKING_MANAGER] }
  },
  {
    path: 'booking/reservation',
    component: ReservationBookingComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.BOOKING_MANAGER] }
  },
  { path: 'profile', component: ProfileComponent },
  { path: 'about', component: About },
  { path: 'test', component: TestComponent },
  { path: 'test-search', component: TestSearchComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'error', component: ErrorComponent },
  {
    path: 'admin/system-info',
    component: SystemInfoComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.SYSTEM_INFO] }
  },
  {
    path: 'admin/system-jobs',
    component: SystemJobListComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.SYSTEM_JOB] }
  },
  {
    path: 'admin/system-jobs' + '/:jobId',
    component: SystemJobEditComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.SYSTEM_JOB] }
  },
  {
    path: 'admin/system-settings',
    component: SystemSettingsComponent,
    canActivate: [HasAnyRoleGuard],
    data: { authorities: [Permission.SYSTEM_INFO] }
  },
];
