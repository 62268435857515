import { Component, ElementRef, OnInit, viewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { ToolDocumentService } from '../../../service/tool-document.service';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { EmployeeService } from '../../../service/employee.service';
import { DropdownItemDto } from '../../../ model/dropdown-item.model';
import { ToolCategoryService } from '../../../service/tool-category.service';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { FocusHelper } from '../../../service/helper/focus-helper';
import { Permission } from '../../../constants/Permission';
import { HasPermissionDirective } from '../../../directive/has-permission.directive';
import { ToolLocationDocumentService } from '../../../service/tool-location-document.service';
import {
  BookingItemDto,
  BulkBookItemDto,
  ReservationBookingDto,
  ReservationBookingValidateItemDto,
  ReservationGridItemDto
} from '../../../ model/booking.models';
import { TransactionService } from '../../../service/transaction.service';
import { CantaaMessageService } from '../../../service/cantaa-message.service';
import { ToolLocationService } from '../../../service/tool-location.service';
import { MatIcon } from '@angular/material/icon';
import { SharedModule } from 'primeng/api';
import { Table, TableModule } from 'primeng/table';
import { UuidHelperService } from '../../../service/helper/uuid-helper.service';
import { PTableHelper } from '../../../service/helper/p-table-helper';

@Component({
  selector: 'wim-reservation-booking',
  standalone: true,
  templateUrl: './reservation-booking.component.html',
  styleUrl: './reservation-booking.component.scss',
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    MatFormField,
    MatButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatHint,
    MatSuffix,
    HasPermissionDirective,
    MatIcon,
    MatIconButton,
    SharedModule,
    TableModule,
  ]
})
export class ReservationBookingComponent implements OnInit {
  protected readonly Permission = Permission;
  dataTable = viewChild.required<Table>('dt');

  employees: DropdownItemDto[] = [];
  categories: DropdownItemDto[] = [];
  toolLocations: DropdownItemDto[] = [];

  gridItems: ReservationGridItemDto[] = [];

  reservationForm = this.fb.group({
    pickUpLocation: this.fb.control<DropdownItemDto | null>({ value: null, disabled: false }, Validators.required),
    employee: this.fb.control<DropdownItemDto | null>({ value: null, disabled: true }),
    fromDate: this.fb.control<Date | null>({ value: new Date(), disabled: true }, Validators.required),
    toDate: this.fb.control<Date | null>({ value: new Date(), disabled: true }, Validators.required),
    category: this.fb.control<DropdownItemDto | null>({ value: null, disabled: true }, Validators.required),
    amount: [{ value: 1, disabled: true }, [Validators.required, Validators.min(0)]],
  });

  constructor(private fb: FormBuilder,
              private employeeService: EmployeeService,
              private toolCategoryService: ToolCategoryService,
              private el: ElementRef,
              private errorHandler: CantaaErrorHandlerService,
              private toolLocationDocumentService: ToolLocationDocumentService,
              private messageService: CantaaMessageService,
              private transactionService: TransactionService,
              protected toolDocumentService: ToolDocumentService,
              private toolLocationService: ToolLocationService) {
  }

  async ngOnInit() {
    this.employees = await this.fetchEmployees();
    this.categories = await this.fetchToolCategories();
    this.toolLocations = await this.fetchToolLocations();
    this.focusPickUpLocation();
  }

  private async fetchToolLocations() {
    try {
      return await this.toolLocationService.getToolLocationsForReservation();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  onPickUpLocationChange() {
    this.reservationForm.controls.employee.enable();
    this.reservationForm.controls.fromDate.enable();
    this.reservationForm.controls.toDate.enable();

    const pickupLocationId = this.reservationForm.value.pickUpLocation?.id;
    this.toolLocationDocumentService.getDefaultPhoto2ImgEleId(pickupLocationId, 'reservation_pickuplocation_icon');
    this.focusToEmployee();
  }

  onEmployeeChange() {
    const employeeId = this.reservationForm.value.employee?.id;
    this.toolLocationDocumentService.getDefaultPhoto2ImgEleId(employeeId, 'reservation_employee_icon');
    this.focusToFromDate();
  }

  onCategoryChange() {
    this.reservationForm.controls.amount.enable();
    this.focusToAmount();
  }

  async onAmountEnter($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    await this.processItem();
  }

  onFromDateChanged() {
    if (!this.reservationForm.controls.fromDate.valid) {
      return;
    }
    this.focusToToDate();
  }

  onToDateChanged() {
    if (!this.reservationForm.controls.toDate.valid) {
      return;
    }

    this.reservationForm.controls.category.enable();

    this.reservationForm.controls.pickUpLocation.disable();
    this.reservationForm.controls.employee.disable();
    this.reservationForm.controls.fromDate.disable();
    this.reservationForm.controls.toDate.disable();

    this.focusToCategory();
  }

  async onReservationFormSubmit() {
    await this.makeBooking();
  }

  private async processItem() {
    const itemToValidate = {
      pickupLocationId: this.reservationForm.getRawValue().pickUpLocation?.id,
      fromDate: this.reservationForm.getRawValue().fromDate,
      toDate: this.reservationForm.getRawValue().toDate,
      categoryId: this.reservationForm.getRawValue().category?.id,
      amount: this.reservationForm.getRawValue().amount,
    } as ReservationBookingValidateItemDto;

    let validationMessage = await this.getValidateMessage(itemToValidate);

    const bookItem = {
      id: UuidHelperService.generateUuid(),
      pickupLocation: this.reservationForm.getRawValue().pickUpLocation,
      employee: this.reservationForm.getRawValue().employee,
      fromDate: this.reservationForm.getRawValue().fromDate,
      toDate: this.reservationForm.getRawValue().toDate,
      category: this.reservationForm.getRawValue().category,
      amount: this.reservationForm.getRawValue().amount,
      message: validationMessage,
      active: true
    } as ReservationGridItemDto

    this.gridItems = this.gridItems.concat(bookItem);
    PTableHelper.navigateToBottomOfTable(this.dataTable(), this.gridItems.length);

    this.reservationForm.controls.category.setValue(null);

    this.reservationForm.controls.amount.setValue(1);
    this.reservationForm.controls.amount.disable();

    this.focusToCategory();
  }

  async getValidateMessage(itemToValidate: ReservationBookingValidateItemDto) {
    try {
      return await this.transactionService.validateReservationBooking(itemToValidate);
    } catch (e) {
      throw this.errorHandler.handleError(e, 'VALIDATION.FAILED');
    }
  }

  private async makeBooking() {
    let bookingItems = this.getActiveItems()
      .map(i => ({
        id: i.category.id,
        amount: i.amount,
      } as BookingItemDto));

    const booking = {
      pickupLocationId: this.reservationForm.getRawValue().pickUpLocation?.id,
      employeeId: this.reservationForm.getRawValue().employee?.id,
      fromDate: this.reservationForm.getRawValue().fromDate,
      toDate: this.reservationForm.getRawValue().toDate,
      reservationItems: bookingItems
    } as ReservationBookingDto

    try {
      await this.transactionService.makeReservationBooking(booking)
    } catch (e) {
      this.errorHandler.handleError(e, 'RESERVATION.FAILED');
      throw e;
    }
    this.messageService.success('RESERVATION.SUCCESSFUL');
    this.setUiToInitState();
  }

  private setUiToInitState() {
    this.gridItems = [];

    this.reservationForm.controls.pickUpLocation.enable();
    this.reservationForm.controls.employee.disable();
    this.reservationForm.controls.fromDate.disable();
    this.reservationForm.controls.toDate.disable();
    this.reservationForm.controls.category.disable();
    this.reservationForm.controls.amount.disable();
    this.focusPickUpLocation();

    this.reservationForm.reset({
        fromDate: new Date(),
        toDate: new Date(),
        amount: 1
      }
    )
  }

  onCancel() {
    this.setUiToInitState();
  }

  private async fetchEmployees() {
    try {
      return await this.employeeService.getEmployeesForDropdown();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private async fetchToolCategories() {
    try {
      return await this.toolCategoryService.findCategoriesForBookingDropdown();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  focusToCategory() {
    FocusHelper.focusFormField(this.el, 'category');
  }

  focusToAmount() {
    FocusHelper.focusFormField(this.el, 'amount');
  }

  focusToFromDate() {
    FocusHelper.focusFormField(this.el, 'fromDate');
  }

  focusToToDate() {
    FocusHelper.focusFormFieldLongTimeout(this.el, 'toDate');
  }

  focusToEmployee() {
    FocusHelper.focusFormFieldLongTimeout(this.el, 'employee');
  }

  focusPickUpLocation() {
    FocusHelper.focusFormFieldLongTimeout(this.el, 'pickUpLocation');
  }

  getTdClass(item: BulkBookItemDto) {
    return item.active ? "" : 'strikethrough';
  }

  onUpdateActiveState(item: BulkBookItemDto) {
    item.active = !item.active;
  }

  getActiveItems() {
    return this.gridItems.filter(i => i.active);
  }


}
