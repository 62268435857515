import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map } from 'rxjs';
import { ToolType } from '../ model/tool-type.model';


@Injectable({
  providedIn: 'root'
})
export class ToolTypeService {

  constructor(private http: HttpClient, private httpClientHelper: HttpClientHelper) {
  }

  getToolTypes() {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/tool-types`;
    const observable = this.http.get<CollectionDto<ToolType>>(url, { params: params })
      .pipe(map(c => c.items))

    return firstValueFrom(observable)
  }

}
