import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { AssignmentDto } from '../ model/qualification-assignment.model';


@Injectable({
  providedIn: 'root'
})
export class QualificationAssignmentService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getQualificationAssignmentsByLocation(locationId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/qualifications-assignment/location/${locationId}`;
    const observable = this.http.get<CollectionDto<AssignmentDto>>(url, { params: params })
      .pipe(tap(r => this.log.debug('QualificationAssignment: ', r)))
      .pipe(map(c => c.items))

    return firstValueFrom(observable)
  }


  updateQualificationToLocationAssignment(locationId: number, qualificationId: number, assigned: boolean) {
    let params = this.httpClientHelper.createHttpParams();
    params = params.append('qualificationId', qualificationId);
    params = params.append('assigned', assigned);

    let url = environment.apiUrl + `/qualifications-assignment/location/${locationId}`;
    const observable = this.http.put<boolean>(url, null, { params: params })
      .pipe(tap(r => this.log.debug('QualificationAssignment updated: ', r)))

    return firstValueFrom(observable)
  }

  getQualificationAssignmentsByCategory(categoryId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/qualifications-assignment/category/${categoryId}`;
    const observable = this.http.get<CollectionDto<AssignmentDto>>(url, { params: params })
      .pipe(tap(r => this.log.debug('QualificationAssignment: ', r)))
      .pipe(map(c => c.items))

    return firstValueFrom(observable)
  }

  updateQualificationToCategoryAssignment(categoryId: number, qualificationId: number, assigned: boolean) {
    let params = this.httpClientHelper.createHttpParams();
    params = params.append('qualificationId', qualificationId);
    params = params.append('assigned', assigned);

    let url = environment.apiUrl + `/qualifications-assignment/category/${categoryId}`;
    const observable = this.http.put<boolean>(url, null, { params: params })
      .pipe(tap(r => this.log.debug('CategoryAssignment updated: ', r)))

    return firstValueFrom(observable)
  }

}
