import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, map, tap } from 'rxjs';
import { ConsoleLoggerService } from "./console-logger.service";
import { LocationInventoryListItem } from "../ model/location-inventory-list-item.model";
import { ToolInventoryListItem } from '../ model/tool-inventory-list-item.model';
import { ToolInventoryAmount } from '../ model/tool-inventory-amount.model';
import { CantaaErrorHandlerService } from './cantaa-error-handler.service';


@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  getToolInventory(toolId: number) {
    let url = environment.apiUrl + `/inventory/tool/${toolId}`;
    const params = this.httpClientHelper.createHttpParams();
    let observable = this.http.get<CollectionDto<LocationInventoryListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('inventory: ', r)));
    return firstValueFrom(observable);
  }

  getToolLocationInventory(toolLocationId: number) {
    let url = environment.apiUrl + `/inventory/toolLocation/${toolLocationId}`;
    const params = this.httpClientHelper.createHttpParams();
    let observable = this.http.get<CollectionDto<ToolInventoryListItem>>(url, { params: params })
      .pipe(tap(r => this.log.debug('inventory: ', r)))
      .pipe(map(c => c.items))

    return firstValueFrom(observable);
  }

  getInventoryAmountByLocation(toolLocationId: number, toolId: number) {
    let url = environment.apiUrl + `/inventory/toolLocation/${toolLocationId}/amount`;
    const params = this.httpClientHelper.createHttpParams()
      .append('toolId', toolId)

    let observable = this.http.get<ToolInventoryAmount>(url, { params: params })
      .pipe(tap(r => this.log.debug('inventory: ', r)))
      .pipe(map(c => c.amount))

    try {
      return firstValueFrom(observable);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

}
