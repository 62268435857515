<mat-tab-group>
  <mat-tab label="Detail">
    <ng-template matTabContent>
      <wim-transaction-detail></wim-transaction-detail>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'TOOLS' | translate }}">
    <ng-template matTabContent>
      <wim-transaction-detail-tools></wim-transaction-detail-tools>
    </ng-template>
  </mat-tab>
</mat-tab-group>
