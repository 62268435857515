import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CantaaLocalStorageService {

  private readonly currentLang = 'wim-current-lang';
  private readonly currentMarket = 'wim-current-market';

  getLanguage() {
    return localStorage.getItem(this.currentLang);
  }


  setLanguage(lang: string) {
    localStorage.setItem(this.currentLang, lang);
  }

  getMarket() {
    return localStorage.getItem(this.currentMarket);
  }

  setMarket(lang: string) {
    localStorage.setItem(this.currentMarket, lang);
  }

}


