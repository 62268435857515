import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CantaaLocalizationService } from './cantaa-localization.service';

@Injectable({
  providedIn: 'root'
})
export class HttpClientHelper {

  constructor(private cantaaLocalizationService: CantaaLocalizationService) {
  }

  public createHttpParams() {
    return new HttpParams()
      .set('lang', this.cantaaLocalizationService.currentLanguage)
      .set('marketCode', this.cantaaLocalizationService.currentMarket);
  }

}
