import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { SystemInfoService } from '../../../service/system-info.service';
import { SystemInfo } from '../../../ model/system-info.model';

@Component({
  selector: 'wim-system-info',
  standalone: true,
  imports: [
    DatePipe,
    PrimeTemplate,
    TableModule,
    TranslateModule
  ],
  templateUrl: './system-info.component.html',
  styleUrl: './system-info.component.scss'
})
export class SystemInfoComponent implements OnInit {
  systemInfos: SystemInfo[] = [];

  constructor(private systemInfoService: SystemInfoService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  async ngOnInit() {
    this.systemInfos = await this.fetchSystemInfo();
  }

  private async fetchSystemInfo() {
    try {
      return await this.systemInfoService.getSystemInfo();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

}
