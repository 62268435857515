<div class="container highlight-cursor">
  <h2>{{ 'INSPECTION.INSPECTION' | translate }} {{ 'BOOKING.BOOKING' | translate }}</h2>

  <div class="row">
    <div class="col-6">
      <wim-suggestion-input
        id="toolSuggestionInput"
        #toolSuggestionInput
        [label]="'TOOL.TOOL' | translate"
        [fetchSuggestions]="toolFetchSuggestions"
        [displayFn]="DisplayHelper.toolDisplayFn"
        (onOptionSelected)="onToolSelected($event)"/>

    </div>
    <div class="col-1">
      <img class="img-defaultphoto-icon iconw70xh70" id="inspection_tool_icon" src="">
    </div>
    <div class="col-4 mt-3 ms-3">
      <label class="description" for="toolSuggestionInput">{{ selectedTool?.category }}<br>{{ selectedTool?.productName }}</label>
    </div>

    <form [formGroup]="inspectionForm" autocomplete="off">
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>{{ 'INSPECTION.CHOOSE_INSPECTION' | translate }}</mat-label>
            <mat-select id="inspection"
                        class="w-100"
                        formControlName="inspection"
                        (selectionChange)="onInspectionChange()">
              @for (inspection of inspections; track inspection.id) {
                <mat-option [value]="inspection">{{ inspection.name + ' | ' + inspection.dueDateFormatted }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4 mt-3 ms-3">
          <label class="description" for="inspection">{{ inspectionForm.value.inspection?.dueDateFormatted }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>{{ 'OUTCOME.CHOOSE_OUTCOME' | translate }}</mat-label>
            <mat-select class="w-100"
                        formControlName="result"
                        (selectionChange)="onResultChange()">
              @for (result of results; track result) {
                <mat-option [value]="result">{{ result.translateKey | translate }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field id="statusBox" class="w-100">
            <mat-label>{{ 'COMMENT' | translate }}</mat-label>
            <textarea matInput formControlName="comment" rows="7"></textarea>
          </mat-form-field>
        </div>
      </div>

    </form>

  </div>

  <div class="row">
    <div class="col-3 my-2">
      <button mat-raised-button color="primary" class="me-2"
              (click)="onBooking()"
              [disabled]="inspectionForm.pristine || !inspectionForm.valid">
        {{ 'SAVE.SAVE' | translate }}
      </button>

      <button mat-raised-button (click)="onCancel()">
        {{ 'CANCEL' | translate }}
      </button>

    </div>
  </div>
  <wim-test-data>
    test tool: 1235
  </wim-test-data>

  <wim-debug>
    <pre>{{ inspectionForm.value | json }} </pre>
  </wim-debug>

</div>
