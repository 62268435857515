import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { firstValueFrom, map, tap } from 'rxjs';
import { HttpClientHelper } from './http-client-helper.service';
import { ConsoleLoggerService } from './console-logger.service';
import { DropdownItemDto } from '../ model/dropdown-item.model';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient, private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getEmployeesForDropdown() {
    const params = this.httpClientHelper.createHttpParams();
    let url = `${environment.apiUrl}/employees/dropdown`;
    const observable = this.http.get<CollectionDto<DropdownItemDto>>(url, { params: params })
      .pipe(tap(r => this.log.debug('employees for dropdown: ', r)))
      .pipe(map(c => c.items));

    return firstValueFrom(observable);
  }


}
