<div class="mx-3">
  <p-table [value]="assignments()"
           [tableStyle]="{ 'width': 'max-content' }"
           [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
           styleClass="p-datatable-sm"
  >
    <ng-template pTemplate="header" class="">
      <tr>
        <th class="p-dummy-element">{{ title() | translate }}</th>
        <th class="p-dummy-element">{{ 'ASSIGNED	' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-assignment>
      <tr>
        <td class="p-dummy-element">{{ assignment.name }}</td>
        <td class="p-dummy-element">
          <mat-checkbox [(ngModel)]="assignment.assigned"
                        [color]="'primary'"
                        (ngModelChange)="updateAssignment(assignment)">
          </mat-checkbox>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
