<mat-toolbar class="toolbar">
  <button mat-icon-button class="" aria-label="burger menu"
  (click)="toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <a routerLink="" class="logo">
    <img ngSrc="assets/img/header-logo.png" alt="Logo" class="logo" height="50" width="50"/>
  </a>

  <span class="spacer"></span>

  <wim-language-market-selector class="mt-3 me-2"></wim-language-market-selector>
  &nbsp;
  <button mat-button routerLink="logout" matTooltip="Log out">
    <mat-icon>logout</mat-icon>
    {{ userName }}
  </button>
  &nbsp;
  <img class="img-defaultphoto-icon" id="img_toolbar_loggedUser" src="">

</mat-toolbar>
