import {AfterViewInit, Component, ElementRef, inject, viewChild} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Table, TableLazyLoadEvent, TableModule} from 'primeng/table';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {GridHelperService} from '../../../service/helper/grid-helper.service';
import {FocusHelper} from '../../../service/helper/focus-helper';
import {GridRequest} from '../../../ model/grid-request.model';
import {CostCenterListItem} from '../../../ model/cost-center-list-item.model';
import {CostCenterService} from '../../../service/cost-center.service';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {CantaaGridMeta} from "../../../ model/GridMeta";
import {GridMetaService} from "../../../service/grid-meta.service";

@Component({
  selector: 'wim-cost-center-grid',
  standalone: true,
  templateUrl: './cost-center-grid.component.html',
  styleUrl: './cost-center-grid.component.scss',
  imports: [
    TableModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatIconModule,
    MatIconButton,
    HasPermissionDirective
  ],
})
export class CostCenterGridComponent implements AfterViewInit {
  dataTable = viewChild.required<Table>('dt');
  protected readonly Permission = Permission;
  readonly gridName = 'COST_CENTER_GRID';

  private gridMetaService = inject(GridMetaService);
  private fb = inject(FormBuilder);
  private costCenterService = inject(CostCenterService);
  private messageService = inject(CantaaMessageService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private el = inject(ElementRef);
  private gridHelperService = inject(GridHelperService);

  costCenters: CostCenterListItem[] = [];
  public totalRecords: number = 0;
  editMode = false;

  createNewForm = this.fb.group({
    number: ['', Validators.required],
    name: ['', Validators.required],
  });

  clonedItems: { [s: number]: CostCenterListItem } = {};
  gridMeta!: CantaaGridMeta;

  constructor() {
    this.initGridMeta();
  }

  private initGridMeta() {
    const defaultSortMeta = [{field: 'name', order: 1}];
    this.gridMeta = this.gridMetaService.getGridMeta(this.gridName, defaultSortMeta);
  }

  async ngAfterViewInit() {
    await this.reloadData();
  }

  onRowEditInit(edit: CostCenterListItem) {
    this.clonedItems[edit.id] = { ...edit };
    this.editMode = true;
  }

  async onRowEditSave(costCenterListItem: CostCenterListItem) {
    await this.save(costCenterListItem);
    this.editMode = false;
    await this.reloadData();
  }

  private async save(item: CostCenterListItem) {
    try {
      await this.costCenterService.saveCostCenter(item);
      this.messageService.success('SAVE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, 'SAVE.FAILED');
    }
  }

  onRowEditCancel(item: CostCenterListItem, index: number) {
    this.costCenters[index] = this.clonedItems[item.id];
    delete this.clonedItems[item.id];
    this.editMode = false;
  }

  async onCreateNew() {
    const item = {
      number: this.createNewForm.value.number,
      name: this.createNewForm.value.name
    } as CostCenterListItem;

    await this.save(item);
    this.createNewForm.reset();
    await this.reloadData();
    FocusHelper.focusFormField(this.el, 'number');
  }

  onCancel() {
    this.createNewForm.reset();
  }

  async onUpdateStatus(item: CostCenterListItem) {
    try {
      await this.costCenterService.updateCostCentersStatus(item.id, !item.active);
      this.messageService.success('UPDATE.SUCCESS');
      await this.reloadData();
    } catch (e) {
      this.errorHandler.handleError(e, 'UPDATE.FAILED');
    }
  }

  async fetchDataViaTableComponent(lazyLoadMeta: TableLazyLoadEvent) {
    this.gridMetaService.setGridMeta(this.gridName, {lazyLoadMeta});
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMeta);
    await this.fetchData(gridRequest);
  }

  async reloadData() {
    let lazyLoadMetadata = this.dataTable().createLazyLoadMetadata();
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMetadata);
    await this.fetchData(gridRequest);
  }

  async fetchData(gridRequest: GridRequest) {
    try {
      const collection = await this.costCenterService.getPagedCostCenters(gridRequest);
      this.costCenters = collection.items;
      this.totalRecords = collection.totalNumberOfItems;
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
    }
  }

  getTdClass(item: any) {
    return item.active ? "" : 'strikethrough';
  }

}
