import { Component, OnInit } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { DispositionService } from '../../../service/disposition.service';
import { DispositionListItem } from '../../../ model/disposition-list-item.model';
import { DatePipe } from '@angular/common';
import { ToolCategoryService } from '../../../service/tool-category.service';
import { ToolCategoryDetailItem } from '../../../ model/tool-category-detail.model';
import { CategoryConstants } from '../../../constants/category-constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wim-tool-category-disposition-grid',
  standalone: true,
  imports: [
    PrimeTemplate,
    TableModule,
    TranslateModule,
    DatePipe
  ],
  templateUrl: './tool-category-disposition-grid.component.html',
  styleUrl: './tool-category-disposition-grid.component.scss'
})
export class ToolCategoryDispositionGridComponent implements OnInit {
  categoryDetail: ToolCategoryDetailItem | null = null;
  dispositions: DispositionListItem[] = [];

  constructor(private dispositionService: DispositionService,
              private errorHandler: CantaaErrorHandlerService,
              private toolCategoryService: ToolCategoryService,
              private route: ActivatedRoute) {
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      const categoryId = Number(params.get('id'));
      this.categoryDetail = await this.toolCategoryService.getToolCategoryDetail(categoryId);
      this.dispositions = await this.fetchDispositionsByCategory(categoryId);
    });
  }

  private async fetchDispositionsByCategory(categoryId: number) {
    try {
      return await this.dispositionService.getDispositionsByCategory(categoryId);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  isSubCategoryNameVisible() {
    return this.categoryDetail?.levelType === CategoryConstants.LEVEL_TYPE.MAIN_GROUP;
  }

  isToolCategoryName() {
    return this.categoryDetail?.levelType === CategoryConstants.LEVEL_TYPE.MAIN_GROUP
      || this.categoryDetail?.levelType === CategoryConstants.LEVEL_TYPE.SUBGROUP;
  }

}
