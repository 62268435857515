<mat-tab-group>
  <mat-tab label="{{ 'DETAIL' | translate }}">
    <ng-template matTabContent>
      <wim-location-detail></wim-location-detail>
    </ng-template>
  </mat-tab>
    <mat-tab label="{{ 'DOCUMENTS.DOCUMENTS' | translate }}">
    <ng-template matTabContent>
      <wim-tool-location-documents></wim-tool-location-documents>
    </ng-template>
  </mat-tab>
    <mat-tab label="{{ 'INVENTORY.INVENTORY' | translate }}">
    <ng-template matTabContent>
      <wim-tool-category-inventory></wim-tool-category-inventory>
    </ng-template>
  </mat-tab>
  @if (visibleTabs?.visibleTabs?.includes('qualifications')) {
    <mat-tab label="{{ 'QUALIFICATIONS' | translate }}">
      <ng-template matTabContent>
        <wim-qualification-assignment-location></wim-qualification-assignment-location>
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>
