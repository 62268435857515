<div class="container " style="min-height: 80vh">
  <h2>WIM</h2>
  <p>FE version: {{ frontendAppVersion }}</p>
  <p>BE version: {{ backendAppInfo?.version }}</p>

  <ul>
    @for (item of backendAppInfo?.meta | keyvalue; track item.key) {
      <li>{{ item.value }}</li>
    } @empty {
      <li>There are no items.</li>
    }
  </ul>

</div>

