<div class="container">
  <form [formGroup]="detailForm" (ngSubmit)="onSave()">
    <input hidden formControlName="jobId">

    <div class="row">

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'NAME' | translate }}</mat-label>
          <input matInput formControlName="jobName">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-9">
        <mat-form-field class="w-100">
          <mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
          <input matInput formControlName="jobDescription">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-9">
        <mat-checkbox formControlName="enabled" color='primary'>
          {{ 'ENABLED' | translate }}
        </mat-checkbox>
      </div>

    </div>

    <div class="row">

      <div class="col-4">
        <mat-form-field class="w-100 mb-4">
          <mat-label>{{ 'CRON' | translate }}</mat-label>
          <input matInput formControlName="cron">
          <mat-hint class="multiline-hint">second minute hour month week-day<br>
            For example to run every day at 23:15: <b>0 15 23 * * *</b></mat-hint>
        </mat-form-field>
      </div>

      <button mat-icon-button type="button" class=""
              title="What is cron job?"
              (click)="openLinkInNewTab('https://docs.spring.io/spring-framework/docs/current/javadoc-api/org/springframework/scheduling/support/CronExpression.html')">
        <mat-icon>help</mat-icon>
      </button>

    </div>

    <div class="row">
      <div class="col-4">
        <button type="submit" mat-raised-button color="primary" [disabled]="!detailForm.valid" class="me-2">
          {{ 'SAVE.SAVE' | translate }}
        </button>
        <button type="button" mat-raised-button (click)="onCancel()">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>

  </form>

</div>
