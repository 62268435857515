import {Component, inject} from '@angular/core';
import {SharedModule} from 'primeng/api';
import {TableLazyLoadEvent, TableModule} from 'primeng/table';
import {ToolService} from '../../../service/tool.service';
import {TreeHelperService} from '../../../service/helper/tree-helper.service';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {NodeType} from '../../../constants/node-type';
import {ToolListItem} from '../../../ model/tool-list-item.model';
import {WimIcons} from '../../../constants/wim-icons';
import {GridRequest} from '../../../ model/grid-request.model';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {RouteType} from '../../../constants/route-type';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {ToolDocumentService} from "../../../service/tool-document.service";
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {MatListItem} from '@angular/material/list';
import {TranslateModule} from "@ngx-translate/core";
import {UuidHelperService} from '../../../service/helper/uuid-helper.service';
import {BaseNodeGridComponent} from '../../base-components/base-node-grid.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {SearchInputComponent} from '../../inputs/search-input/search-input.component';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-tool-grid',
  standalone: true,
  imports: [
    SharedModule,
    TableModule,
    MatButton,
    MatIcon,
    HasPermissionDirective,
    MatListItem,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconButton,
    FormsModule,
    SearchInputComponent,
    ShowIfDebugMode,
  ],
  templateUrl: './tool-grid.component.html',
  styleUrl: './tool-grid.component.scss'
})
export class ToolGrid extends BaseNodeGridComponent<ToolListItem> {
  protected readonly Permission = Permission;
  protected override nodeType = NodeType.TOOL_RELATION;

  private toolService = inject(ToolService);
  private treeHelperService = inject(TreeHelperService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private messageService = inject(CantaaMessageService);
  public toolDocumentService = inject(ToolDocumentService);

  override async fetchData(selectedNode: WimTreeNode, lazyLoadMetadata: TableLazyLoadEvent) {
    await super.fetchData(selectedNode, lazyLoadMetadata);

    this.gridItems.forEach(tool => {
      this.toolDocumentService.getDefaultPhotoScaled35x35AndRender(tool.id);
    });
  }

  override async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode) {
    try {
      return await this.toolService.getTools(gridRequest, selectedNode);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  override mapTreeItems(selectedNode: WimTreeNode, items: ToolListItem[]): WimTreeNode[] {
    return items
      .map<WimTreeNode>(t => {

          let treeNode = {
            id: t.id,
            key: UuidHelperService.generateUuid(),
            label: (t.category !== null && t.category !== undefined && t.category.trim() !== '') ? `${t.category} ${t.productName}` : t.productName,
            data: t.category,
            icon: WimIcons.TOOL_ICON,
            nodeType: NodeType.TOOL,
            route: RouteType.TOOL_ALL,
            children: [] as WimTreeNode[],
            leaf: false,
            parent: selectedNode
          };

        t.treeNode = treeNode;

        treeNode.children = [this.treeHelperService.createToolLocationRelationsTreeItem(treeNode),
          this.treeHelperService.createTransactionRelationsTreeItem(treeNode)];

        return treeNode;
      });
  }

  async delete() {
    try {
      await this.toolService.deleteTool(this.selectedItem!);
      this.messageService.success('DELETE.SUCCESS');
      await this.reFetchData();
    } catch (e) {
      throw this.errorHandler.handleError(e, 'DELETE.FAILED');
    }
  }

}
