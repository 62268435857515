import {Component, OnInit, viewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToolLocationService} from '../../../service/tool-location.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {BookMobileLocationDto} from '../../../ model/booking.models';
import {ToolLocationDetail} from '../../../ model/tool-location-detail.model';
import {TransactionService} from '../../../service/transaction.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {ToolLocationDocumentService} from "../../../service/tool-location-document.service";
import {SuggestionInputComponent} from '../../inputs/suggestion-input/suggestion-input.component';
import {ToolLocationListItem} from '../../../ model/tool-location-list-item.model';
import {DisplayHelper} from '../../../service/helper/DisplayHelper';

@Component({
  selector: 'wim-location-booking',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    TranslateModule,
    SuggestionInputComponent
  ],
  templateUrl: './mobile-location-booking.component.html',
  styleUrl: './mobile-location-booking.component.scss'
})
export class MobileLocationBookingComponent implements OnInit {
  protected readonly DisplayHelper = DisplayHelper;
  employeeSuggestionInput = viewChild.required<SuggestionInputComponent<ToolLocationListItem>>('employeeSuggestionInput');
  mobileLocationSuggestionInput = viewChild.required<SuggestionInputComponent<ToolLocationListItem>>('mobileLocationSuggestionInput');

  selectedEmployee: ToolLocationDetail | null = null;
  selectedMobileLocation: ToolLocationDetail | null = null;

  statusText = '';

  constructor(private toolLocationService: ToolLocationService,
              private transactionService: TransactionService,
              private messageService: CantaaMessageService,
              private toolLocationDocumentService: ToolLocationDocumentService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  ngOnInit(): void {
    this.employeeSuggestionInput().focus();
  }

  async onEmployeeSelected(toolLocationListItem: ToolLocationListItem) {
    try {
      this.selectedEmployee = await this.toolLocationService.getToolLocationDetailById(toolLocationListItem.id, false);
      this.messageService.success('EMPLOYEE.FOUND');
      this.mobileLocationSuggestionInput().focus();
      this.toolLocationDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(this.selectedEmployee.id, 'mobilelocationbooking_employee_icon');
    } catch (e) {
      const error = this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      this.addMessageToStatus('error: ' + error);
      this.selectedEmployee = null;
    }
  }

  async onMobileLocationSelected(toolLocationListItem: ToolLocationListItem) {
    try {
      this.selectedMobileLocation = await this.toolLocationService.getToolLocationDetailById(toolLocationListItem.id, false);
      this.messageService.success('MOBILE_LOCATION.FOUND');
      this.toolLocationDocumentService.getDefaultPhotoScaled70x70_2ImgEleId(this.selectedMobileLocation.id, 'mobilelocationbooking_mobilelocation_icon');
    } catch (e) {
      const error = this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      this.addMessageToStatus('error: ' + error);
      this.selectedMobileLocation = null;
      throw e;
    }

    await this.bookMobileLocation();
  }

  async bookMobileLocation() {
    const bookLocationDto = {
      transactionTypeCode: "HandoverLocation",
      toLocationId: this.selectedEmployee?.id,
      mobileLocationId: this.selectedMobileLocation?.id
    } as BookMobileLocationDto;

    await this.book(bookLocationDto);

    this.messageService.success('BOOKING.SUCCESSFUL');

    const statusText = this.createStatusText();
    this.addMessageToStatus(statusText);

    this.employeeSuggestionInput().clear();
    this.mobileLocationSuggestionInput().clear();

    this.employeeSuggestionInput().focus();
  }

  private async book(bookLocationDto: BookMobileLocationDto) {
    try {
      return await this.transactionService.makeBooking(bookLocationDto, false)
    } catch (e) {
      const error = this.errorHandler.handleError(e, 'BOOKING.FAILED');
      this.addMessageToStatus('error: ' + error);
      throw e;
    }
  }

  createStatusText(): string {
    return this.selectedMobileLocation?.locationPretty + ' -> ' + this.selectedEmployee?.locationPretty;
  }

  addMessageToStatus(msg: string) {
    this.statusText = msg + '\n' + this.statusText;
  }

  employeeFetchSuggestions = async (query: string): Promise<ToolLocationListItem[]> => {
    return await this.toolLocationService.searchLocations(query);
  };

  mobileLocationFetchSuggestions = async (query: string): Promise<ToolLocationListItem[]> => {
    return await this.toolLocationService.searchLocations(query, true);
  };

}
