<div>
  <mat-form-field class="the-dense-zone-5 language-selector">
    <mat-label>language</mat-label>
    <mat-select [(value)]="selectedLanguage" (selectionChange)="onLanguageChange($event)">
      @for (language of languages; track language) {
        <mat-option [value]="language">{{ language }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (false) {
    <mat-form-field class="the-dense-zone-5 market-selector">
      <mat-label>market</mat-label>
      <mat-select [(value)]="selectedMarket" (selectionChange)="onMarketChange($event)">
        @for (market of markets; track market) {
          <mat-option [value]="market">{{ market }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
</div>
