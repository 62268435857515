<!-- https://fonts.google.com/icons -->

<mat-accordion>

  <mat-expansion-panel [expanded]="isExplorer">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Navigation
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-nav-list>

      <a mat-list-item [routerLink]="'explorer/' + RouteType.TOOL" *hasPermission="Permission.TOOL_READ">
        <mat-icon matListItemIcon>construction</mat-icon>
        <span routerLinkActive="active">{{ 'TOOLS' | translate }}</span>
      </a>

      <a mat-list-item [routerLink]="'explorer/' + RouteType.LOCATION" *hasPermission="Permission.LOCATION_READ">
        <mat-icon matListItemIcon>location_city</mat-icon>
        <span routerLinkActive="active">{{ 'LOCATIONS.LOCATIONS' | translate }}</span>
      </a>
      <a mat-list-item [routerLink]="'explorer/' + RouteType.CATEGORY" *hasPermission="Permission.CATEGORY_READ">
        <mat-icon matListItemIcon>account_tree</mat-icon>
        <span routerLinkActive="active">{{ 'CATEGORIES.CATEGORIES' | translate }}</span>
      </a>
      <a mat-list-item [routerLink]="'explorer/' + RouteType.TRANSACTION"
         *hasPermission="Permission.TRANSACTION_READ">
        <mat-icon matListItemIcon>sync_alt</mat-icon>
        <span routerLinkActive="active">{{ 'TRANSACTIONS' | translate }}</span>
      </a>
      <a mat-list-item [routerLink]="'explorer/' + RouteType.INSPECTION_TYPE"
         *hasPermission="Permission.INSPECTION_TYPE_READ">
        <mat-icon matListItemIcon>warning</mat-icon>
        <span routerLinkActive="active">{{ 'INSPECTIONTYPES.INSPECTIONTYPES' | translate }}</span>
      </a>
      <a mat-list-item [routerLink]="'explorer/' + RouteType.DOCUMENT"
         *hasPermission="Permission.DOCUMENT_READ">
        <mat-icon matListItemIcon>content_paste</mat-icon>
        <span routerLinkActive="active">{{ 'DOCUMENTS.DOCUMENTS' | translate }}</span>
      </a>
      <a mat-list-item [routerLink]="'explorer/' + RouteType.RESERVATION"
         *hasPermission="Permission.RESERVATION_READ">
        <mat-icon matListItemIcon>note_alt</mat-icon>
        <span routerLinkActive="active">{{ 'RESERVATION.RESERVATION' | translate }}</span>
      </a>
    </mat-nav-list>

  </mat-expansion-panel>

  <mat-expansion-panel *hasPermission="Permission.TABLE_READ" [expanded]="isTable">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'TABLES' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-nav-list>
      <a mat-list-item routerLink="table/manufacturer-grid">
        <mat-icon matListItemIcon>factory</mat-icon>
        <span routerLinkActive="active">{{ 'MANUFACTURERS' | translate }}</span>
      </a>

      <a mat-list-item routerLink="table/tool-location-type-grid">
        <mat-icon matListItemIcon>location_city</mat-icon>
        <span routerLinkActive="active">{{ 'LOCATIONTYPES' | translate }}</span>
      </a>

      <a mat-list-item routerLink="table/document-type-grid">
        <mat-icon matListItemIcon>description</mat-icon>
        <span routerLinkActive="active">{{ 'DOCUMENTTYPES' | translate }}</span>
      </a>

      <a mat-list-item routerLink="table/cost-center-grid">
        <mat-icon matListItemIcon>payments</mat-icon>
        <span routerLinkActive="active">{{ 'COSTCENTERS' | translate }}</span>
      </a>

      <a mat-list-item routerLink="table/qualification-grid">
        <mat-icon matListItemIcon>scatter_plot</mat-icon>
        <span routerLinkActive="active">{{ 'QUALIFICATIONS' | translate }}</span>
      </a>
    </mat-nav-list>

  </mat-expansion-panel>

  <mat-expansion-panel *hasPermission="Permission.BOOKING_MANAGER" [expanded]="isBooking">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'POSTING' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-nav-list>
      <a mat-list-item routerLink="/booking/tool">
        <mat-icon matListItemIcon>collections_bookmark</mat-icon>
        <span routerLinkActive="active">{{ 'TOOLS' | translate }}</span>
      </a>

      <a mat-list-item routerLink="/booking/location">
        <mat-icon matListItemIcon>cases</mat-icon>
        <span routerLinkActive="active">{{ 'MOVECONTAINER' | translate }}</span>
      </a>
      <a mat-list-item routerLink="/booking/inspection">
        <mat-icon matListItemIcon>task</mat-icon>
        <span routerLinkActive="active">{{ 'TOOL_INSPECTION_BOOKING' | translate }}</span>
      </a>
      <a mat-list-item routerLink="/booking/reservation">
        <mat-icon matListItemIcon>note_alt</mat-icon>
        <span routerLinkActive="active">{{ 'RESERVATION.RESERVATION' | translate }}</span>
      </a>
    </mat-nav-list>

  </mat-expansion-panel>

  <mat-expansion-panel *hasAnyPermission="[Permission.SYSTEM_INFO, Permission.SYSTEM_JOB, Permission.SYSTEM_SETTINGS]"
                       [expanded]="isAdmin">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'ADMIN' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-nav-list *hasPermission="Permission.SYSTEM_INFO">
      <a mat-list-item routerLink="/admin/system-info">
        <mat-icon matListItemIcon>computer</mat-icon>
        <span routerLinkActive="active">{{ 'SYSTEM.INFO' | translate }}</span>
      </a>
    </mat-nav-list>

    <mat-nav-list *hasPermission="Permission.SYSTEM_JOB">
      <a mat-list-item routerLink="/admin/system-jobs">
        <mat-icon matListItemIcon>task_alt</mat-icon>
        <span routerLinkActive="active">{{ 'SYSTEM.JOB.JOB' | translate }}</span>
      </a>
    </mat-nav-list>

    <mat-nav-list *hasPermission="Permission.SYSTEM_SETTINGS">
      <a mat-list-item routerLink="/admin/system-settings">
        <mat-icon matListItemIcon>settings</mat-icon>
        <span routerLinkActive="active">{{ 'SYSTEM.SETTINGS' | translate }}</span>
      </a>
    </mat-nav-list>

  </mat-expansion-panel>

</mat-accordion>

<mat-nav-list>
  <a mat-list-item routerLink="about">
    <mat-icon matListItemIcon>info</mat-icon>
    <span routerLinkActive="active">{{ 'ABOUT' | translate }}</span>
  </a>

  <a mat-list-item routerLink="profile">
    <mat-icon matListItemIcon>person</mat-icon>
    <span routerLinkActive="active">{{ 'PROFILE' | translate }}</span>
  </a>
  <a mat-list-item routerLink="test" *hasPermission="Permission.DEV_MODE">
    <mat-icon matListItemIcon>bug_report</mat-icon>
    <span routerLinkActive="active">Test page</span>
  </a>

  <a mat-list-item routerLink="test-search" *hasPermission="Permission.DEV_MODE">
    <mat-icon matListItemIcon>bug_report</mat-icon>
    <span routerLinkActive="active">Test search page</span>
  </a>
</mat-nav-list>


