import { ElementRef } from '@angular/core';


export class FocusHelper {

  public static focusFormField(el: ElementRef, key: string, timeout = 5) {
    setTimeout(() => FocusHelper.focusFormFieldImpl(el, key), timeout);
  }

  public static focusFormFieldLongTimeout(el: ElementRef, key: string) {
    setTimeout(() => FocusHelper.focusFormFieldImpl(el, key), 200);
  }

  private static focusFormFieldImpl(el: ElementRef, key: string) {
    const selectedControl = el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
    console.log('selectedControlForFocus', selectedControl);
    selectedControl.focus();
  }

  public static focusElementById(el: ElementRef, key: string) {
    setTimeout(() => FocusHelper.focusElementByIdImpl(el, key), 5);
  }

  private static focusElementByIdImpl(el: ElementRef, key: string) {
    const selectedControl = el.nativeElement.querySelector('#' + key);
    console.log('selectedControlForFocus', selectedControl);
    selectedControl.focus();
  }

  public static focusElementByClass(el: ElementRef, key: string) {
    setTimeout(() => FocusHelper.focusElementByClassImpl(el, key), 5);
  }

  private static focusElementByClassImpl(el: ElementRef, key: string) {
    const selectedControl = el.nativeElement.querySelector('.' + key);
    console.log('selectedControlForFocus', selectedControl);
    selectedControl.focus();
  }

}
