<div class="container " style="min-height: 80vh">
  <h2>Test page</h2>
  <div class="row">
    <div class="col-3">
      <div>
        <button (click)="toastTest()" mat-raised-button color="primary">Toast test</button>
      </div>
    </div>
    <div class="col-3">
      <div>
        <button (click)="loadingOn()" mat-raised-button color="primary">Loading on</button>
      </div>
    </div>
  </div>

  <hr class="mt-5 mb-5"/>

  <p>Here is button visible only for user with role admin:</p>
  <div *hasPermission="'admin'">
    <button mat-raised-button color="primary">Admin button</button>
  </div>

  <hr class="mt-5 mb-5"/>

  <p> Example of client validation by field:</p>
  <form [formGroup]="detailForm" (ngSubmit)="onSave()">

    <div class="row">
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <input id="name" matInput formControlName="name">
          <mat-hint>Required and 3-6 characters</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>serialNumber</mat-label>
          <input id="serialNumber" matInput formControlName="serialNumber">
          <mat-hint>match format 123-456 (regex)</mat-hint>
        </mat-form-field>
      </div>

    </div>

  </form>

  <div class="mt-5 mb-5"></div>

  <p> Example of async validation:</p>
  <p>
    When we leave RFID input, request is made to backend and check if RFID already exists.
    E.g. a4130408019016e0
  </p>
  <form [formGroup]="detailForm2" (ngSubmit)="onSave()">

    <div class="row">
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>RFID</mat-label>
          <input id="rfid" matInput formControlName="rfid">
          <mat-error *ngIf="detailForm2.get('rfid')?.errors?.['required']">RFID is required</mat-error>
          <mat-error *ngIf="detailForm2.get('rfid')?.errors?.['invalidRfid']">RFID is not valid</mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Note</mat-label>
          <input id="note" matInput formControlName="note">
        </mat-form-field>
      </div>
    </div>

  </form>

  <div class="mt-5 mb-5"></div>

  <p> Example of validation in BE:</p>
  <p> rfid - a4130408019016e0 already exists</p>
  <p> name - must not contains question mark </p>

  <form [formGroup]="detailForm3" (ngSubmit)="onSave()">

    <div class="row">
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>RFID</mat-label>
          <input id="rfid2" matInput formControlName="rfid2">
          <mat-error *ngIf="detailForm3.get('rfid2')?.errors">
            {{ detailForm3.get('rfid2')?.errors?.['serverError'] }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <input id="name2" matInput formControlName="name2">

          <!--          <mat-error *ngIf="detailForm3.get('name2')?.errors">-->

          <mat-error>{{ FormErrorHelper.getErrorMessage(detailForm3.controls.name2) }}</mat-error>


          <!--          @if (detailForm3.controls.name2.hasError('serverError')) {-->
          <!--            &lt;!&ndash;          @if (detailForm3.controls.name2.hasError('serverError')) {&ndash;&gt;-->
          <!--            <mat-error>-->
          <!--              {{ detailForm3.controls.name2.errors?.['serverError'] }}-->
          <!--            </mat-error>-->
          <!--          }-->

        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <button type="submit" mat-raised-button color="primary" [disabled]="detailForm3.pristine || !detailForm3.valid" class="m-1">
          Save
        </button>
        <!--        <button type="submit" mat-raised-button color="primary" class="m-1">Save</button>-->
        <button type="button" mat-raised-button [disabled]="detailForm3.pristine" (click)="onCancel()">Cancel</button>
      </div>
    </div>

  </form>

  @if (false) {
    <div class="row">
      <div class="col-12">
      <pre>
        {{ detailForm3.get('rfid2')?.errors | json }}
        {{ detailForm3.get('name2')?.errors | json }}
        {{ detailForm3.valid }}
      </pre>
      </div>
    </div>
  }

</div>

<hr>
<h2>Profile Rohdaten:</h2>
@if (loggedUser$ | async; as loggedUser) {
  <pre>{{ loggedUser | json }} </pre>
}
