export const RouteType = {
  TOOLS: 'tools',
  TOOL: 'tool',
  TOOL_ALL: 'all-tools',
  TOOL_INSPECTABLE: 'inspectable-tools',
  TRANSACTIONS: 'transactions',
  TRANSACTION: 'transaction',
  LOCATIONS: 'locations',
  LOCATION: 'location',
  CATEGORIES: 'categories',
  CATEGORY: 'category',
  INSPECTION_TYPES: 'inspection-types',
  INSPECTION_TYPE: 'inspection-type',
  DOCUMENTS: 'documents',
  DOCUMENT: 'document',
  RESERVATIONS: 'reservations',
  RESERVATION: 'reservation',
};
