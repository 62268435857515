import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridRequest } from '../ model/grid-request.model';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { WimTreeNode } from '../ model/wim-tree.node';
import { NodeType } from '../constants/node-type';
import { TransactionListItem } from '../ model/transaction-list-item.model';
import { TransactionDetail } from '../ model/transaction-detail.model';
import { firstValueFrom, map, tap } from 'rxjs';
import { HttpClientHelper } from './http-client-helper.service';
import {
  BookMobileLocationDto,
  BookToolDto,
  InspectionBookingDto,
  ReservationBookingDto,
  ReservationBookingValidateItemDto,
  ToolBookingValidateItemDto
} from '../ model/booking.models';
import { ConsoleLoggerService } from './console-logger.service';
import { CantaaErrorHandlerService } from './cantaa-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService,) {
  }

  getTransactions(request: GridRequest, selectedNode: WimTreeNode) {
    let url = this.createUrl(selectedNode);
    const params = this.httpClientHelper.createHttpParams();
    const observable = this.http.post<CollectionDto<TransactionListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('Fetched transactions: ', r)));

    return firstValueFrom(observable);
  }

  private createUrl(selectedNode: WimTreeNode) {
    return `${environment.apiUrl}${this.getUrl(selectedNode)}`;
  }

  private getUrl(selectedNode: WimTreeNode): string {
    const parent = (selectedNode.parent as WimTreeNode);
    const parentNodeType = parent?.nodeType;

    switch (parentNodeType) {
      case undefined:
        return `/transactions`;
      case NodeType.TOOL:
        return `/transactions/tool/${parent.id}`;
      case NodeType.TOOL_LOCATION:
        return `/transactions/location/${parent.id}`;
      default:
        throw new Error("not Implemented");
    }
  }

  getTransactionDetail(transactionId: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/transactions/${transactionId}`;
    const observable = this.http.get<TransactionDetail>(url, { params: params })
      .pipe(tap(r => this.log.debug('transaction detail: ', r)));

    return firstValueFrom(observable);
  }

  async makeBooking(booking: BookToolDto | BookMobileLocationDto, handleError = true) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/transactions/book`;
    const observable = this.http.post<TransactionDetail>(url, booking, { params: params })
      .pipe(tap(r => this.log.debug('booking response: ', r)));

    try {
      return await firstValueFrom(observable);
    } catch (e) {
      if (handleError) {
        this.errorHandler.handleError(e, 'BOOKING.FAILED');
      }
      throw e;
    }
  }

  makeReservationBooking(booking: ReservationBookingDto) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/transactions/book-reservation`;
    const observable = this.http.post<TransactionDetail>(url, booking, { params: params })
      .pipe(tap(r => this.log.debug('booking response: ', r)));

    return firstValueFrom(observable);
  }

  makeInspectionBooking(booking: InspectionBookingDto) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/transactions/book-inspection`;
    const observable = this.http.post<number>(url, booking, { params: params })
      .pipe(tap(r => this.log.debug('Inspection booked in transaction: ', r)));

    return firstValueFrom(observable);
  }

  async validateToolBooking(booking: ToolBookingValidateItemDto, transactionTypeCode: string) {
    let params = this.httpClientHelper.createHttpParams();
    params = params.append('transactionTypeCode', transactionTypeCode);

    let url = environment.apiUrl + `/transactions/validate/tool-booking`;
    const observable = this.http.post<CollectionDto<string>>(url, booking, { params: params })
      .pipe(tap(r => this.log.debug('validate tool booking response: ', r)))
      // .pipe(map(c => c.items ? c.items.toString() : null))
      .pipe(map(c => c.items))

    try {
      return await firstValueFrom(observable);
    } catch (e) {
      this.errorHandler.handleError(e, 'VALIDATION.FAILED');
      throw e;
    }
  }

  validateReservationBooking(booking: ReservationBookingValidateItemDto) {
    let params = this.httpClientHelper.createHttpParams();

    let url = environment.apiUrl + `/transactions/validate/reservation-booking`;
    const observable = this.http.post<CollectionDto<string>>(url, booking, { params: params })
      .pipe(tap(r => this.log.debug('validate reservation response: ', r)))
      .pipe(map(c => c.items ? c.items.toString() : null))

    return firstValueFrom(observable);
  }

}
