import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionDto } from '../ model/collection.model';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import {firstValueFrom, map, tap} from 'rxjs';
import {Document} from "../ model/document.models";
import {ConsoleLoggerService} from "./console-logger.service";
import {CantaaLocalizationService} from "./cantaa-localization.service";
import {GridRequest} from "../ model/grid-request.model";
import {WimTreeNode} from "../ model/wim-tree.node";
import {DocumentListItem} from "../ model/document-list-item.model";
import {NodeType} from "../constants/node-type";
import {InspectionTypeDetail} from "../ model/inspection-type.detail";
import {DocumentDetail} from "../ model/document.detail";


@Injectable({
  providedIn: 'root'
})
export class DocumentService {


  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService,
              private cantaaLocalizationService: CantaaLocalizationService) {
  }

  getBlobUrl4DocumentId(documentId: number){
    // let url = 'environment.apiUrl + `/documents/blob/' + documentId;
    // return url;
  }

  getDocuments(request: GridRequest, selectedNode: WimTreeNode) {
    let url = this.createUrl(selectedNode);
    const params = this.httpClientHelper.createHttpParams();
    const observable = this.http.post<CollectionDto<DocumentListItem>>(url, request, { params: params })
      .pipe(tap(r => this.log.debug('Fetched documents: ', r)));
    return firstValueFrom(observable);
  }

  private createUrl(selectedNode: WimTreeNode) {
    return `${environment.apiUrl}${this.getUrl(selectedNode)}`;
  }

  private getUrl(selectedNode: WimTreeNode): string {
    const parent = (selectedNode.parent as WimTreeNode);
    const parentNodeType = parent?.nodeType;

    switch (parentNodeType) {
      case undefined:
        return `/documents`;
      case NodeType.TOOL:
        return `/documents/tool/${parent.id}`;
      case NodeType.TOOL_LOCATION:
        return `/documents/location/${parent.id}`;
      default:
        throw new Error("not Implemented 4 nodetype : " + parentNodeType);
    }
  }

  getDocumentDetail(id: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/documents/${id}`;
    const observable = this.http.get<DocumentDetail>(url, { params: params })
      .pipe(tap(r => this.log.debug('document: ', r)));
    return firstValueFrom(observable);
  }

  saveDocument(detail: DocumentDetail) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/documents/save`;
    let observable = this.http.post<number>(url, detail, { params: params })
      .pipe(tap(r => this.log.debug('saved: ', r)))

    return firstValueFrom(observable)
  }


  // getAll() {
  //   const params = this.httpClientHelper.createHttpParams();
  //   let url = environment.apiUrl + `/document`;
  //   return firstValueFrom(this.http.get<CollectionDto<Document>>(url, { params: params })
  //     .pipe(tap(r => this.log.debug('document: ', r))));
  // }

  getAllExceptAllreadyAssignedToToolId(toolid: number) {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/documents/exceptallreadyassignedto/toolid/` + toolid;
    return firstValueFrom(this.http.get<CollectionDto<Document>>(url, { params: params })
      .pipe(tap(r => this.log.debug('document: ', r))));
  }

  // getBlobFromUri(documentUri: string){
  //   const params = this.httpClientHelper.createHttpParams();
  //   params.set('responseType', 'blob');
  //   documentUri = documentUri + '?lang='+this.cantaaLocalizationService.currentLanguage+'&marketCode='+this.cantaaLocalizationService.currentMarket;
  //   const documentBlob = firstValueFrom(this.http.get(documentUri, { responseType: 'blob' } ));
  //   return  documentBlob
  // }

  getBlob4Id(id: number){
    const params = this.httpClientHelper.createHttpParams();
    params.set('responseType', 'blob');
    let url = environment.apiUrl + '/documents/blob/' + id;
    url = url + '?lang='+this.cantaaLocalizationService.currentLanguage+'&marketCode='+this.cantaaLocalizationService.currentMarket;
    const documentBlob = firstValueFrom(this.http.get(url, { responseType: 'blob' } ));
    return  documentBlob
  }

  getBlob4IdScaled(id: number, width: number, heigth: number){
    const params = this.httpClientHelper.createHttpParams();
    params.set('responseType', 'blob');
    let url = environment.apiUrl + '/documents/blob/' + id + '/' + width + '/' + heigth;
    url = url + '?lang='+this.cantaaLocalizationService.currentLanguage+'&marketCode='+this.cantaaLocalizationService.currentMarket;
    const documentBlob = firstValueFrom(this.http.get(url, { responseType: 'blob' } ));
    return  documentBlob
  }

  async getDocumentFromIdAndRender(documentId: number | undefined, imgEleId: string){
    if (documentId == undefined){
      return;
    }
    const params = this.httpClientHelper.createHttpParams();
    params.set('responseType', 'blob');
    let url = environment.apiUrl + `/documents/blob/` + documentId;
    url = url + '?lang='+this.cantaaLocalizationService.currentLanguage+'&marketCode='+this.cantaaLocalizationService.currentMarket;
    const documentBlob = await firstValueFrom(this.http.get(url, { responseType: 'blob' } ));
    this.processImageAndRender(documentBlob, imgEleId);
  }

  async getDocumentScaled4IdAndRender(documentId: number | undefined, imgEleId: string, width: number, heigth: number){
    if (documentId == undefined){
      return;
    }
    const params = this.httpClientHelper.createHttpParams();
    params.set('responseType', 'blob');
    // let url = environment.apiUrl + `/documents/blob/` + documentId;
    let url = environment.apiUrl + '/documents/blob/' + documentId + '/' + width + '/' + heigth;
    url = url + '?lang='+this.cantaaLocalizationService.currentLanguage+'&marketCode='+this.cantaaLocalizationService.currentMarket;
    const documentBlob = await firstValueFrom(this.http.get(url, { responseType: 'blob' } ));
    this.processImageAndRender(documentBlob, imgEleId);
  }


  processImageAndRender(imgBlob: Blob, imgEleId: string){
    let url= URL.createObjectURL(imgBlob);
    var myImg = document.getElementById(imgEleId) as HTMLImageElement;
    myImg.src = url;
    this.enableImgElement(imgEleId);
  }

  disableImgElement(imgEleId: string){
    var myImg = document.getElementById(imgEleId) as HTMLImageElement;
    myImg.style.display='none';
    myImg.src='';
  }

  enableImgElement(imgEleId: string){
    var myImg = document.getElementById(imgEleId) as HTMLImageElement;
    myImg.style.display='initial';
  }

  processPdfAndRender(pdfBlob: Blob, pdfEleId: string){

    let url= URL.createObjectURL(pdfBlob);
    var myPdf = document.getElementById(pdfEleId) as HTMLImageElement;
    myPdf.src = url;

    // let reader = new FileReader();
    // reader.addEventListener("load", () => {
    //   var myPdf = document.getElementById(pdfEleId) as HTMLIFrameElement;
    //   myPdf.src = <string>reader.result;
    // }, false);
    // reader.readAsDataURL(pdfBlob);
  }

  deleteDocument(documentId: number | null) {
    if (documentId == null) {
      return;
    }

    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/documents/${documentId}`;
    let observable = this.http.delete<boolean>(url, { params: params })
      .pipe(tap(r => this.log.debug('document deleted ')))

    return firstValueFrom(observable)
  }

}
