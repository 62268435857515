import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogRef,} from '@angular/material/dialog';
import {MatButtonModule} from "@angular/material/button";
import {NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltip} from "@angular/material/tooltip";
import {ConsoleLoggerService} from "../../service/console-logger.service";
import {ToolDocumentService} from "../../service/tool-document.service";
import {ToolDocument} from "../../ model/tool-document.models";
import {DocumentService} from "../../service/document.service";
import {DocumentTypeListItem} from "../../ model/document-type-list-item.model";
import {DocumentTypeService} from "../../service/document-type.service";
import {CantaaErrorHandlerService} from "../../service/cantaa-error-handler.service";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {CantaaMessageService} from "../../service/cantaa-message.service";
import {MatToolbar, MatToolbarRow} from "@angular/material/toolbar";
import {ToolLocationDocumentService} from "../../service/tool-location-document.service";
import {ToolLocationDocument} from "../../ model/tool-location-document.models";
import {TranslateModule} from '@ngx-translate/core';


export interface DialogData {
  toolDocumentId: 0;
  edit: false;
  type: string;
}

@Component({
  selector: 'wim-dialog-document',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogClose,
    MatButtonModule,
    NgIf,
    MatIcon,
    MatFormField,
    MatLabel,
    MatFormField,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatTooltip,
    MatOption,
    MatSelect,
    MatToolbar,
    MatToolbarRow,
    TranslateModule
  ],
  templateUrl: './dialog-document.component.html',
  styleUrl: './dialog-document.component.scss',
})
export class DialogDocumentComponent implements OnInit {

  toolDocumentId: number = 0;
  toolDocument: ToolDocument | null = null;
  isImageLoading: boolean;
  edit: boolean;
  type: string = '';
  documentTypes: DocumentTypeListItem[] = [];
  initialValue: any | undefined | null;

  detailForm = this.fb.group({
    id: ({ value: 0, disabled: true }),
    documentId: ({ value: 0, disabled: true }),
    documentText: (''),
    documentTypeId: this.fb.control<DocumentTypeListItem | null>({ value: null, disabled: false }),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              private fb: FormBuilder,
              private toolDocumentService: ToolDocumentService,
              private toolLocationDocumentService: ToolLocationDocumentService,
              private documentService: DocumentService,
              private documentTypeService: DocumentTypeService,
              private errorHandler: CantaaErrorHandlerService,
              private messageService: CantaaMessageService,
              private log: ConsoleLoggerService,
              public dialogRef: MatDialogRef<DialogDocumentComponent>) {

    this.toolDocumentId = data.toolDocumentId;
    this.isImageLoading = false;
    this.edit = data.edit;
    this.type = data.type;
  }

  async ngOnInit() {
    await this.fetchDetail(this.toolDocumentId);
    this.documentTypes = await this.fetchDocumentTyped();

    if (this.toolDocument?.documentFileType == 'pdf') {
      const pdfBlob = await this.documentService.getBlob4Id(this.toolDocument?.documentId);
      this.documentService.processPdfAndRender(pdfBlob,'pdfToShow');
    } else if (this.toolDocument?.documentId) {
      const imgBlob = await this.documentService.getBlob4Id(this.toolDocument?.documentId);
      this.documentService.processImageAndRender(imgBlob, 'imageToShow');
    }

    await this.initFormForEdit();
  }

  private async initFormForEdit() {
    let documentTypeId = this.documentTypes.find(c => c.id === this.toolDocument?.documentTypeId) ?? null;
    this.initialValue =
      {
        id: this.toolDocument?.id,
        documentId: this.toolDocument?.documentId,
        documentText: this.toolDocument?.documentText,
        documentTypeId: documentTypeId
      };
    this.detailForm.reset(this.initialValue);
  }

  private async fetchDetail(toolDocumentId: number) {
    if (this.type == 'TOOL') {
      this.toolDocument = await this.toolDocumentService.getToolDocumentDetail(toolDocumentId);
    }
    if (this.type == 'LOCATION') {
      this.toolDocument = await this.toolLocationDocumentService.getToolLocationDocumentDetail(toolDocumentId);
    }
  }

  private async fetchDocumentTyped() {
    try {
      return await this.documentTypeService.getDocumentTypes();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }


  closeDialog(){
    this.dialogRef.close();
  }

  async onSave() {
    if (this.type == 'TOOL') {
      this.saveToolDocument();
    }
    if (this.type == 'LOCATION') {
      this.saveToolLocationDocument();
    }
  }

  async saveToolDocument(){
    const detail = {
      id: this.detailForm.getRawValue().id,
      documentId: this.detailForm.getRawValue().documentId,
      documentText: this.detailForm.getRawValue().documentText,
      documentTypeId: this.detailForm.getRawValue().documentTypeId?.id,
    } as ToolDocument

    this.log.debug('on save ToolDocument', detail);

    try {
      let ret = await  this.toolDocumentService.saveToolDocument(detail);
      this.messageService.success('SAVE.SUCCESS');
      this.closeDialog();
    } catch (e) {
      this.errorHandler.handleError(e, 'SAVE.FAILED');
    }
  }

  async saveToolLocationDocument(){
    const detail = {
      id: this.detailForm.getRawValue().id,
      documentId: this.detailForm.getRawValue().documentId,
      documentText: this.detailForm.getRawValue().documentText,
      documentTypeId: this.detailForm.getRawValue().documentTypeId?.id,
    } as ToolLocationDocument

    this.log.debug('on save ToolLocationDocument', detail);

    try {
      let ret = await  this.toolLocationDocumentService.saveToolLocationDocument(detail);
      this.messageService.success('SAVE.SUCCESS');
      this.closeDialog();
    } catch (e) {
      this.errorHandler.handleError(e, 'SAVE.FAILED');
    }
  }
}
