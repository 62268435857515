<div class="container ">
  <div class="row">
    <p-table
      #dt
      [value]="inspections"
      [sortMode]="'multiple'"
      [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="name" style="min-width:10rem" pSortableColumn="name" pResizableColumn>
            {{ 'INSPECTION.INSPECTION' | translate }}
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th id="dueDate" style="min-width:10rem" pSortableColumn="dueDate" pResizableColumn>
            {{ 'DATE.DUE_DATE' | translate }}
            <p-sortIcon field="dueDate"></p-sortIcon>
          </th>
          <th id="actualInspectionDate" style="min-width:10rem" pSortableColumn="actualInspectionDate" pResizableColumn>
            {{ 'INSPECTED' | translate }}
            <p-sortIcon field="actualInspectionDate"></p-sortIcon>
          </th>
          <th id="done" style="min-width:10rem" pSortableColumn="done" pResizableColumn>
            {{ 'DONE' | translate }}
            <p-sortIcon field="done"></p-sortIcon>
          </th>
          <th id="result" style="min-width:10rem" pSortableColumn="result" pResizableColumn>
            {{ 'RESULT.RESULT' | translate }}
            <p-sortIcon field="result"></p-sortIcon>
          </th>
          <th id="comment" style="min-width:10rem" pSortableColumn="comment" pResizableColumn>
            {{ 'COMMENT' | translate }}
            <p-sortIcon field="comment"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-inspections>
        <tr>
          <td>{{ inspections.name }}</td>
          <td>{{ inspections.dueDateFormatted }}</td>
          <td>{{ inspections.actualInspectionDateFormatted }}</td>
          <td>{{ 'BOOLEAN.YESNO.' + (inspections.done ? 'TRUE' : 'FALSE') | translate }}</td>
          <td>{{ 'RESULT.' + inspections.result | translate }}</td>
          <td>{{ inspections.comment }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
