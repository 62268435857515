<p-tree [value]="wimTreeNodes"
        class="w-full"
        [style]="{ height: '100%', width: '100%' }"
        selectionMode="single"
        [(selection)]="selectedNodeForUI"
        (onNodeExpand)="nodeExpand($event)"
        (onNodeCollapse)="nodeCollapse($event)"
        (onNodeSelect)="nodeSelect($event)"
        (onNodeUnselect)="nodeUnselect($event)"
        scrollHeight="flex">
  <ng-template let-node pTemplate="default">
    <div #nodeReference id="{{node.key}}" (dblclick)="doubleClick(node.key)">
      @if (node.highlighterClass) {
        <span [ngClass]="node.highlighterClass">&nbsp;</span>
      }
      {{ node.label }}
    </div>
  </ng-template>
</p-tree>


<!--<div class="custom-tree-container">-->
<!--  &lt;!&ndash;<div style="height: 100%; width: 90%; overflow: auto;">&ndash;&gt;-->

<!--  <p-tree [value]="wimTreeNodes"-->
<!--          [style]="{ height: '100%', width: '90%' }"-->
<!--          selectionMode="single"-->
<!--          scrollHeight="flex">-->
<!--    <ng-template let-node pTemplate="default">-->
<!--      <div>-->
<!--        {{ node.label }}-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </p-tree>-->

<!--</div>-->
