import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {PaginatorModule} from 'primeng/paginator';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {AppStateService} from '../../../service/app-state.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {NodeType} from '../../../constants/node-type';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteHelper} from '../../../service/helper/route-helper';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {LoggedUserService} from '../../../service/logged-user.service';
import {DocumentService} from "../../../service/document.service";
import {DocumentDetail} from "../../../ model/document.detail";
import {MatDivider} from "@angular/material/divider";
import {NgIf} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {CantaaLocalizationService} from '../../../service/cantaa-localization.service';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-inspection-type-detail',
  standalone: true,
  imports: [
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    PaginatorModule,
    ReactiveFormsModule,
    HasPermissionDirective,
    MatDivider,
    NgIf,
    TranslateModule,
    ShowIfDebugMode
  ],
  templateUrl: './document-detail.component.html',
  styleUrl: './document-detail.component.scss'
})
export class DocumentDetailComponent implements OnInit {
  protected readonly Permission = Permission;
  protected readonly destroyRef = inject(DestroyRef);

  detailForm = this.fb.group({
    id: this.fb.control<number | null>({value: null, disabled: true}),
    status: this.fb.control<string | null>({value: 'ACTIVE', disabled: true}),
    fileType: this.fb.control<string | null>({value: null, disabled: true}),
    fileName: ['', [Validators.required]],
    text: ['', [Validators.required]],
  });

  document!: DocumentDetail;

  constructor(private fb: FormBuilder,
              private appStateService: AppStateService,
              private documentService: DocumentService,
              private messageService: CantaaMessageService,
              private errorHandler: CantaaErrorHandlerService,
              private router: Router,
              private route: ActivatedRoute,
              private localization: CantaaLocalizationService,
              private loggedUserService: LoggedUserService) {
  }

  async ngOnInit() {
    const onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.DOCUMENT) {
          return;
        }
        await this.initDetail(selectedNode);
      });

    this.destroyRef.onDestroy(() => {
      onSelectNodeSubscription.unsubscribe();
    });
  }

  private async initDetail(selectedNode: WimTreeNode) {
    const detail = await this.fetchDetail(selectedNode);
    this.document = detail;
    this.setDetailToForm(detail);
    if (!this.loggedUserService.hasPermission(Permission.DOCUMENT_EDIT)) {
      this.detailForm.disable();
    }

  }

  private async fetchDetail(selectedNode: WimTreeNode) {
    try {
      this.documentService.getDocumentFromIdAndRender(selectedNode.id, 'document_detail_photo');
      return await this.documentService.getDocumentDetail(selectedNode.id!);

    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private setDetailToForm(detail: DocumentDetail) {
    this.detailForm.reset(
      {
        id: detail.id,
        status: detail.status,
        fileType: detail.fileType,
        fileName: detail.fileName,
        text: detail.text
      }
    );
  }

  async onSave() {
    //   this.log.debug('on save', this.detailForm.value);

    const detail = {
      id: this.detailForm.getRawValue().id,
      status: this.detailForm.getRawValue().status,
      fileType: this.detailForm.getRawValue().fileType,
      fileName: this.detailForm.getRawValue().fileName,
      text: this.detailForm.getRawValue().text,
    } as DocumentDetail;

    try {
      await this.documentService.saveDocument(detail);
      this.messageService.success('SAVE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, this.localization.instant('SAVE.FAILED'));
    }
  }

  async onCancel() {
    //   if (RouteHelper.isInNewState(this.route)) {
    //     this.detailForm.reset();
    //   } else {
    let selectedNodeValue = this.appStateService.selectedNodeValue;
    await this.initDetail(selectedNodeValue);
    //   }
  }

  async onDelete(){
    if (confirm(this.localization.instant('DOCUMENT.CONFIRM.DELETE'))) {
      this.documentService.deleteDocument(this.document.id);
      // await RouteHelper.navigateToGrid(this.route, this.router);
      await RouteHelper.navigateToGridDocument(this.route, this.router);
    }
  }

}
