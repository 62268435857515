import {Component, DestroyRef, inject} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AppStateService} from "../../../service/app-state.service";
import {CantaaMessageService} from "../../../service/cantaa-message.service";
import {ConsoleLoggerService} from "../../../service/console-logger.service";
import {CantaaErrorHandlerService} from "../../../service/cantaa-error-handler.service";
import {NodeType} from "../../../constants/node-type";
import {WimTreeNode} from "../../../ model/wim-tree.node";
import {SharedModule} from "primeng/api";
import {Document} from "../../../ model/document.models";
import {ToolDocument} from "../../../ model/tool-document.models";
import {ToolDocumentService} from "../../../service/tool-document.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogDocumentComponent} from "../../dialog-document/dialog-document.component";
import {NgForOf, NgIf} from "@angular/common";
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle
} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {CantaaLocalizationService} from '../../../service/cantaa-localization.service';
import {MatIcon} from "@angular/material/icon";
import {DocumentService} from "../../../service/document.service";
import {MatFormField, MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {ToolDocumentDetail} from "../../../ model/tool-document-detail.models";
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MatDivider} from "@angular/material/divider";
import {TranslateModule} from '@ngx-translate/core';


@Component({
  selector: 'wim-tool-detail-documents',
  standalone: true,
  imports: [
    SharedModule,
    NgForOf,
    NgIf,
    MatCardHeader,
    MatCard,
    MatCardContent,
    MatCardActions,
    MatCardSubtitle,
    MatCardTitle,
    MatButtonModule,
    MatGridList,
    MatGridTile,
    MatIcon,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatFormFieldModule,
    MatDivider,
    TranslateModule
  ],
  templateUrl: './tool-detail-documents.component.html',
  styleUrl: './tool-detail-documents.component.scss'
})
export class ToolDetailDocumentsComponent {
  toolDocuments: ToolDocument[] = [];
  documents: Document[] = [];
  toolId!: number;
  formCreateNew = this.fb.group({
    documentId: [({ value: 0}), [Validators.required]],
  });

  protected readonly destroyRef = inject(DestroyRef);

  constructor(private fb: FormBuilder,
              private appStateService: AppStateService,
              private toolDocuemntService: ToolDocumentService,
              private documentService: DocumentService,
              private messageService: CantaaMessageService,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService,
              public dialog: MatDialog,
              private localization: CantaaLocalizationService) {
  }

  async ngOnInit() {
    const onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL) {
          return;
        }
        this.toolId = selectedNode.id;
        await this.fetchDetail(selectedNode);
        await this.getDocumentsForDropDownSelect();
      });

    this.destroyRef.onDestroy(() => {
      onSelectNodeSubscription.unsubscribe();
    });
  }

  async initformCreateNew(){
    // this.initialValue =
    //   {
    //     documentId: 0
    //   };
    this.formCreateNew.reset();
  }


  // @ts-ignore
  async onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      try {
        await this.toolDocuemntService.uploadDocumentForTool(this.toolId, file);
        this.reload();
        this.messageService.success('DOCUMENT.ADDED_NEW');
      } catch (e) {
        this.errorHandler.handleError(e, this.localization.instant('DOCUMENT.UNABLE_ADD_NEW'));
        throw e;
      }
    }
  }

  async getDocumentsForDropDownSelect() {
    const documents = this.documentService.getAllExceptAllreadyAssignedToToolId(this.toolId);
    documents.then((data) => {
      this.documents = data.items;
    });
  }

  async delete(toolDocumentId: number) {
    if (confirm(this.localization.instant('DOCUMENT.CONFIRM.DELETE'))) {
      try {
        await this.toolDocuemntService.deleteToolDocument(toolDocumentId);
        this.reload();
        this.messageService.success('DOCUMENT.DELETED');
      } catch (e) {
        this.errorHandler.handleError(e, this.localization.instant('DOCUMENT.UNABLE_DELETE'));
        throw e;
      }
    }
  }


  async onSave() {
    this.log.debug('on save', this.formCreateNew.value);

    if (this.formCreateNew.valid) {
      let documentId: number = Number(this.formCreateNew.getRawValue().documentId);
      const detail = {
        toolId: this.toolId,
        documentId: documentId
      } as ToolDocumentDetail;

      try {
        await this.toolDocuemntService.newToolDocument(detail);
        this.messageService.success('SAVE.SUCCESS');
        this.reload();
      } catch (e) {
        this.errorHandler.handleError(e, this.localization.instant('SAVE.FAILED'));
      }
    } else {
      this.messageService.error('DOCUMENT.NO_DOCUMENT_SELECTED');
    }
  }


  reload() {
    this.ngOnInit();
  }

  openDialog4DocumentShow(toolDocumentId: number, documentFileType: string) {
    if (documentFileType == 'pdf') {
      const dialogRef = this.dialog.open(DialogDocumentComponent, {
        data: {toolDocumentId: toolDocumentId, edit: false, type: 'TOOL'}, width: '95%', height: '95%'
      });
    } else {
      const dialogRef = this.dialog.open(DialogDocumentComponent, {
        data: {toolDocumentId: toolDocumentId, edit: false, type: 'TOOL'}, width: '60%', height: '95%'
      });
    }
  }

  openDialog4DocumentEdit(toolDocumentId: number) {
    const dialogRef = this.dialog
      .open(DialogDocumentComponent, {
      data: {toolDocumentId: toolDocumentId, edit: true, type: 'TOOL'}, width: '30%', height: '95%'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.reload();
    })

  }

  async fetchDetail(selectedNode: WimTreeNode) {
    const toolDocument = await this.fetchToolByToolId(selectedNode.id!);
    this.toolDocuments = toolDocument.items;

    this.toolDocuments.forEach(toolDocument => {
      if (toolDocument.documentText == ''){
        toolDocument.documentText = '---';
      }

      if (toolDocument.documentText.length > 30){
        toolDocument.documentText = toolDocument.documentText.substring(0,27)+'...';
      }


       this.getBlobAndRender(toolDocument)
    });
    await this.initformCreateNew();
  }

  async getBlobAndRender(document: ToolDocument) {
    if (document.documentFileType != 'pdf') {
      // const imgBlob = await this.documentService.getBlob4Id(document.documentId);
      const imgBlob = await this.documentService.getBlob4IdScaled(document.documentId,300,300);
      let eleImgId = document.documentFileType + document.documentId;
      this.documentService.processImageAndRender(imgBlob, eleImgId);
    }
  }

  private async fetchToolByToolId(toolId: number) {
    try {
      return await this.toolDocuemntService.getAllToolDocuments4ToolId(toolId);
    } catch (e) {
      // this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      this.toolDocuments = [];
      throw e;
    }
  }

  onCancel() {
  }
}
