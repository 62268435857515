import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoggedUserService } from '../service/logged-user.service';

// https://juri.dev/blog/2018/02/angular-permission-directive/
@Directive({
  selector: '[hasAnyPermission]',
  standalone: true,
})
export class HasAnyPermissionDirective implements OnInit {
  private permissions: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private loggedUserService: LoggedUserService
  ) {
  }

  ngOnInit() {
    this.loggedUserService.loggedUser$
      .subscribe(loggedUser => {
        this.updateView();
      });
  }

  @Input()
  set hasAnyPermission(permissions: string[]) {
    this.permissions = permissions;
    this.updateView();
  }

  private updateView() {
    if (this.loggedUserService.hasAnyPermission(this.permissions)) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
