<div class="container">

  <h1 class="mt-2">System jobs</h1>

  <span class="me-2 mb-2">Reload jobs:</span>
  <button mat-mini-fab color="primary" (click)="fetchJobs()">
    <mat-icon>refresh</mat-icon>
  </button>

  <div class="row">
    <p-table
      #dt
      [value]="systemJobs"
      [sortMode]="'multiple'"
      [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="jobName" style="min-width:10rem" pSortableColumn="jobName" pResizableColumn>
            {{ 'NAME' | translate }}
          </th>
          <th id="enabled" style="min-width:2rem" pSortableColumn="enabled" pResizableColumn>
            {{ 'ENABLED' | translate }}
          </th>
          <th id="cron" style="min-width:10rem" pSortableColumn="cron" pResizableColumn>
            {{ 'CRON' | translate }}
          </th>
          <th id="status" style="min-width:10rem" pSortableColumn="status" pResizableColumn>
            {{ 'STATUS' | translate }}
          </th>
          <th id="lastSuccessStep" style="min-width:10rem" pSortableColumn="lastSuccessStep" pResizableColumn>
            {{ 'LAST_SUCCESS_STEP' | translate }}
          </th>
          <th id="startDate" style="min-width:10rem" pSortableColumn="startDate" pResizableColumn>
            {{ 'DATE.START' | translate }}
          </th>
          <th id="lastSuccessEndDate" style="min-width:10rem" pSortableColumn="lastSuccessEndDate" pResizableColumn>
            {{ 'DATE.LAST_SUCCESS_END_DATE' | translate }}
          </th>
          <th id="action" style="min-width:7rem" pSortableColumn="action" pResizableColumn>
          </th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td title="{{ item.jobDescription }}">{{ item.jobName }}</td>
          <td>
            @if (item.enabled) {
              <mat-icon>check</mat-icon>
            } @else {
              <mat-icon>horizontal_rule</mat-icon>
            }
          </td>
          <td>{{ item.cron }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.lastSuccessStep }}</td>
          <td>{{ item.startDate | date: ('DATETIME.FORMAT' | translate) }}</td>
          <td>{{ item.lastSuccessEndDate | date: ('DATETIME.FORMAT' | translate) }}</td>
          <td>
            <button mat-icon-button type="button" class="" title="Edit job" (click)="toEditPage(item.jobId)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-mini-fab color="primary" type="button" class=""
                    title="Run job" (click)="runJob(item.jobId)">
              <mat-icon>play_arrow</mat-icon>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
